import { connect } from 'react-redux';
import { Sessions as SessionsComponent } from './sessions.component';
import { getSessions, signoutSession } from './sessions.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.sessions,
    };
};

export const Sessions = connect(mapStateToProps, {
    getSessions,
    signoutSession,
})(SessionsComponent);
