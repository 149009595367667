const initialState = {
    sessions: [],
    loadingSessions: true,
};

export const sessionsReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_SESSIONS_REQUESTED':
            return {
                ...state,
                loadingSessions: true,
            };
        case 'GET_SESSIONS_SUCCESS':
            return {
                ...state,
                loadingSessions: false,
                ...action.payload,
            };
        case 'GET_SESSIONS_FAILED':
            return {
                ...state,
                loadingSessions: false,
            };
        case 'DELETE_SESSION_REQUESTED':
            return {
                ...state,
            };
        case 'DELETE_SESSION_SUCCESS':
            return {
                ...state,
                sessions: [
                    ...state.sessions.filter(session => {
                        return session.id != action.payload.sessionId;
                    }),
                ],
            };
        case 'DELETE_SESSION_FAILED':
            return {
                ...state,
            };
    }
};
