import React, { Component } from 'react';
import FlexBox from '../../flex-box/flex-box';
import { DFPManager, DFPSlotsProvider, AdSlot } from 'react-dfp';
import { Link } from '@mui/material';

export class Ad extends Component {
    componentDidMount() {
        // Fix for blank ads bug: https://github.com/jaanauati/react-dfp/issues/149#issuecomment-497908118
        DFPManager.getGoogletag()
            .then(googletag => {
                // Ensure it exists...
                if (googletag.pubads) {
                    googletag.pubads().updateCorrelator();
                }
            })
            .catch(() => { });
    }

    render() {
        const { user } = this.props;
        return (
            <FlexBox aligncenter style={{ position: 'relative' }}>
                <DFPSlotsProvider dfpNetworkId="5476490">
                    <div className="gpt-ad">
                        <AdSlot
                            sizes={[[300, 250]]}
                            adUnit="Ad_Sales_300x250"
                            targetingArguments={{
                                age_range: user.age_range,
                                marital_st: user.marital_status,
                            }}
                        />
                    </div>
                </DFPSlotsProvider>
            </FlexBox>
        );
    }
}
