const initialState = {
    success: false,
};

export const signupReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'SIGNUP_REQUESTED':
            return {
                ...state,
            };
        case 'SIGNUP_SUCCESS':
            return {
                ...state,
                success: Date.now(),
            };
        case 'SIGNUP_FAILED':
            return {
                ...state,
            };
    }
};
