import { connect } from 'react-redux';
import { Menu as MenuComponent } from './menu.component';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.account,
    };
};

export const Menu = connect(mapStateToProps, {})(MenuComponent);
