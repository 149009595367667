export const queueRedirect = (url, state, immediate = false) => dispatch => {
    dispatch({
        type: 'QUEUE_REDIRECT',
        url,
        state,
        canRedirect: immediate,
    });
};

export const clearRedirect = () => dispatch => {
    dispatch({
        type: 'CLEAR_REDIRECT',
    });
};
