import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import { get } from 'lodash';

export default class SafeHtml extends Component {
    render() {
        const html = this.props.children;
        const clean = DOMPurify.sanitize(html, { ADD_TAGS: ['iframe'] });
        const className = this.props.className || null;
        const component = get(this.props, 'component', 'div');
        const CustomTag = `${component}`;

        return (
            <CustomTag
                className={className}
                style={this.props.style}
                dangerouslySetInnerHTML={{ __html: clean }}
            />
        );
    }
}
