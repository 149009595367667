import React, { useEffect } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Button, Typography, CircularProgress, Paper } from '@mui/material';
import moment from 'moment';
import classes from './sessions.styles.module.scss';
import { Grid as MyGrid } from '../grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const Sessions = props => {
    useEffect(() => {
        document.title = 'Sessions';
        if (!props.sessions.length) {
            props.getSessions();
        }
    }, []);

    const signout = id => {
        props.signoutSession(id);
    };

    if (props.loadingSessions) {
        return (
            <FlexBox justifycenter aligncenter className="p-4">
                <CircularProgress size={30} className="mb-4"></CircularProgress>{' '}
                <Typography variant="subtitle2">Loading Sessions</Typography>
            </FlexBox>
        );
    }
    return (
        <FlexBox>
            <MyGrid
                loading={props.loadingSessions}
                items={props.sessions}
                column1Title="Last Access"
                column2Title="Browser"
                column3Title="Location"
                column1={s => {
                    return (
                        <Typography
                            color="textPrimary"
                            variant={window.isDesktop ? 'body1' : 'caption'}
                        >
                            {moment(s.changed_on).format('MMM Do, YYYY')}{' '}
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                component={window.isDesktop ? 'p' : 'span'}
                                className={classes.time}
                            >
                                {moment(s.changed_on).format('LT')}
                            </Typography>
                        </Typography>
                    );
                }}
                column2={s => {
                    return <Typography>{s.user_agent_description}</Typography>;
                }}
                column3={s => {
                    return (
                        <FlexBox>
                            <Typography>{s.geo_description}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {s.remote_addr}
                            </Typography>
                        </FlexBox>
                    );
                }}
                column4={s => {
                    if (s.is_current) {
                        return (
                            <Typography variant="subtitle2" color="primary">
                                Current
                            </Typography>
                        );
                    } else {
                        return (
                            <Button
                                color="primary"
                                onClick={() => signout(s.id)}
                            >
                                Signout
                            </Button>
                        );
                    }
                }}
            ></MyGrid>
        </FlexBox>
    );
};
