import { connect } from 'react-redux';
import { Orders as OrdersComponent } from './orders.component';
import { getOrderHistory } from './orders.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.orders,
    };
};

export const Orders = connect(mapStateToProps, {
    getOrderHistory,
})(OrdersComponent);
