const initialState = {
    channels: [],
    confirmSuccess: false,
};

export const tfaReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'SELECT_TFA_CHANNEL_REQUESTED':
            return {
                ...state,
            };
        case 'SELECT_TFA_CHANNEL_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'SELECT_TFA_CHANNEL_FAILED':
            return {
                ...state,
            };
        case 'SEND_TFA_CODE_REQUESTED':
            return {
                ...state,
            };
        case 'SEND_TFA_CODE_SUCCESS':
            return {
                ...state,
            };
        case 'SEND_TFA_CODE_FAILED':
            return {
                ...state,
            };
        case 'CONFIRM_TFA_CODE_REQUESTED':
            return {
                ...state,
                confirmSuccess: false,
            };
        case 'CONFIRM_TFA_CODE_SUCCESS':
            return {
                ...state,
                ...action.payload,
                confirmSuccess: true,
            };
        case 'CONFIRM_TFA_CODE_FAILED':
            return {
                ...state,
                confirmSuccess: false,
            };
        case 'TRUST_DEVICE_REQUESTED':
            return {
                ...state,
            };
        case 'TRUST_DEVICE_SUCCESS':
            return {
                ...state,
            };
        case 'TRUST_DEVICE_FAILED':
            return {
                ...state,
            };
    }
};
