import axiosAPI from '@/shared/api';

export const getAccount = () => async dispatch => {
    dispatch(getAccountRequested());

    return axiosAPI()
        .get('account')
        .then(response => {
            return dispatch(getAccountSuccess(response.data));
        })
        .catch(error => {
            dispatch(getAccountFailed(error.response));
        });
};

function getAccountRequested() {
    return {
        type: 'GET_ACCOUNT_REQUESTED',
    };
}

function getAccountSuccess(payload) {
    return {
        type: 'GET_ACCOUNT_SUCCESS',
        payload,
    };
}

function getAccountFailed(error) {
    return {
        type: 'GET_ACCOUNT_FAILED',
        error,
    };
}
