import { connect } from 'react-redux';
import { RemoveAccount as RemoveAccountComponent } from './remove-account.component';
import { getReasons, removeAccount } from './remove-account.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.removeAccount,
    };
};

export const RemoveAccount = connect(mapStateToProps, {
    getReasons,
    removeAccount,
})(RemoveAccountComponent);
