import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import variables from '@/shared/styles/variables';
import { get, debounce, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

export const useDidUpdateEffect = function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) fn();
        else didMountRef.current = true;
    }, inputs);
};

export const usePreviousLocation = function usePreviousLocation() {
    const previousLocation = useSelector(state => state.navigationReducer?.previousLocation);
    const previousPaths = useSelector(state => state.navigationReducer?.previousPaths);
    const history = useHistory();

    if (previousLocation && previousPaths) {
        const navigateToPrevious = (defaultPath = '/home') => {
            const isModal = /modal/.test(history.location.pathname);

            if (previousPaths.length > 0) {
                history.goBack();
            } else if (isModal) {
                const splitUrl = history.location.pathname.split('/');
                const splicedUrl = splitUrl.splice(0, splitUrl.length - 1);
                const baseUrl = splicedUrl.join('/');
                history.replace(baseUrl);
            } else {
                history.push(defaultPath);
            }
        };

        return { previousLocation, navigateToPrevious };
    } else {
        return {
            previousLocation: null,
            navigateToPrevious: () => {
                history.replace('/');
            },
        };
    }
};

export const usePrevious = function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useCtabarHeight = function useCtabarHeight(value) {
    const token = useSelector(state => state.authReducer.token);
    const ctabar = get(token, 'promo.ctabar');
    if (ctabar) {
        return variables.subscribeBarHeight + value;
    }
    return value;
};
export const useIsLightTheme = function useIsLightTheme() {
    const wcag = useSelector(state => state.authReducer.wcag);
    if (wcag && !isEmpty(wcag)) {
        return wcag.theme == 'lightBackground' || wcag.theme == 'default' || wcag.theme == 'traditional';
    }
    return true;
};
export const useWhiteLogo = function useWhiteLogo() {
    const wcag = useSelector(state => state.authReducer.wcag);
    if (wcag && !isEmpty(wcag)) {
        return wcag.theme == 'highContrast' || wcag.theme == 'traditional' || wcag.theme == 'negativeContrast';
    }
    return false;
};

export const useInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    return {
        value,
        setValue,
        reset: () => setValue(''),
        bind: {
            value,
            onChange: event => {
                const val = event.target.type == 'checkbox' ? event.target.checked : event.target.value;
                setValue(val);
            },
        },
    };
};

export const useWindowSize = () => {
    const isClient = typeof window === 'object';

    const getSize = () => {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
    };

    const [windowSize, setWindowSize] = useState(getSize());

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        const handleResize = debounce(() => setWindowSize(getSize()), 100);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
};

// Our hook
export const useDebounceTime = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Set debouncedValue to value (passed in) after the specified delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Return a cleanup function that will be called every time ...
            // ... useEffect is re-called. useEffect will only be re-called ...
            // ... if value changes (see the inputs array below).
            // This is how we prevent debouncedValue from changing if value is ...
            // ... changed within the delay period. Timeout gets cleared and restarted.
            // To put it in context, if the user is typing within our app's ...
            // ... search box, we don't want the debouncedValue to update until ...
            // ... they've stopped typing for more than 500ms.
            return () => {
                clearTimeout(handler);
            };
        },
        // Only re-call effect if value changes
        // You could also add the "delay" var to inputs array if you ...
        // ... need to be able to change that dynamically.
        [value]
    );

    return debouncedValue;
};

export const useTaskBeforeUnmount = (calback, ...data) => {
    const mounted = useRef(null);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(
        () => () => {
            if (!mounted.current) {
                calback({ ...data });
            }
        },
        [calback, ...data]
    );
};
