import { createTheme } from '@mui/material/styles';

const globalColors = {
    gray000: '#f5f6ff',
    gray100: '#e7edf3',
    gray200: '#c8d4e0',
    gray300: '#b3c0ce',
    gray400: '#98A0A9',
    gray500: '#5c6c7f',
    gray600: '#4a5b6d',
    gray700: '#35424e',
    gray800: '#2b353f',
    gray900: '#1b2128',
    gray1000: '#0c0f12',
    white: '#fff',
    black: '#000',
};

const lightBackground = {
    //GRAYS
    gray50: globalColors.black, // controls
    gray000: globalColors.white,
    gray100: globalColors.white,
    gray200: globalColors.white,
    gray300: globalColors.white,
    gray400: globalColors.gray400,
    gray500: globalColors.gray500,
    gray600: globalColors.gray600,
    gray700: globalColors.gray700,
    gray800: globalColors.gray800,
    gray900: globalColors.gray900,
    A100: globalColors.white,
    A200: globalColors.gray300,
    A400: globalColors.gray500,
    A700: globalColors.gray700,

    //PRIMARY
    primary: '#3271a0',
    primaryLight: '#5c93b5',
    primary100: 'rgba(50,113,160, 0.08)',
    primary200: 'rgba(50,113,160, 0.2)',
    primaryDark: '#0d264d',
    primaryHighlight: '#3271a0',

    //SECONDARY
    orangeDark: '#f4ae35',
    orangeLight: '#f7c46d',
    secondary: '#f26059',
    secondaryDark: '#d41515',
    secondary100: 'rgba(242,96,	89, .08)',
    secondary200: 'rgba(242,96,	89, .2)',
    purple: '#6840a1',
    green: '#47C43C',
    darkBlue: '#0c377c',

    //RANDOM
    backgroundDefault: globalColors.white,
    background000: globalColors.white,
    background100: globalColors.white,
    background200: globalColors.white,
    background300: globalColors.white,
    backgroundTransparent: 'rgba(255,255,255, .8)',
    backgroundBlue: 'rgba(159, 194, 249, .15)',

    divider: 'rgba(0,0,0,.12)',
    tabColor: 'rgba(0,0,0,.6)',

    //TEXT
    textPrimary: globalColors.black,
    textSecondary: globalColors.black,
    textContrast: globalColors.white,

    navbarBackground: globalColors.white,
    navbarColor: globalColors.black,
    navbarBorder: globalColors.white,
    navbarLogoBackground: globalColors.black,
    footerLogoBackground: 'transparent',
    type: 'light',
};

const traditional = {
    //GRAYS
    gray50: globalColors.gray200,
    gray000: globalColors.gray000,
    gray100: globalColors.gray100,
    gray200: globalColors.gray200,
    gray300: globalColors.gray300,
    gray400: globalColors.gray400,
    gray500: globalColors.gray500,
    gray600: globalColors.gray600,
    gray700: globalColors.gray700,
    gray800: globalColors.gray800,
    gray900: globalColors.gray900,
    A100: globalColors.gray100,
    A200: globalColors.gray300,
    A400: globalColors.gray500,
    A700: globalColors.gray700,

    //PRIMARY
    // primary: '#106FFF',
    // primaryLight: '#3787ff',
    // primary100: 'rgba(16, 111, 255, 0.08)',
    // primary200: 'rgba(16, 111, 255, 0.2)',
    // primaryDark: '#0054d4',
    primary: '#3271a0',
    primaryLight: '#5c93b5',
    primary100: 'rgba(50,113,160, 0.08)',
    primary200: 'rgba(50,113,160, 0.2)',
    primaryDark: '#0d264d',
    primaryHighlight: '#3271a0',

    //SECONDARY
    orangeDark: '#f4ae35',
    orangeLight: '#f7c46d',
    secondary: '#f26059',
    secondaryDark: '#d41515',
    secondary100: 'rgba(242,96,	89, .08)',
    secondary200: 'rgba(242,96,	89, .2)',
    purple: '#6840a1',
    green: '#47C43C',
    darkBlue: '#0c377c',

    //RANDOM
    backgroundDefault: globalColors.white,
    background000: globalColors.gray000,
    background100: globalColors.white,
    background200: globalColors.gray100,
    background300: globalColors.gray300,
    backgroundTransparent: 'rgba(255,255,255, .8)',
    backgroundBlue: 'rgba(159, 194, 249, .15)',

    divider: 'rgba(0,0,0,.12)',
    tabColor: globalColors.gray400,

    //TEXT
    textPrimary: globalColors.gray900,
    textSecondary: 'rgba(0, 0, 0, 0.5)', //gray500
    textContrast: globalColors.white,

    navbarBackground: 'linear-gradient(90deg, #0D264D, #3271A0)',
    navbarColor: globalColors.white,
    navbarBorder: 'transparent',
    navbarLogoBackground: 'transparent',
    footerLogoBackground: 'transparent',
    type: 'light',
};
const lightDefault = {
    //GRAYS
    gray50: globalColors.gray200,
    gray000: globalColors.gray000,
    gray100: globalColors.gray100,
    gray200: globalColors.gray200,
    gray300: globalColors.gray300,
    gray400: globalColors.gray400,
    gray500: globalColors.gray500,
    gray600: globalColors.gray600,
    gray700: globalColors.gray700,
    gray800: globalColors.gray800,
    gray900: globalColors.gray900,
    A100: globalColors.gray100,
    A200: globalColors.gray300,
    A400: globalColors.gray500,
    A700: globalColors.gray700,

    //PRIMARY
    // primary: '#106FFF',
    // primaryLight: '#3787ff',
    // primary100: 'rgba(16, 111, 255, 0.08)',
    // primary200: 'rgba(16, 111, 255, 0.2)',
    // primaryDark: '#0054d4',
    primary: '#3271a0',
    primaryLight: '#5c93b5',
    primary100: 'rgba(50,113,160, 0.08)',
    primary200: 'rgba(50,113,160, 0.2)',
    primaryDark: '#0d264d',
    primaryHighlight: '#3271a0',

    //SECONDARY
    orangeDark: '#f4ae35',
    orangeLight: '#f7c46d',
    secondary: '#f26059',
    secondaryDark: '#d41515',
    secondary100: 'rgba(242,96,	89, .08)',
    secondary200: 'rgba(242,96,	89, .2)',
    purple: '#6840a1',
    green: '#47C43C',
    darkBlue: '#0c377c',

    //RANDOM
    backgroundDefault: globalColors.white,
    background000: globalColors.gray000,
    background100: globalColors.white,
    background200: globalColors.gray100,
    background300: globalColors.gray300,
    backgroundTransparent: 'rgba(255,255,255, .8)',
    backgroundBlue: 'rgba(159, 194, 249, .15)',

    divider: 'rgba(0,0,0,.12)',
    tabColor: globalColors.gray400,

    //TEXT
    textPrimary: globalColors.gray900,
    textSecondary: 'rgba(0, 0, 0, 0.5)', //gray500
    textContrast: globalColors.white,

    navbarBackground: globalColors.white,
    navbarColor: globalColors.gray900,
    navbarBorder: globalColors.gray100,
    navbarLogoBackground: 'transparent',
    footerLogoBackground: 'transparent',
    type: 'light',
};

const highContrast = {
    //GRAYS
    gray50: globalColors.gray1000,
    gray000: globalColors.gray1000,
    gray100: globalColors.gray900,
    gray200: globalColors.gray800,
    gray300: globalColors.gray700,
    gray400: globalColors.gray600,
    gray500: globalColors.gray500,
    gray600: globalColors.gray400,
    gray700: globalColors.gray300,
    gray800: globalColors.gray200,
    gray900: globalColors.gray100,
    A100: globalColors.gray300,
    A200: globalColors.gray500,
    A400: globalColors.gray700,
    A700: globalColors.gray900,

    //PRIMARY
    primary: '#00FFFF',
    primary100: 'rgba(0,255,255, .08)',
    primary200: 'rgba(0,255,255, .2)',
    primaryLight: '#14ffff',
    primaryDark: '#00d8d8',
    primaryHighlight: '#106fff',

    //SECONDARY COLORS
    orangeDark: '#f29f10',
    orangeLight: '#f5b548', //#fda74a
    secondary: '#f26059',
    secondaryDark: '#d41515',
    secondary100: 'rgba(242,96,	89, .08)',
    secondary200: 'rgba(242,96,	89, .2)',
    purple: '#6840a1',
    green: '#47C43C',
    darkBlue: '#0c377c',

    //RANDOM
    backgroundDefault: globalColors.gray900,
    background000: globalColors.gray900,
    background100: globalColors.gray800,
    background200: globalColors.gray700,
    background300: globalColors.gray600,
    backgroundTransparent: 'rgba(0,0,0, .8)',
    backgroundBlue: 'rgba(159, 194, 249, .15)',
    divider: 'rgba(255,255,255,.12)',
    tabColor: 'rgba(255,255,255, .6)',

    //TEXT
    textPrimary: globalColors.white,
    textSecondary: 'rgba(255, 255, 255, 0.7)',
    textContrast: globalColors.gray700,

    navbarBackground: globalColors.gray800,
    navbarColor: globalColors.white,
    navbarBorder: globalColors.gray900,
    navbarLogoBackground: 'transparent',
    footerLogoBackground: 'transparent',
    type: 'dark',
};

const negativeContrast = {
    //GRAYS
    gray50: globalColors.gray1000,
    gray000: globalColors.gray900,
    gray100: globalColors.gray900,
    gray200: globalColors.gray800,
    gray300: globalColors.gray700,
    gray400: globalColors.gray600,
    gray500: globalColors.gray500,
    gray600: globalColors.gray400,
    gray700: globalColors.gray300,
    gray800: globalColors.gray200,
    gray900: globalColors.gray100,
    A100: globalColors.gray300,
    A200: globalColors.gray500,
    A400: globalColors.gray700,
    A700: globalColors.gray900,

    //PRIMARY
    primary: '#ffeb3b',
    primary100: 'rgba(255, 235, 59, .08)',
    primary200: 'rgba(255, 235, 59, .2)',
    primaryLight: '#ffeb3b',
    primaryDark: '#fdd835',
    primaryHighlight: '#106fff',

    //SECONDARY COLORS
    orangeDark: '#ffb300',
    orangeLight: '#ffca28',
    secondary: '#f26059',
    secondaryDark: '#d41515',
    secondary100: 'rgba(242,96,	89, .08)',
    secondary200: 'rgba(242,96,	89, .2)',
    purple: '#6840a1',
    green: '#47C43C',
    darkBlue: '#0c377c',

    //RANDOM
    backgroundDefault: globalColors.gray900,
    background000: globalColors.gray900,
    background100: globalColors.gray900,
    background200: globalColors.gray900,
    background300: globalColors.gray900,
    backgroundTransparent: 'rgba(0,0,0, .8)',
    backgroundBlue: 'rgba(159, 194, 249, .15)',
    divider: 'rgba(255,255,255,.12)',
    tabColor: globalColors.white,

    textPrimary: globalColors.white,
    textSecondary: 'rgba(255, 255, 255, 0.7)',
    textContrast: globalColors.gray700,

    navbarBackground: globalColors.gray900,
    navbarColor: globalColors.white,
    navbarBorder: globalColors.gray900,
    navbarLogoBackground: 'transparent',
    footerLogoBackground: globalColors.white,
    type: 'dark',
};

const legend = {
    default: lightDefault,
    highContrast: highContrast,
    negativeContrast: negativeContrast,
    lightBackground: lightBackground,
    traditional: traditional,
};

export const getColors = type => {
    return legend[type];
};

export const makeTheme = type => {
    const colors = legend[type] || lightDefault;
    //set css variables
    document.documentElement.style.setProperty('--gray-000', colors.gray000);
    document.documentElement.style.setProperty('--gray-100', colors.gray100);
    document.documentElement.style.setProperty('--gray-200', colors.gray200);
    document.documentElement.style.setProperty('--gray-300', colors.gray300);
    document.documentElement.style.setProperty('--gray-400', colors.gray400);
    document.documentElement.style.setProperty('--gray-500', colors.gray500);
    document.documentElement.style.setProperty('--gray-600', colors.gray600);
    document.documentElement.style.setProperty('--gray-700', colors.gray700);
    document.documentElement.style.setProperty('--gray-800', colors.gray800);
    document.documentElement.style.setProperty('--gray-900', colors.gray900);

    document.documentElement.style.setProperty('--background-default', colors.backgroundDefault);
    document.documentElement.style.setProperty('--background-000', colors.background000);
    document.documentElement.style.setProperty('--background-100', colors.background100);
    document.documentElement.style.setProperty('--background-200', colors.background200);
    document.documentElement.style.setProperty('--background-300', colors.background300);
    document.documentElement.style.setProperty(
        '--background-transparent',
        colors.backgroundTransparent
    );
    document.documentElement.style.setProperty('--background-blue', colors.backgroundBlue);

    document.documentElement.style.setProperty('--primary', colors.primary);
    document.documentElement.style.setProperty('--primary-light', colors.primaryLight);
    document.documentElement.style.setProperty('--primary-100', colors.primary100);
    document.documentElement.style.setProperty('--primary-200', colors.primary200);
    document.documentElement.style.setProperty('--primary-dark', colors.primaryDark);
    document.documentElement.style.setProperty('--primary-highlight', colors.primaryHighlight);

    document.documentElement.style.setProperty('--secondary', colors.secondary);
    document.documentElement.style.setProperty('--secondary-dark', colors.secondaryDark);
    document.documentElement.style.setProperty('--secondary-100', colors.secondary100);
    document.documentElement.style.setProperty('--secondary-200', colors.secondary200);
    document.documentElement.style.setProperty('--divider', colors.divider);

    document.documentElement.style.setProperty('--orange-dark', colors.orangeDark);
    document.documentElement.style.setProperty('--orange-light', colors.orangeLight);
    document.documentElement.style.setProperty('--purple', colors.purple);
    document.documentElement.style.setProperty('--green', colors.green);
    document.documentElement.style.setProperty('--blue', colors.blue);

    document.documentElement.style.setProperty('--tab-color', colors.tabColor);
    document.documentElement.style.setProperty('--text-primary', colors.textPrimary);
    document.documentElement.style.setProperty('--text-secondary', colors.textSecondary);

    document.documentElement.style.setProperty('--navbar-background', colors.navbarBackground);
    document.documentElement.style.setProperty('--navbar-border', colors.navbarBorder);
    document.documentElement.style.setProperty('--navbar-color', colors.navbarColor);
    document.documentElement.style.setProperty(
        '--navbar-logo-background',
        colors.navbarLogoBackground
    );
    document.documentElement.style.setProperty(
        '--footer-logo-background',
        colors.footerLogoBackground
    );
    document.documentElement.style.setProperty('--text-contrast', colors.textContrast);

    //for duo icons
    document.documentElement.style.setProperty('--fa-primary-color', colors.primary);
    document.documentElement.style.setProperty('--fa-secondary-color', colors.primaryLight);
    document.documentElement.style.setProperty('--dark-gray', globalColors.gray900);

    return createTheme({
        props: {
            MuiTypography: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    subtitle1: 'p',
                    subtitle2: 'p',
                    body1: 'p',
                    body2: 'p',
                    caption: 'p',
                },
            },
        },
        typography: {
            fontFamily: [
                'Nunito Sans',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            h1: {
                fontSize: '3.2rem',
                lineHeight: 1.2,
                letterSpacing: '0em',
                fontWeight: 400,
                fontFamily: 'DM Serif Display',
                '@media (max-width:768px)': {
                    fontSize: '2.8rem',
                    lineHeight: 1.2,
                },
            },
            h2: {
                fontWeight: 400,
                fontSize: '2.5rem',
                lineHeight: 1.167,
                letterSpacing: '0.00735em',
                fontFamily: 'DM Serif Display',
                '@media (max-width:768px)': {
                    fontSize: '2.1rem',
                    lineHeight: 1.3,
                },
            },
            h3: {
                fontWeight: 600,
                fontSize: '1.25rem',
                lineHeight: 1.2,
                letterSpacing: '0.0075em',
            },
            h5: {
                fontSize: '1.1rem',
                lineHeight: 1.3,
                fontWeight: 700,
            },
            h6: {
                fontSize: '1rem',
                lineHeight: 1.3,
                fontWeight: 600,
            },
            body1: {
                lineHeight: 1.4,
                letterSpacing: '0',
            },
            subtitle1: {
                fontSize: '1rem',
                lineHeight: 1.3,
            },
            subtitle2: {
                fontWeight: 700,
                lineHeight: 1.25,
            },
            caption: {
                lineHeight: 1.3,
            },
        },
        breakpoints: {
            keys: ['xs', 'sm', 'md', 'lg', 'xl'],
            values: { xs: 0, sm: 600, md: 768, lg: 1036, xl: 1140 },
        },
        palette: {
            common: {
                black: globalColors.gray900,
                white: '#fff',
            },
            text: {
                primary: colors.textPrimary,
                secondary: colors.textSecondary,
                contrast: colors.textContrast,
                //secondary: '#fff',
                // disabled: colors.gray400,
                // hint: colors.gray300,
            },
            primary: {
                main: colors.primary,
                light: colors.primaryLight,
                dark: colors.primaryDark,
                light100: colors.primary100,
                light200: colors.primary200,
                highlight: colors.primaryHighlight,
                // contrastText: '#fff',
            },
            secondary: {
                main: colors.secondary,
                dark: colors.secondaryDark,
            },
            background: {
                paper: colors.background100,
                '000': colors.background000,
                100: colors.background100,
                200: colors.background200,
                300: colors.background300,
                default: colors.backgroundDefault,
            },
            grey: {
                900: colors.gray900,
                800: colors.gray800,
                700: colors.gray700,
                600: colors.gray600,
                500: colors.gray500,
                400: colors.gray400,
                300: colors.gray300,
                200: colors.gray200,
                100: colors.gray100,
                '000': colors.gray000,
                50: colors.gray50,
                A100: colors.A100,
                A200: colors.A200,
                A400: colors.A400,
                A700: colors.A700,
            },
            divider: colors.divider,
            //my own colors
            misc: {
                orangeDark: colors.orangeDark,
                orangeLight: colors.orangeLight,
                purple: colors.purple,
                green: colors.green,
                darkBlue: colors.darkBlue,
            },
            type: colors.type,
        },
        //TODO get shadows from original theme and just overwrite ones needed (1 & 2)
        shadows: [
            'none',
            '0 0 15px 0 rgba(0, 0, 0, 0.06)',
            '0px 0px 0px -6px rgba(0,0,0,0.1),0px 1px 6px 0px rgba(0,0,0,0.1),0px 1px 3px 0px rgba(0,0,0,0.05)',
            '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
            '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
            '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
            '1px 1px 1rem -1px rgba(0, 0, 0, 0.17), 1px 1px  .5rem -1px rgba(0, 0, 0, 0.12)',
            '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
            '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
            '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
            '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
            '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
            '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
            '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
            '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
            '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
            '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
            '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
            '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
            '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
            '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
            '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
            '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
            '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
            '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
        ],
        overrides: {
            MuiButton: {
                contained: {
                    boxShadow: 'none',
                    padding: '8px 16px',
                    '&:disabled': {
                        backgroundColor: colors.gray200,
                    },
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                root: {
                    textTransform: 'capitalize',
                    borderRadius: 8,
                },
                label: {
                    fontWeight: 700,
                },
                containedSizeSmall: {
                    paddingTop: 2,
                    paddingBottom: 2,
                },
                containedSizeLarge: {
                    paddingTop: 12,
                    paddingBottom: 12,
                },
            },
            MuiOutlinedInput: {
                root: {
                    // backgroundColor: 'white',
                    '&:hover': {
                        // '& .MuiOutlinedInput-notchedOutline': {
                        //     borderColor: 'yellow'
                        // },
                    },
                },
                input: {
                    height: 25,
                    fontSize: 15,
                    padding: 12,
                },
                // notchedOutline: {
                //     borderColor: 'hotpink'
                // },
            },

            MuiPaper: {
                root: {
                    borderWidth: 1,
                    borderColor: colors.divider,
                    borderStyle: 'solid',
                },
                rounded: {
                    borderRadius: 3,
                },
            },
            MuiTabs: {
                root: {
                    minHeight: 40,
                },
                indicator: {
                    height: 2,
                    borderRadius: 5,
                    backgroundColor: colors.primary,
                },
            },
            MuiTab: {
                root: {
                    minWidth: 70,
                    minHeight: 40,
                    textTransform: 'capitalize',
                    fontWeight: 600,
                    '@media (min-width: 960px)': {
                        minWidth: 70,
                    },
                },
            },
            MuiBadge: {
                badge: {
                    fontSize: 11,
                    lineHeight: 11,
                    height: 16,
                    minWidth: 16,
                    padding: 5,
                    marginTop: 4,
                },
            },
            MuiFab: {
                root: {
                    height: 70,
                    width: 70,
                    backgroundColor: colors.background100,
                    color: colors.textPrimary,
                    '&:hover': {
                        backgroundColor: colors.backgroundDefault,
                    },
                },
            },
            MuiChip: {
                sizeSmall: {
                    height: 17,
                    lineHeight: '17px',
                    fontSize: 11,
                },
            },
            MuiIconButton: {
                root: {
                    color: colors.textPrimary,
                    fontSize: '1.2rem',
                },
                label: {
                    fontSize: '1.2rem',
                },
            },
            MuiIcon: {
                fontSizeSmall: {
                    height: '1.2rem',
                    width: '1.2rem',
                    fontSize: '1.2rem',
                    lineHeight: '1.2rem',
                },
            },
            MuiSkeleton: {
                rect: {
                    borderRadius: 5,
                },
            },
        },
    });
};
