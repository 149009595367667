import { authReducer } from './auth/auth.reducer';
import { redirectionReducer } from './redirection/redirection.reducer';
import { exceptionReducer } from './exception/exception.reducer';
import { ghostingReducer } from './ghosting/ghosting.reducer';
import { toastReducer } from './toast/toast.reducer';
import { interruptReducer } from './interrupt/interrupt.reducer';
import { signinReducer } from './auth/signin/signin.reducer';
import { forgotPasswordReducer } from './auth/forgot-password/forgot-password.reducer';
import { resetPasswordReducer } from './auth/reset-password/reset-password.reducer';
import { tfaReducer } from './auth/tfa/tfa.reducer';
import { categoryReducer } from './articles/category/category.reducer';
import { articleReducer } from './articles/article/article.reducer';
import { signupReducer } from './signup/signup.reducer';
import { configReducer } from './config/config.reducer';
import { bladeReducer } from './blade/blade.reducer';
import accountReducer from './account/account.reducer';
import { reauthReducer } from './account/reauth/reauth.reducer';
import { verifyReducer } from './verify/verify.reducer';

export const sharedReducers = {
    authReducer,
    redirectionReducer,
    exceptionReducer,
    ghostingReducer,
    toastReducer,
    interruptReducer,
    signinReducer,
    signupReducer,
    forgotPasswordReducer,
    resetPasswordReducer,
    tfaReducer,
    categoryReducer,
    articleReducer,
    reauthReducer,
    configReducer,
    bladeReducer,
    accountReducer,
    verifyReducer,
};
