import React from 'react';
import classes from './menu.styles.module.scss';
import FlexBox from '@/shared/components/flex-box/flex-box';
import {
    ListItemText,
    ListItemSecondaryAction,
    Paper,
    Typography,
    ListItem,
    List,
} from '@mui/material';
import clsx from 'clsx';
import history from '@/shared/history';
import MenuComponent from '@/shared/components/menu/menu.component';

export const Menu = props => {
    const service = props.user ? props.user.service : null;
    const routes = [
        {
            name: 'Basic Information',
            link: '/account/contact',
            routes: ['/account/contact'],
        },
        {
            name: 'Subscription',
            link: '/account/services',
            routes: ['/account/services'],
        },
        {
            name: 'Security',
            link: '/account/password',
            routes: ['/account/tfa', '/account/password', '/account/sessions', '/account/trusted'],
        },
        {
            name: 'Settings',
            link: '/account/settings',
            routes: ['/account/settings'],
        },
        {
            name: 'Notifications',
            link: '/account/notifications',
            routes: ['/account/notifications'],
        },
        {
            name: 'Payments',
            link: '/account/payment',
            routes: ['/account/payment'],
        },
        {
            name: 'Orders',
            link: '/account/orders',
            routes: ['/account/orders'],
        },
    ];

    const isActive = route => {
        if (route.routes) {
            const path = window.location.pathname;
            if (route.routes.includes(path)) {
                return true;
            }
        }
        return false;
    };

    const navigate = route => {
        if (isActive(route)) {
            return;
        }
        if (window.isDesktop) {
            history.push(route.link);
        } else {
            history.push(route.link);
        }
    };

    return (
        <React.Fragment>
            {!window.isDesktop && <MenuComponent bottomBorder title="Account" showBack />}
            <FlexBox flex1 style={props.style}>
                <Paper className={classes.paper}>
                    <List component="nav" className={classes.root} aria-label="contacts">
                        {routes.map((route, i) => {
                            return (
                                <ListItem
                                    onClick={() => navigate(route)}
                                    key={i}
                                    className={clsx(
                                        classes.item,
                                        isActive(route) && classes.active
                                    )}
                                    button
                                >
                                    <ListItemText primary={route.name} />
                                    {service &&
                                        !service.is_current &&
                                        route.name == 'Current Subscription' && (
                                            <ListItemSecondaryAction>
                                                <Typography variant="subtitle2" color="secondary">
                                                    Expired
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        )}
                                </ListItem>
                            );
                        })}
                    </List>
                </Paper>
            </FlexBox>
        </React.Fragment>
    );
};
