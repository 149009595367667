import React, { useState, useEffect } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Button, Grid as MuiGrid, Paper, Typography } from '@mui/material';

export const Grid = props => {
    const {
        items,
        key,
        column1,
        column2,
        column3,
        column4,
        column1Title,
        column2Title,
        column3Title,
    } = props;

    let itemsRendered = null;

    if (window.isDesktop) {
        itemsRendered = items.map((s, i) => {
            return (
                <MuiGrid
                    className="p-3"
                    style={{
                        borderBottom: '1px solid #ecedf3',
                        alignItems: 'center',
                    }}
                    container
                    key={i}
                >
                    <MuiGrid item xs={3}>
                        {column1(s)}
                    </MuiGrid>
                    <MuiGrid item xs={5}>
                        {column2(s)}
                    </MuiGrid>
                    <MuiGrid item xs={3}>
                        {column3(s)}
                    </MuiGrid>
                    <MuiGrid item xs={1}>
                        {column4(s)}
                    </MuiGrid>
                </MuiGrid>
            );
        });
    } else {
        itemsRendered = items.map((s, i) => {
            return (
                <MuiGrid
                    className="p-3"
                    style={{
                        borderBottom: '1px solid #ecedf3',
                        alignItems: 'center',
                    }}
                    container
                    key={i}
                >
                    <MuiGrid item xs={6}>
                        <FlexBox className="pr-3">
                            {column1(s)}
                            {column2(s)}
                        </FlexBox>
                    </MuiGrid>
                    <MuiGrid item xs={4}>
                        {column3(s)}
                    </MuiGrid>
                    <MuiGrid item xs={2}>
                        {column4(s)}
                    </MuiGrid>
                </MuiGrid>
            );
        });
    }
    return (
        <FlexBox>
            {window.isDesktop && (
                <MuiGrid className="px-3" container>
                    <MuiGrid item xs={3}>
                        <Typography variant="subtitle2">
                            {column1Title}
                        </Typography>
                    </MuiGrid>
                    <MuiGrid item xs={5}>
                        <Typography variant="subtitle2">
                            {column2Title}
                        </Typography>
                    </MuiGrid>
                    <MuiGrid item xs={3}>
                        <Typography variant="subtitle2">
                            {column3Title}
                        </Typography>
                    </MuiGrid>
                    <MuiGrid item xs={1}></MuiGrid>
                </MuiGrid>
            )}
            <Paper className="mt-2" variant="outlined" elevation={0}>
                <FlexBox>{itemsRendered}</FlexBox>
            </Paper>
        </FlexBox>
    );
};
