import axiosAPI from '@/shared/api';

export const getTFA = () => async dispatch => {
    dispatch(getTFARequested());

    return axiosAPI()
        .get('account/tfa')
        .then(response => {
            dispatch(getTFASuccess(response.data));
        })
        .catch(error => {
            dispatch(getTFAFailed(error.response));
        });
};

export const enableTFA = () => async dispatch => {
    dispatch(enableTFARequested());

    return axiosAPI()
        .patch('account/tfa/enable')
        .then(response => {
            dispatch(enableTFASuccess(response.data));
        })
        .catch(error => {
            dispatch(axiosAPI().retryAction(() => enableTFA()));
            dispatch(enableTFAFailed(error.response));
        });
};

export const disableTFA = () => async dispatch => {
    dispatch(disableTFARequested());

    return axiosAPI()
        .patch('account/tfa/disable')
        .then(response => {
            dispatch(disableTFASuccess(response.data));
        })
        .catch(error => {
            dispatch(axiosAPI().retryAction(() => disableTFA()));
            dispatch(disableTFAFailed(error.response));
        });
};

export const deleteChannel = channelId => async dispatch => {
    dispatch(deleteChannelRequested());

    return axiosAPI()
        .delete(`account/tfa/${channelId}`)
        .then(response => {
            dispatch(deleteChannelSuccess(response.data));
            // dispatch(getTFA());
        })
        .catch(error => {
            dispatch(axiosAPI().retryAction(() => deleteChannel(channelId)));
            dispatch(deleteChannelFailed(error.response));
        });
};

export const updatePrimary = channelId => async dispatch => {
    dispatch(updatePrimaryRequested());

    return axiosAPI()
        .patch(`account/tfa/${channelId}`)
        .then(response => {
            dispatch(updatePrimarySuccess(response.data));
            dispatch(getTFA());
        })
        .catch(error => {
            dispatch(axiosAPI().retryAction(() => updatePrimary(channelId)));
            dispatch(updatePrimaryFailed(error.response));
        });
};

export const showDisableConfirmModal = () => dispatch => {
    dispatch(showDisableConfirmSuccess());
};

export const hideDisableConfirmModal = () => dispatch => {
    dispatch(hideDisableConfirmSuccess());
};

export const showDeleteConfirmModal = () => dispatch => {
    dispatch(showDeleteConfirmSuccess());
};

export const hideDeleteConfirmModal = () => dispatch => {
    dispatch(hideDeleteConfirmSuccess());
};

function showDisableConfirmSuccess() {
    return {
        type: 'SHOW_TFA_CONFIRM_SUCCESS',
    };
}

function hideDisableConfirmSuccess() {
    return {
        type: 'HIDE_TFA_CONFIRM_SUCCESS',
    };
}

function showDeleteConfirmSuccess() {
    return {
        type: 'SHOW_DELETE_CONFIRM_SUCCESS',
    };
}

function hideDeleteConfirmSuccess() {
    return {
        type: 'HIDE_DELETE_CONFIRM_SUCCESS',
    };
}

function getTFARequested() {
    return {
        type: 'GET_TFA_REQUESTED',
    };
}

function getTFASuccess(payload) {
    return {
        type: 'GET_TFA_SUCCESS',
        payload,
    };
}

function getTFAFailed(error) {
    return {
        type: 'GET_TFA_FAILED',
        error,
    };
}

function enableTFARequested() {
    return {
        type: 'ENABLE_TFA_REQUESTED',
    };
}

function enableTFASuccess(payload) {
    return {
        type: 'ENABLE_TFA_SUCCESS',
        payload,
    };
}

function enableTFAFailed(error) {
    return {
        type: 'ENABLE_TFA_FAILED',
        error,
    };
}

function disableTFARequested() {
    return {
        type: 'DISABLE_TFA_REQUESTED',
    };
}

function disableTFASuccess(payload) {
    return {
        type: 'DISABLE_TFA_SUCCESS',
        payload,
    };
}

function disableTFAFailed(error) {
    return {
        type: 'DISABLE_TFA_FAILED',
        error,
    };
}

function deleteChannelRequested() {
    return {
        type: 'DELETE_TFA_CHANNEL_REQUESTED',
    };
}

function deleteChannelSuccess(payload) {
    return {
        type: 'DELETE_TFA_CHANNEL_SUCCESS',
        payload,
    };
}

function deleteChannelFailed(error) {
    return {
        type: 'DELETE_TFA_CHANNEL_FAILED',
        error,
    };
}

function addSMSRequested() {
    return {
        type: 'ADD_SMS_CHANNEL_REQUESTED',
    };
}

function addSMSSuccess(payload) {
    return {
        type: 'ADD_SMS_CHANNEL_SUCCESS',
        payload,
    };
}

function addSMSFailed(error) {
    return {
        type: 'ADD_SMS_CHANNEL_FAILED',
        error,
    };
}

function updatePrimaryRequested() {
    return {
        type: 'UPDATE_TFA_PRIMARY_REQUESTED',
    };
}

function updatePrimarySuccess(payload) {
    return {
        type: 'UPDATE_TFA_PRIMARY_SUCCESS',
        payload,
    };
}

function updatePrimaryFailed(error) {
    return {
        type: 'UPDATE_TFA_PRIMARY_FAILED',
        error,
    };
}
