import { orderBy } from 'lodash';

const initialState = {
    countries: [],
};

//TODO add default criteria to here
export const configReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_COUNTRIES_REQUESTED':
            return {
                ...state,
            };
        case 'GET_COUNTRIES_SUCCESS':
            return {
                ...state,
                countries: orderBy(
                    Object.keys(action.countries).map(key => {
                        return {
                            key,
                            name: action.countries[key],
                        };
                    }),
                    'name',
                    'asc'
                ),
            };
        case 'GET_COUNTRIES_FAILED':
            return {
                ...state,
            };
    }
};
