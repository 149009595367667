import axiosAPI from '@/shared/api';

export const getOrderHistory = () => async dispatch => {
    dispatch(getOrderHistoryRequested());

    return axiosAPI()
        .get('account/orders')
        .then(response => {
            dispatch(getOrderHistorySuccess(response.data));
        })
        .catch(error => {
            dispatch(getOrderHistoryFailed(error.response));
        });
};

function getOrderHistoryRequested() {
    return {
        type: 'GET_ORDER_HISTORY_REQUESTED',
    };
}

function getOrderHistorySuccess(payload) {
    return {
        type: 'GET_ORDER_HISTORY_SUCCESS',
        payload,
    };
}

function getOrderHistoryFailed(error) {
    return {
        type: 'GET_ORDER_HISTORY_FAILED',
        error,
    };
}

export const getIndividualOrderHistory = id => async dispatch => {
    dispatch(getIndividualOrderHistoryRequested());

    return axiosAPI()
        .get('account/orders/' + id)
        .then(response => {
            dispatch(getIndividualOrderHistorySuccess(response.data));
        })
        .catch(error => {
            dispatch(getIndividualOrderHistoryFailed(error.response));
        });
};

function getIndividualOrderHistoryRequested() {
    return {
        type: 'GET_INDIVIDUAL_ORDER_HISTORY_REQUESTED',
    };
}

function getIndividualOrderHistorySuccess(payload) {
    return {
        type: 'GET_INDIVIDUAL_ORDER_HISTORY_SUCCESS',
        payload,
    };
}

function getIndividualOrderHistoryFailed(error) {
    return {
        type: 'GET_INDIVIDUAL_ORDER_HISTORY_FAILED',
        error,
    };
}
