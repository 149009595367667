import React, { useEffect, useRef, Component, useCallback, useMemo } from 'react';
import { Modal as MuiModal, IconButton, Backdrop, Typography, Slide } from '@mui/material';
// import { withStyles } from '@mui/material/styles';
import { styled } from "@mui/material/styles";
import classes from './modal.module.scss';
import FlexBox from '../flex-box/flex-box';
import { Paper } from '../mui';
import clsx from 'clsx';
import history from '@/shared/history';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const Modal = props => {
    // state = {
    //     popped: false,
    //     scrollY: 0,
    // };
    const modalRef = useRef(null);
    const defaults = {
        disableBackdropClick: true,
        disableEscapeKeyDown: false,
        hideClose: false,
        mobileFullscreen: true,
        size: 'medium',
        title: null,
        toolbar: null,
        content: null,
        className: null,
        position: null,
        open: true,
        onClose: () => { },
    };

    const popPage = () => {
        // setState({ popped: true }, () => {
        if (props.onClose) {
            props.onClose();
        }
        // });
    };

    useEffect(() => {
        if (modalRef.current) {
            disableBodyScroll(modalRef.current);
        }

        return () => {
            if (modalRef.current) {
                enableBodyScroll(modalRef.current);
            }
        };
    }, []);

    const ToBeStyledBackdrop = ({ className, ...props }) => {
        <Backdrop {...props} classes={{ root: className }} />
    }

    const customBackdrop = useMemo(
        () =>
            styled(ToBeStyledBackdrop)({
                backgroundColor: `rgba(0,0,0,${props.backdropOpacity})`,
            }),
        [props.backdropOpacity]
    );

    const options = { ...defaults, ...props };

    let classNames = [classes.modal];
    switch (options.size) {
        case 'xs':
            classNames.push(classes.xs);
            break;
        case 'small':
            classNames.push(classes.sm);
            break;
        case 'large':
            classNames.push(classes.lg);
            break;
        case 'auto':
            classNames.push(classes.auto);
            break;
        default:
            classNames.push(classes.md);
            break;
    }

    const onClose = () => {
        options.onClose();
    };

    options.mobileFullscreen && classNames.push(classes.mobileFullscreen);

    if (options.className) {
        classNames.push(props.className);
    }

    const joinedClasses = classNames.join(' ');

    return (
        <MuiModal
            ref={modalRef}
            BackdropComponent={
                props.BackdropComponent
                    ? props.BackdropComponent
                    : props.backdropOpacity
                        ? customBackdrop
                        : Backdrop
            }
            BackdropProps={{
                classes: {
                    root: classes.backDrop,
                },
            }}
            open={options.open}
            onClose={onClose}
            disableBackdropClick={options.disableBackdropClick}
            disableEscapeKeyDown={options.disableEscapeKeyDown}
            className={clsx(
                classes.modalContainer,
                options.position == 'bottom' && classes.positionBottom,
                options.mobileFullscreen && classes.mobileFullScreenContainer
            )}
            style={props.containerStyle}
        >
            <Slide direction="up" in={options.open} mountOnEnter unmountOnExit>
                <FlexBox style={props.style} className={joinedClasses}>
                    {!options.hideClose && (
                        <IconButton
                            aria-label="close"
                            className={clsx(classes.close, props.closeClassName)}
                            onClick={onClose}
                        >
                            <i className="fal fa-times" />
                        </IconButton>
                    )}
                    {(options.title || options.toolbar) && (
                        <ModalHeader title={options.title} toolbar={options.toolbar} />
                    )}
                    <Paper level={0} elevation={0} className="h-100 w-100">
                        {options.content ? options.content : props.children}
                    </Paper>
                </FlexBox>
            </Slide>
        </MuiModal>
    );
};

export default Modal;

export class ModalHeader extends Component {
    render() {
        const { title, toolbar, className } = this.props;
        return (
            <FlexBox
                row
                aligncenter
                justifybetween
                className={classes.headerContainer + ' ' + className}
            >
                <Typography variant={window.isDesktop ? 'h6' : 'body1'}>{title}</Typography>
                {toolbar && (
                    <FlexBox row className="mr-4">
                        {toolbar}
                    </FlexBox>
                )}
            </FlexBox>
        );
    }
}
