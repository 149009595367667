import React, { useState, useEffect } from 'react';
import LayoutContainer from '@/shared/components/layout-container/layout-container';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { FormGroup, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import classes from './profile-status.styles.module.scss';
import { AccountLayout } from '../account-layout';

export const ProfileStatus = props => {
    const [status, setStatus] = useState(null);

    useEffect(() => {
        document.title = 'Profile Status';
        setStatus(props.has_active_profile ? 'active' : 'inactive');
    }, []);

    useEffect(() => {
        setStatus(props.has_active_profile ? 'active' : 'inactive');
    }, [props.has_active_profile]);

    const setTo = () => {
        if (props.has_active_profile) return 'inactive';
        return 'active';
    };

    const updateStatus = async () => {
        await props.updateProfileStatus(`/account/status/${setTo()}`);
    };

    return (
        <AccountLayout
            menuProps={{
                showBack: !window.isDesktop,
                title: 'Profile Status',
                rightOptionsPosition: 'end',
                rightOptions: [
                    <Button
                        key="0"
                        onClick={updateStatus}
                        color={status == 'active' ? 'secondary' : 'primary'}
                    >
                        Set Status To{' '}
                        {props.has_active_profile ? 'inactive' : 'active'}
                    </Button>,
                ],
            }}
        >
            {status && (
                <React.Fragment>
                    <FlexBox className={classes.content}>
                        <Typography variant="h6" className="mb-1">
                            Your profile is currently{' '}
                            <Typography
                                variant="h6"
                                component="span"
                                className={classes.status}
                                color={
                                    status == 'active' ? 'primary' : 'secondary'
                                }
                            >
                                {status}
                            </Typography>
                        </Typography>
                        <div>
                            Setting your profile to inactive prevents access to
                            all parts of the site except for account & help
                            pages. You will not appear in member's search
                            results, receive new matches or be matched with
                            anyone. This is a great alternative to removing your
                            account if you simply need a break.
                        </div>
                    </FlexBox>
                </React.Fragment>
            )}
        </AccountLayout>
    );
};
