import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Modal from '@/shared/components/modal/modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import FlexBox from '@/shared/components/flex-box/flex-box';

export const Reauth = props => {
    const [password, setPassword] = useState('');

    const authenticate = password => {
        props.reauthenticateAccount(password);
    };

    useDidUpdateEffect(() => {
        if (props.success) {
            props.onSuccess();
        }
    }, [props.success]);

    return (
        <Modal size="auto" hideClose mobileFullscreen={false}>
            <FlexBox column className="p-4">
                <Typography variant="h6" component="p" style={{ maxWidth: 300 }}>
                    {props.error}
                </Typography>
                <Typography
                    variant="subtitle2"
                    component="label"
                    htmlFor="password-input"
                    className="mt-3"
                >
                    Password
                </Typography>
                <TextField
                    variant="outlined"
                    style={{ minWidth: 250, maxwidth: 300 }}
                    id="password-input"
                    className="mt-1"
                    placeholder=""
                    value={password}
                    type="password"
                    autoComplete="off"
                    error={props.reauthError}
                    helperText={props.reauthError && 'Invalid Password'}
                    onChange={event => setPassword(event.target.value)}
                />
                <Button
                    onClick={() => {
                        authenticate(password);
                    }}
                    variant="contained"
                    color="primary"
                    className="mt-3 ml-auto"
                >
                    Submit
                </Button>
            </FlexBox>
        </Modal>
    );
};
