import React, { useState } from 'react';
import { FlexBox, IconText, Modal } from '@/shared/components';
import { Paper, Button, Link, Typography, Divider } from '@mui/material';
import classes from './subscription-details.styles.module.scss';
import clsx from 'clsx';
import SafeHtml from '@/shared/components/safe-html/safe-html';
import { useHistory } from 'react-router-dom';

export const SubscriptionDetails = ({
    product: {
        current = false,
        features = [],
        messages,
        id,
        color = '#3271a0',
        confirm,
        savings_pct,
        subscription_months,
        cost_per_month,
        button_text,
        title,
        heading,
        end_date,
    },
    isExpired,
    nextSubscription,
    upgradeSubscription,
    purchaseSubscription,
    hasPremium,
    renewPremium,
}) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const isBasic = subscription_months == 0;

    const adjustedFeatures = current || expanded ? features : features.slice(0, 4);

    const isNextDifferent = nextSubscription?.subscription_months !== subscription_months;

    const showDivider = !(current && isBasic) || expanded;

    return (
        <div className="mb-4" style={{ borderRadius: 8, overflow: 'hidden' }}>
            <Paper
                variant="outlined"
                elevation={0}
                style={current ? { borderColor: color, borderWidth: 2, borderRadius: 8 } : { borderRadius: 8 }}
            >
                <div style={{ backgroundColor: color }}>
                    {current && (
                        <div className="px-3 py-2" style={{ margin: -2, background: 'rgba(0,0,0,.15)' }}>
                            <Typography
                                variant="body2"
                                style={{
                                    color: 'white',
                                    textTransform: 'uppercase',
                                    fontWeight: 800,
                                }}
                            >
                                {heading}
                            </Typography>
                        </div>
                    )}
                    <FlexBox row aligncenter className="px-3 py-5">
                        <Typography variant="h3" style={{ fontSize: 40, color: 'white', fontWeight: 800 }}>
                            {title}
                        </Typography>
                    </FlexBox>
                </div>
                {current && (
                    <Typography variant="h6" className="px-3 pt-4 pb-1">
                        Currently, your plan includes:
                    </Typography>
                )}
                <FlexBox className="px-3 pt-3" row={window.isDesktop}>
                    <FlexBox flex1>
                        {adjustedFeatures.slice(0, Math.ceil(adjustedFeatures.length / 2)).map((feature, i) => (
                            <IconText key={i} text={feature} classes={{ icon: classes.icon }} icon="check"></IconText>
                        ))}
                    </FlexBox>
                    <FlexBox flex1>
                        {adjustedFeatures.slice(Math.ceil(adjustedFeatures.length / 2)).map((feature, i) => (
                            <IconText key={i} text={feature} classes={{ icon: classes.icon }} icon="check"></IconText>
                        ))}
                    </FlexBox>
                </FlexBox>

                <React.Fragment>
                    {(messages?.length || (!current && features.length > 4)) && (
                        <div className="mb-3 ml-3">
                            <Link to="#" onClick={() => setExpanded(!expanded)} className={clsx(classes.link)}>
                                {!expanded ? 'See more details' : 'See Less'}
                            </Link>
                        </div>
                    )}
                    {current && isNextDifferent && (
                        <div
                            className={'p-3 mx-3 mb-3'}
                            style={{
                                border: '1px solid #dedede',
                                borderRadius: 4,
                                backgroundColor: '#f2f2f2',
                                maxWidth: 350,
                            }}
                        >
                            {isExpired && (
                                <Typography>
                                    Your Premium plan expired on <strong>{end_date}</strong>
                                </Typography>
                            )}

                            {!isExpired && (
                                <Typography>
                                    Your plan will change to {nextSubscription.title} on <strong>{end_date}</strong>
                                </Typography>
                            )}
                        </div>
                    )}
                    {showDivider && <Divider className="mx-3"></Divider>}
                    <FlexBox className="p-3">
                        <FlexBox flex1 row w100 justifybetween={expanded} justifystart aligncenter>
                            {!isBasic && (
                                <Typography className="mr-2" variant="h6">
                                    <strong>${cost_per_month}</strong>
                                    <span style={{ fontWeight: 400, fontSize: '1rem' }}>/mo</span>
                                </Typography>
                            )}

                            {!!savings_pct && (
                                <Typography
                                    className="mr-2"
                                    style={{
                                        fontStyle: 'italic',
                                        fontWeight: 800,
                                        color: '#608f0f',
                                    }}
                                    variant="h6"
                                >
                                    (You save {savings_pct}%)
                                </Typography>
                            )}
                            {button_text && (
                                <Button
                                    className="ml-auto"
                                    variant={'outlined'}
                                    style={{ whiteSpace: 'nowrap' }}
                                    onClick={hasPremium ? () => setShowConfirm(true) : () => purchaseSubscription(id)}
                                >
                                    {button_text}
                                </Button>
                            )}
                        </FlexBox>
                        {expanded && messages?.length && (
                            <div className="mt-3 mr-2">
                                {messages.map((message, i) => (
                                    <Typography key={i} className={clsx(i !== 0 && 'mt-3')} variant="body1">
                                        {message}
                                    </Typography>
                                ))}
                            </div>
                        )}
                    </FlexBox>
                </React.Fragment>

                {confirm && showConfirm && (
                    <MultiStepConfirmModal
                        steps={Array.isArray(confirm) ? confirm : [confirm]}
                        onClose={() => setShowConfirm(false)}
                        buttonMethods={{
                            upgrade: () => upgradeSubscription(id),
                            renewPremium,
                            downgrade: () => upgradeSubscription(id),
                        }}
                    ></MultiStepConfirmModal>
                    // <ConfirmModal
                    //     buttonMethods={{
                    //         upgrade: () => upgradeSubscription(id),
                    //         downgrade: cancelSubscription,
                    //     }}
                    //     onClose={() => setShowConfirm(false)}
                    //     {...confirm}
                    // ></ConfirmModal>
                )}
            </Paper>
        </div>
    );
};

const ConfirmModal = ({ title, message, buttons, onClose, onButtonClick, buttonMethods }) => {
    const history = useHistory();
    return (
        <Modal
            mobileFullscreen={false}
            disableBackdropClick={false}
            style={{ maxWidth: 500 }}
            size="auto"
            hideClose
            backdropOpacity={0.9}
        >
            <FlexBox>
                <Typography
                    style={{ backgroundColor: '#3271a0', fontSize: '1.4rem', color: 'white' }}
                    className="px-4 py-3"
                    variant="h5"
                >
                    {title}
                </Typography>
                <Divider style={{ width: '100%' }}></Divider>
                <Typography
                    className={clsx('p-4', classes.confirmMessage)}
                    variant="body1"
                    style={{ fontSize: '1rem', lineHeight: '1.4em' }}
                >
                    <SafeHtml>{message}</SafeHtml>
                </Typography>
            </FlexBox>
            <FlexBox className="mt-auto px-4 pb-2" aligncenter>
                {buttons?.map((button, i) => {
                    const click = () => {
                        buttonMethods?.[button.id]?.();
                        onButtonClick && onButtonClick(button);
                        if (button.to) {
                            history.push(button.to);
                        }
                        if (!button.next) {
                            onClose();
                        }
                    };

                    const buttonColor = button.color == 'warning' ? '' : button.color;

                    return (
                        <Button
                            key={button.id}
                            variant={buttonColor ? 'contained' : 'text'}
                            size={buttonColor ? 'large' : 'medium'}
                            color={buttonColor && 'primary'}
                            className={clsx(i == 0 && 'mb-2', classes[`${button.color}Btn`])}
                            onClick={click}
                        >
                            {button.text}
                        </Button>
                    );
                })}
            </FlexBox>
        </Modal>
    );
};
const MultiStepConfirmModal = ({ steps, ...props }) => {
    const [current, setCurrent] = useState(steps[0]);

    const onClick = button => {
        if (button.next) {
            setCurrent(steps.find(s => s.id == button.next));
        }
    };

    return <ConfirmModal {...current} {...props} onButtonClick={onClick}></ConfirmModal>;
};
