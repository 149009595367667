import React, { Fragment, Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default class Alert extends Component {
    defaults = {
        title: 'Alert',
        content: 'Something important occurred!',
        confirmText: 'OK',
        onConfirm: () => { },
        confirmOptions: {
            color: 'primary',
            variant: 'contained',
        },
    };

    render() {
        const options = { ...this.defaults, ...this.props };

        return (
            <Fragment>
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    style={{ zIndex: 99999 }}
                >
                    {options.title && (
                        <DialogTitle id="alert-dialog-title">{options.title}</DialogTitle>
                    )}
                    {options.content && (
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {options.content}
                            </DialogContentText>
                        </DialogContent>
                    )}

                    <DialogActions>
                        <Button onClick={options.onConfirm} {...options.confirmOptions}>
                            {options.confirmText}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}
