import axiosAPI from '@/shared/api';

export const getCountries = () => async dispatch => {
    dispatch(getCountriesRequested());
    return axiosAPI()
        .get('/util/resource/countries')
        .then(response => {
            dispatch(getCountriesSuccess(response.data.countries));
        })
        .catch(error => {
            dispatch(getCountriesFailed(error.response));
        });
};

function getCountriesRequested() {
    return {
        type: 'GET_COUNTRIES_REQUESTED',
    };
}

function getCountriesSuccess(countries) {
    return {
        type: 'GET_COUNTRIES_SUCCESS',
        countries,
    };
}

function getCountriesFailed(error) {
    return {
        type: 'GET_COUNTRIES_FAILED',
        error,
    };
}
