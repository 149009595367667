import React, { useEffect, useState } from 'react';
import helpers from '@/shared/util/helpers';
import { Typography } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './icon-text.styles.module.scss';
import clsx from 'clsx';

export const IconText = ({
    text,
    icon,
    className,
    classes: incomingClasses = {},
    bottomMargin = true,
}) => {
    return (
        <FlexBox
            style={bottomMargin && { marginBottom: '1rem' }}
            row
            alignstart
            className={className}
        >
            {icon && (
                <i
                    className={clsx(
                        classes.icon + ` mr-1 mt-1 far fa-${icon}`,
                        incomingClasses.icon
                    )}
                />
            )}
            <Typography style={{ fontWeight: 500 }}>{text}</Typography>
        </FlexBox>
    );
};
