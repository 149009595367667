import React, { useEffect } from 'react';
import LayoutContainer from '@/shared/components/layout-container/layout-container';
import { Chip, Typography, Paper, Grid } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import moment from 'moment';
import classes from './order.styles.module.scss';
import { AccountLayout } from '../../account-layout';
import clsx from 'clsx';
export const Order = props => {
    /**
     * Get the order when component mounts.
     */
    useEffect(() => {
        props.getIndividualOrderHistory(props.match.params.id);
    }, []);

    /**
     * Return the service name from an order.
     *
     * @param  {*} order
     * @return string
     */
    const service = order => {
        const item = props.order.order_items.find(item =>
            item.product.sku.toUpperCase().includes('SINGLES')
        );

        return item.product.name;
    };

    const gridPair = (text, value, bold = false) => {
        return (
            <React.Fragment>
                <Grid item xs={6}>
                    <FlexBox alignend>
                        <Typography style={{ fontWeight: bold ? 700 : 500 }}>
                            {text}
                        </Typography>
                    </FlexBox>
                </Grid>
                <Grid item xs={6}>
                    <FlexBox alignstart>
                        <Typography style={{ fontWeight: bold ? 700 : 500 }}>
                            ${value}
                        </Typography>
                    </FlexBox>
                </Grid>
            </React.Fragment>
        );
    };

    if (!props.order) return null;
    return (
        <AccountLayout
            menuProps={{
                showBack: true,
                backText: 'Back to Orders',
                title: `Order #${props.order.id}`,
                rightOptions: [
                    <Chip
                        key="0"
                        size="small"
                        label={props.order.status.toLowerCase()}
                        className={
                            props.order.status.toLowerCase == 'completed'
                                ? classes.chipPrimary
                                : classes.chipSecondary
                        }
                    />,
                ],
                rightOptionsPosition: 'end',
            }}
        >
            <Paper className="mt-2" variant="outlined" elevation={0}>
                <FlexBox className={classes.order}>
                    <FlexBox
                        className={classes.section}
                        row
                        aligncenter
                        justifybetween
                    >
                        <Typography variant="h6" component="p">
                            {service(props.order)}
                        </Typography>
                        <Typography variant="h6" component="p" className="mr-3">
                            ${props.order.subtotal_amount}
                        </Typography>
                    </FlexBox>
                    <FlexBox className={classes.section} alignend>
                        <Grid container spacing={3} style={{ width: 200 }}>
                            {gridPair('Sub Total', props.order.subtotal_amount)}
                            {gridPair('Discounts', props.order.discount_value)}
                            {props.order.user_balance_value > 0 &&
                                gridPair(
                                    'User Balance',
                                    props.order.user_balance_value
                                )}
                            {gridPair('Total', props.order.amount, true)}
                        </Grid>
                    </FlexBox>

                    {props.order.payment ? (
                        <React.Fragment>
                            <FlexBox className={classes.section}>
                                <Typography variant="h6" component="p">
                                    Payment
                                </Typography>
                                <FlexBox row aligncenter>
                                    <Typography>
                                        {props.order.amount} on{' '}
                                        {moment(props.order.date).format(
                                            'MMMM DD, YYYY '
                                        )}
                                    </Typography>
                                    {props.order.payment && (
                                        <Chip
                                            size="small"
                                            className={clsx(
                                                'ml-2',
                                                classes.chipPrimary,
                                                classes.chipSquare
                                            )}
                                            label={props.order.payment.method}
                                        />
                                    )}
                                </FlexBox>
                            </FlexBox>
                            <FlexBox className={classes.section}>
                                <Typography variant="h6" component="p">
                                    Payment Information
                                </Typography>

                                {props.order.payment.fields &&
                                    props.order.payment.fields.length > 0 && (
                                        <Typography>{`${props.order.payment.fields.type} ${props.order.payment.fields.number}`}</Typography>
                                    )}
                                <Typography>
                                    Expires{' '}
                                    {moment(
                                        props.order.payment.fields.exp_date
                                    ).format('MM/YYYY')}
                                </Typography>
                            </FlexBox>{' '}
                        </React.Fragment>
                    ) : (
                        <FlexBox
                            flex1
                            className="p-4"
                            justifycenter
                            aligncenter
                        >
                            <Typography color="textSecondary">
                                No Payment Information
                            </Typography>
                        </FlexBox>
                    )}
                </FlexBox>
            </Paper>
        </AccountLayout>
    );
};
