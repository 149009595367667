const initialState = {
    redirectUrl: null,
    redirectState: null,
    canRedirect: false,
};

export const redirectionReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'QUEUE_REDIRECT': {
            return {
                ...state,
                redirectUrl: action.url,
                redirectState: action.state,
                canRedirect: action.canRedirect,
            };
        }
        case 'CLEAR_REDIRECT': {
            return {
                ...state,
                redirectUrl: null,
                redirectState: null,
                canRedirect: false,
            };
        }
        case 'SET_TOKEN_REQUESTED':
            return {
                ...state,
                canRedirect: false,
            };
        case 'SET_TOKEN_SUCCESS':
            return {
                ...state,
                canRedirect: true,
            };
        case 'SET_TOKEN_FAILED':
            return {
                ...state,
                canRedirect: false,
            };
    }
};
