const initialState = {
    notifications: [],
};

export const toastReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'SHOW_TOAST':
            if (
                action.options.key &&
                state.notifications.find(
                    n => n.options.key === action.options.key
                )
            ) {
                return { ...state };
            }
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        message: action.message,
                        options: action.options,
                        variant: action.variant,
                    },
                ],
            };
        case 'CLEAR_TOAST':
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.options.key !== action.key
                ),
            };
        case 'CLOSE_TOAST': {
            return {
                ...state,
                notifications: state.notifications.map(notification =>
                    action.dismissAll || notification.options.key === action.key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                ),
            };
        }
    }
};
