const initialState = {
    reasons: {},
};

export const removeAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_REASONS_REQUESTED':
            return {
                ...state,
            };
        case 'GET_REASONS_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'GET_REASONS_FAILED':
            return {
                ...state,
            };
        case 'REMOVE_ACCOUNT_REQUESTED':
            return {
                ...state,
            };
        case 'REMOVE_ACCOUNT_SUCCESS':
            return {
                ...state,
            };
        case 'REMOVE_ACCOUNT_FAILED':
            return {
                ...state,
            };
    }
};
