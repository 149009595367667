import React, { useEffect, useState } from 'react';
import Container from '@/shared/components/container/container';
import { Menu } from './menu';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Verify } from '@/shared/verify';

export const Account = props => {
    const [verifyRequired, setVerifyRequired] = useState(false);

    useEffect(() => {
        props.getAccount().then(() => {
            if (props?.resolvable) {
                setVerifyRequired(props?.resolvable);
            }
        });
    }, []);

    return (
        <Container nopadding row style={{ paddingTop: 56 }} className="w-100 pb-5">
            {window.isDesktop && <Menu style={{ minWidth: 300 }} />}
            <FlexBox column className="w-100">
                {props.children}
            </FlexBox>
            {verifyRequired && (
                <Verify
                    context="resolve"
                    view={verifyRequired.toLowerCase()}
                    onVerifyModalClose={() => {
                        setVerifyRequired(false);
                    }}
                ></Verify>
            )}
        </Container>
    );
};
