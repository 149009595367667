const initialState = {
    status: null,
};

export const profileStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'UPDATE_PROFILE_STATUS_REQUESTED':
            return {
                ...state,
            };
        case 'UPDATE_PROFILE_STATUS_SUCCESS':
            return {
                ...state,
            };
        case 'UPDATE_PROFILE_STATUS_FAILED':
            return {
                ...state,
            };
    }
};
