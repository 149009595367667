import axiosAPI from '@/shared/api';
import { setVerification } from '@/shared/verify/verify.actions';

export const signup = request => async dispatch => {
    dispatch(signupRequested());

    return axiosAPI()
        .post('/signup/create', request)
        .then(response => {
            if (response.data?.sid) {
                dispatch(setVerification(response.data));
            } else {
                dispatch(signupSuccess(response.data));
            }
        })
        .catch(error => {
            return dispatch(signupFailed(error.response));
        });
};

function signupRequested() {
    return {
        type: 'SIGNUP_REQUESTED',
    };
}

function signupSuccess(payload) {
    return {
        type: 'SIGNUP_SUCCESS',
        payload,
    };
}

function signupFailed(error) {
    return {
        type: 'SIGNUP_FAILED',
        error,
    };
}
