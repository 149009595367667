const initialState = {
    orders: null,
    loading: true,
};

export const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_ORDER_HISTORY_REQUESTED':
            return {
                ...state,
                loading: true,
            };
        case 'GET_ORDER_HISTORY_SUCCESS':
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case 'GET_ORDER_HISTORY_FAILED':
            return {
                ...state,
                loading: false,
            };

        case 'GET_INDIVIDUAL_ORDER_HISTORY_REQUESTED':
            return {
                ...state,
            };
        case 'GET_INDIVIDUAL_ORDER_HISTORY_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'GET_INDIVIDUAL_ORDER_HISTORY_FAILED':
            return {
                ...state,
            };
    }
};
