const initialState = {
    devices: [],
    loadingDevices: true,
};

export const trustedDevicesReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_TRUSTED_DEVICES_REQUESTED':
            return {
                ...state,
                loadingDevices: true,
            };
        case 'GET_TRUSTED_DEVICES_SUCCESS':
            return {
                ...state,
                ...action.payload,
                loadingDevices: false,
            };
        case 'GET_TRUSTED_DEVICES_FAILED':
            return {
                ...state,
                loadingDevices: false,
            };
        case 'FORGET_DEVICE_REQUESTED':
            return {
                ...state,
            };
        case 'FORGET_DEVICE_SUCCESS':
            return {
                ...state,
                devices: [
                    ...state.devices.filter(device => {
                        return device.id != action.payload.deviceId;
                    }),
                ],
            };
        case 'FORGET_DEVICE_FAILED':
            return {
                ...state,
            };
    }
};
