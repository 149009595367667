import { connect } from 'react-redux';
import { AccountLayout as AccountLayoutComponent } from './account-layout.component';

const mapStateToProps = state => {
    return {
        has_active_profile: state.authReducer?.token?.user?.auths?.has_active_profile,
    };
};

export const AccountLayout = connect(mapStateToProps, {})(AccountLayoutComponent);
