import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classes from './placeholder.module.scss';
import FlexBox from '../flex-box/flex-box';
import Typography from '@mui/material/Typography';
import { Spring } from 'react-spring';

export default class Placeholder extends Component {
    state = {
        checked: true,
    };
    render() {
        const {
            image,
            title,
            message,
            height,
            small,
            button,
            caption,
            smallIcon,
        } = this.props;
        let classNames = classes.container;
        if (small) {
            classNames = classNames + ' ' + classes.small;
        } else if (smallIcon) {
            classNames = classNames + ' ' + classes.small;
        }
        return (
            <FlexBox
                justifycenter
                aligncenter
                className={classes.outerContainer}
            >
                <Spring
                    from={{ opacity: 0, transform: 'scale(0.3)' }}
                    to={{ opacity: 1, transform: 'scale(1)' }}
                    config={{ tension: 260, friction: 25, precision: 0.1 }}
                >
                    {({ opacity, transform }) => (
                        <FlexBox
                            justifycenter
                            aligncenter
                            className={classNames}
                            style={{
                                height: height ? height : '70vh',
                            }}
                        >
                            <FlexBox
                                style={{ opacity, transform }}
                                justifycenter
                                aligncenter
                                className={classes.imageContainer}
                            >
                                <img
                                    className={classes.image}
                                    src={image}
                                    alt=""
                                />
                            </FlexBox>

                            <Typography
                                aria-live="polite"
                                style={{ opacity, width: '100%' }}
                                align={'center'}
                                variant={small ? 'subtitle2' : 'h3'}
                                component="p"
                            >
                                {title}
                            </Typography>
                            <Typography
                                style={{ opacity, width: '100%' }}
                                align={'center'}
                                variant={small ? 'caption' : 'subtitle1'}
                                gutterBottom
                            >
                                {message}
                            </Typography>
                            {button}
                            {caption && (
                                <Typography
                                    className="mt-4"
                                    color="textSecondary"
                                    style={{ opacity, width: '100%' }}
                                    align={'center'}
                                    variant={'caption'}
                                    gutterBottom
                                >
                                    {caption}
                                </Typography>
                            )}
                        </FlexBox>
                    )}
                </Spring>
            </FlexBox>
        );
    }
}
Placeholder.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
};
