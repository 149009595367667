import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import classes from './verify.styles.module.scss';
import { Button, Typography, Container, Link, TextField } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import ReactCodeInput from 'react-verification-input';
import { PhoneInput } from '@/shared/components/phone-input';
import { useInput, useDidUpdateEffect } from '@/shared/util/hooks';
import Modal from '@/shared/components/modal/modal';
import helpers from '@/shared/util/helpers';

const VerifyEmail = ({ isSignup, ...props }) => {
    const getEmail = () => {
        if (props?.email) {
            return props.email;
        }
        if (props.token?.user?.email) {
            return props.token.user.email;
        }
    };

    const { value: email, bind: bindEmail } = useInput(getEmail());
    const buttonRef = useRef(null);

    const handleEnterPress = event => {
        const code = event.keyCode || event.which;
        if (code === 13) {
            event.preventDefault();
            sendEmail();
        }
    };

    const sendEmail = e => {
        if (e.detail == 1) {
            props.sendEmail({ email }, isSignup);
        }
    };

    return (
        <Fragment>
            <FlexBox column aligncenter w100>
                <Typography align="center" variant="h2" component="h1" className="mb-3">
                    {isSignup ? 'Sign up and find your match!' : 'Verification Required'}
                </Typography>
                <Typography align="center" variant="body1" className="mb-3">
                    To continue using our site you must confirm your account with an email address.
                </Typography>
                <TextField
                    {...bindEmail}
                    className={'w-100'}
                    variant="outlined"
                    name="email"
                    label="Email Address"
                    inputProps={{
                        autoCapitalize: 'none',
                        autoComplete: 'on',
                        autoCorrect: 'off',
                    }}
                    onKeyPress={handleEnterPress}
                />
            </FlexBox>
            <Button
                className="mt-3 w-100"
                variant="contained"
                color="primary"
                size="large"
                disableRipple
                ref={buttonRef}
                onClick={sendEmail}
            >
                Continue
            </Button>
        </Fragment>
    );
};

const VerifyPhone = ({ isSignup, ...props }) => {
    const getCountry = () => {
        if (props?.phone_country) {
            return props.phone_country;
        }
        if (props.token?.user?.country) {
            return props.token.user.country;
        }
        if (props.token?.guest?.country) {
            return props.token.guest.country;
        }
    };

    const { value: phone_code, setValue: setPhoneCode } = useInput('');
    const { value: phone_number, bind: bindPhoneNumber } = useInput(props?.phone_number ?? '');
    const { value: phone_country, setValue: setPhoneCountry } = useInput(getCountry());
    const buttonRef = useRef(null);

    const handleEnterPress = event => {
        const code = event.keyCode || event.which;
        if (code === 13) {
            event.preventDefault();
            sendPhone();
        }
    };

    const sendPhone = e => {
        if (e.detail == 1) {
            const fullNumber = phone_number ? `+${phone_code}${phone_number}` : null;
            props.sendPhone({ phone_number: fullNumber }, isSignup);
        }
    };

    const handleCountrySelect = (country, code) => {
        setPhoneCountry(country);
        setPhoneCode(code);
    };

    return (
        <Fragment>
            <FlexBox column aligncenter w100>
                <Typography align="center" variant="h2" component="h1" className="mb-3">
                    {isSignup ? 'Sign up and find your match!' : 'Verification Required'}
                </Typography>
                <Typography align="center" variant="body1" className="mb-3">
                    To continue using our site you must confirm your account with a phone number.
                </Typography>
                <PhoneInput
                    {...bindPhoneNumber}
                    key="phone_number"
                    className="mt-3 w-100"
                    label="Phone"
                    onKeyPress={handleEnterPress}
                    initialCountry={phone_country}
                    onCountrySelect={handleCountrySelect}
                />
            </FlexBox>
            <Button
                className="mt-3 w-100"
                variant="contained"
                color="primary"
                size="large"
                disableRipple
                ref={buttonRef}
                onClick={sendPhone}
            >
                Continue
            </Button>
        </Fragment>
    );
};

const VerifySuccess = ({ channel, ...props }) => {
    return (
        <Fragment>
            <FlexBox column aligncenter w100>
                <Typography align="center" variant="h2" component="h1" className="mb-4">
                    Verification Complete!
                </Typography>
                <Typography align="center" variant="body1" className="mb-4">
                    Your {channel == 'email' ? 'email address' : 'phone number'} has been verified.
                </Typography>
            </FlexBox>
            <FlexBox row justifyaround aligncenter>
                <Button
                    className="mt-3"
                    variant="contained"
                    color="primary"
                    size="large"
                    disableRipple
                    onClick={props.onVerifyModalClose}
                >
                    OK
                </Button>
            </FlexBox>
        </Fragment>
    );
};

export const VerifyCode = ({ channel, onSubmit, onResend }) => {
    const isEmail = channel == 'email';
    const startSeconds = isEmail ? 120 : 30;
    const [seconds, setSeconds] = useState(startSeconds);

    useEffect(() => {
        if (seconds <= 0) return;
        const intervalId = setInterval(() => {
            setSeconds(prev => prev - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [seconds]);

    return (
        <Fragment>
            <Typography variant="h2" component="h1">
                Verification Code
            </Typography>
            <Typography className="mt-2 mb-4" variant="body1">
                {`Please enter the 6-digit code we just ${isEmail ? 'emailed' : 'sent'} to you`}
            </Typography>
            <ReactCodeInput
                className={classes.codeInput}
                type="number"
                length={6}
                onComplete={onSubmit}
            ></ReactCodeInput>
            {(seconds > 0 && (
                <Typography className="mt-4 mb-4" variant="body2">
                    Code should arrive within {seconds} seconds
                </Typography>
            )) || (
                    <Typography className="mt-4">
                        <Link
                            to="#"
                            onClick={() => {
                                onResend(channel);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Didn't get the code?
                        </Link>
                    </Typography>
                )}
        </Fragment>
    );
};

export const Verify = ({ context = 'signup', ...props }) => {
    const startView = props?.view;
    const [view, setView] = useState(startView);
    const isSignup = context == 'signup';
    const isAddTFA = context == 'addtfa';
    //const isResolve = context == 'resolve';

    useDidUpdateEffect(() => {
        if (props?.status) {
            switch (props.status) {
                case 'approved':
                    if (isSignup) {
                        props.signup(props.sid);
                    } else {
                        setView('verify_success');
                    }
                    break;
                case 'pending':
                    setView('verify_code');
                    break;
                default:
                    props.onVerifyModalClose();
            }
        }
    }, [props.status]);

    const verifyView = () => {
        switch (view) {
            case 'verify_phone':
                return <VerifyPhone isSignup={isSignup} {...props} />;
            case 'verify_email':
                return <VerifyEmail isSignup={isSignup} {...props} />;
            case 'verify_code':
                return (
                    <VerifyCode
                        channel={props.channel}
                        onSubmit={verify_code => {
                            const sid = props.sid;
                            props.sendCode({ sid, verify_code }, isSignup);
                        }}
                        onResend={channel => {
                            if (isAddTFA) {
                                props.onVerifyModalClose();
                            } else {
                                const resendView =
                                    channel == 'SMS' ? 'verify_phone' : 'verify_email';
                                setView(resendView);
                            }
                        }}
                    />
                );
            case 'verify_success':
                return <VerifySuccess {...props}></VerifySuccess>;
            default:
                return null;
        }
    };

    if (isSignup) {
        return (
            <Fragment>
                <Helmet>
                    <title>{helpers.setTitle('Verification')}</title>
                </Helmet>
                <Container maxWidth="sm" className="py-5" style={{ textAlign: 'center' }}>
                    {verifyView()}
                </Container>
            </Fragment>
        );
    } else {
        return (
            <Modal size="small" onClose={props.onVerifyModalClose}>
                <Container maxWidth="sm" className="py-5" style={{ textAlign: 'center' }}>
                    {verifyView()}
                </Container>
            </Modal>
        );
    }
};
