const initialState = {
    success: false,
};

export const recoveryReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'ACCOUNT_RECOVERY_REQUESTED':
            return {
                ...state,
            };
        case 'ACCOUNT_RECOVERY_SUCCESS':
            return {
                ...state,
                success: true,
            };
        case 'ACCOUNT_RECOVERY_FAILED':
            return {
                ...state,
                success: false,
            };
    }
};
