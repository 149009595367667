import axios from 'axios';
import { get, has, isEqual } from 'lodash';
import md5 from 'md5';
import helpers from '@/shared/util/helpers';
import ExceptionHandler from '@/shared/util/exception-handler';
import { throwException } from '@/shared/exception/exception.actions';
import { showToast } from '@/shared/toast/toast.actions';
import { resetStore, getHandshake } from '@/shared/auth/auth.actions';
import { queueRedirect } from '@/shared/redirection/redirection.actions';

const axiosAPI = () => {
    const store = window.store;
    const dispatch = store.dispatch;
    const state = store.getState;

    let axiosCM = axios.create({
        baseURL: '/api',
    });

    axiosCM.interceptors.request.use(config => {
        config.headers['X-Web-Version'] = helpers.getWebVersion();
        config.headers['X-CSRF-Token'] = helpers.getCsrfToken();
        config.headers['X-Window-Location'] = window.location.href;
        config.headers['Cache-Control'] = 'no-store';
        return config;
    });

    axiosCM.interceptors.response.use(
        response => {
            if (has(response.data, 'redirect')) {
                dispatch(queueRedirect(response.data.redirect));
            }

            if (
                has(response.data, 'token.sc') &&
                !get(response.data, 'data.prevent_token_update', false)
            ) {
                updateSentryUser(response);
                updateToken(response.data);
            }

            if (has(response.data, 'message')) {
                const catchMessages = get(response, 'config.catchMessages', false);
                if (!catchMessages) {
                    showMessage(response.data.message);
                }
            }

            if (has(response.data, 'token.trackers')) {
                trackEvents(response.data);
            }

            return response.data;
        },
        err => {
            if (has(err.response.data, 'token.sc')) {
                updateToken(err.response.data);
            }

            const { exception } = state().exceptionReducer;
            if (!exception || (exception && exception.isCaught())) {
                const catchExceptions = get(err.response, 'config.catchExceptions', []);
                // Original request is on config.
                // https://github.com/axios/axios/issues/934
                //console.log('err.response', err.response);
                err.response.exception = new ExceptionHandler(
                    err.response.data,
                    catchExceptions,
                    err.config
                );
                dispatch(throwException(err.response.exception));
            }

            throw err;
        }
    );

    function updateToken(response) {
        const newToken = get(response, 'token');
        const { token } = state().authReducer;

        if (isEqual(token, newToken)) return;

        const prevUserId = get(token, 'user.id');
        const newUserId = get(newToken, 'user.id');

        if ((!prevUserId && newUserId) || (!newUserId && prevUserId)) {
            dispatch(getHandshake());
        }

        if (newUserId && prevUserId && !isEqual(prevUserId, newUserId)) {
            dispatch(resetStore());
        }

        window.CM.Globals.user = get(token, 'user');

        dispatch({
            type: 'SET_TOKEN_REQUESTED',
        });

        if (newToken) {
            dispatch({
                type: 'SET_TOKEN_SUCCESS',
                payload: newToken,
            });
        } else {
            dispatch({
                type: 'SET_TOKEN_FAILED',
                error: 'No Token?!',
            });
        }
    }

    function updateSentryUser(response) {
        const { token } = state().authReducer;
        const rUser = get(response, 'data.token.user');
        const tUser = get(token, 'user');

        if (rUser && !isEqual(rUser, tUser)) {
            window.Sentry.configureScope(function(scope) {
                scope.setUser(rUser);
            });
        }
    }

    function showMessage(message) {
        if (!message) return;

        if (message.display == 'alert') {
            const { text, title, redirect } = message;
            const exception = new ExceptionHandler({
                exception: 'faux',
                errors: { message: [text] },
                redirect,
                title,
            });
            dispatch(throwException(exception));
        } else {
            dispatch(
                showToast(message.text, {
                    variant: message.type,
                    key: md5(`${message.type}-${message.text}`),
                })
            );
        }
    }

    function trackEvents(response) {
        let events = Array.isArray(response.token.trackers) ? response.token.trackers : [];

        if (Array.isArray(window.dataLayer)) {
            events.forEach(event => {
                window.dataLayer.push(event);
            });
        }
    }

    axiosCM.retryAction = action => {
        return {
            type: 'SET_RETRY_ACTION',
            retryAction: action,
        };
    };

    return axiosCM;
};

export default axiosAPI;
