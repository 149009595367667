import { connect } from 'react-redux';
import { Verify as VerifyComponent } from './verify.component';
import { sendEmail, sendPhone, sendCode, setVerification } from './verify.actions';
import { signup } from '@/shared/signup/signup.actions';

const mapStateToProps = state => {
    return {
        ...state.verifyReducer,
        token: state.authReducer.token,
    };
};

export const Verify = connect(mapStateToProps, {
    sendEmail, sendPhone, sendCode, setVerification, signup
})(VerifyComponent);
