import React, { useState, useEffect } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Button, Typography, Grid, Paper } from '@mui/material';
import classes from './trusted-devices.styles.module.scss';
import moment from 'moment';
import { Grid as MyGrid } from '../grid';

export const TrustedDevices = props => {
    useEffect(() => {
        document.title = 'Trusted Devices';
        props.getTrustedDevices();
    }, []);

    const forgetDevice = async id => {
        await props.forgetDevice(id);
    };

    if (!props.devices.length) {
        return (
            <div>
                <FlexBox
                    aligncenter
                    justifycenter
                    className={classes.noDevices}
                >
                    <Typography variant="h6" color="textSecondary">
                        You do not have any trusted devices
                    </Typography>
                </FlexBox>
            </div>
        );
    }

    return (
        <FlexBox>
            <MyGrid
                loading={props.loadingDevices}
                items={props.devices}
                column1Title="Last Access"
                column2Title="Browser"
                column3Title="Platform"
                column1={device => {
                    return (
                        <Typography>
                            {moment(device.last_used.date).format(
                                'MMM DD, YYYY'
                            )}{' '}
                            <Typography variant="caption" color="textSecondary">
                                {moment(device.last_used.date).format('h:mma')}
                            </Typography>
                        </Typography>
                    );
                }}
                column2={device => {
                    return <Typography>{device.browser}</Typography>;
                }}
                column3={device => {
                    return <Typography>{device.platform}</Typography>;
                }}
                column4={device => {
                    return (
                        <Button
                            color="primary"
                            onClick={() => forgetDevice(device.id)}
                        >
                            Forget
                        </Button>
                    );
                }}
            ></MyGrid>
        </FlexBox>
    );
};
