import moment from 'moment';

const initialState = {
    loading: true,
    notifications: [],
    error: null,
};

export const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_ACCOUNT_NOTIFICATIONS_REQUESTED':
            return {
                ...state,
            };
        case 'GET_ACCOUNT_NOTIFICATIONS_SUCCESS':
            return {
                ...state,
                notifications: action.payload,
            };
        case 'GET_ACCOUNT_NOTIFICATIONS_FAILED':
            return {
                ...state,
            };
        case 'SAVE_ACCOUNT_NOTIFICATION_REQUESTED':
            return {
                ...state,
            };
        case 'SAVE_ACCOUNT_NOTIFICATION_SUCCESS':
            return {
                ...state,
                notifications: action.payload,
            };
        case 'SAVE_ACCOUNT_NOTIFICATION_FAILED':
            return {
                ...state,
                error: moment(),
            };
    }
};
