import React from 'react';
import { Settings } from '../settings/settings.component';

export const Notifications = props => {
    return (
        <Settings
            getSettings={props.getNotifications}
            saveSetting={props.saveNotification}
            settings={props.notifications}
            title="Notifications"
        />
    );
};
