const initialState = {
    email: '',
    status: '',
};

export const badEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_EMAIL_STATUS_REQUESTED':
            return {
                ...state,
            };
        case 'GET_EMAIL_STATUS_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'GET_EMAIL_STATUS_FAILED':
            return {
                ...state,
            };
        case 'UPDATE_EMAIL_REQUESTED':
            return {
                ...state,
            };
        case 'UPDATE_EMAIL_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'UPDATE_EMAIL_FAILED':
            return {
                ...state,
            };
    }
};
