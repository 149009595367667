import axiosAPI from '@/shared/api';
import history from '@/shared/history';

export const getEmailStatus = () => async dispatch => {
    dispatch(getEmailStatusRequested());

    return axiosAPI()
        .get('account/bademail')
        .then(response => {
            dispatch(getEmailStatusSuccess(response.data));
        })
        .catch(error => {
            dispatch(getEmailStatusFailed(error.response));
        });
};

export const updateEmail = email => async dispatch => {
    dispatch(updateEmailRequested());

    return axiosAPI()
        .patch('account/bademail', email)
        .then(response => {
            dispatch(updateEmailSuccess(response.data));
            history.replace('/home');
        })
        .catch(error => {
            dispatch(axiosAPI().retryAction(() => updateEmail(email)));
            dispatch(updateEmailFailed(error.response));
        });
};

function getEmailStatusRequested() {
    return {
        type: 'GET_EMAIL_STATUS_REQUESTED',
    };
}

function getEmailStatusSuccess(payload) {
    return {
        type: 'GET_EMAIL_STATUS_SUCCESS',
        payload,
    };
}

function getEmailStatusFailed(error) {
    return {
        type: 'GET_EMAIL_STATUS_FAILED',
        error,
    };
}

function updateEmailRequested() {
    return {
        type: 'UPDATE_EMAIL_REQUESTED',
    };
}

function updateEmailSuccess(payload) {
    return {
        type: 'UPDATE_EMAIL_SUCCESS',
        payload,
    };
}

function updateEmailFailed(error) {
    return {
        type: 'UPDATE_EMAIL_FAILED',
        error,
    };
}
