import { connect } from 'react-redux';
import { TrustedDevices as TrustedDevicesComponent } from './trusted-devices.component';
import { getTrustedDevices, forgetDevice } from './trusted-devices.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.trustedDevices,
    };
};

export const TrustedDevices = connect(mapStateToProps, {
    getTrustedDevices,
    forgetDevice,
})(TrustedDevicesComponent);
