import React from 'react';
import FlexBox from '../flex-box/flex-box';
import Container from '..//container/container';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { StyledAppBar, StyledToolbar } from '../../styles/appbar.styles';
import { Typography, IconButton } from '@mui/material';
import { isString } from 'lodash';
import classes from './menu.styles.module.scss';
import { useCtabarHeight, usePreviousLocation } from '@/shared/util/hooks';
import { TransparentBlueButton } from '../../styles/button.styles';
import clsx from 'clsx';

//maybe put this in container layout
const MoveUpScroll = props => {
    const height = useCtabarHeight(0);

    const { children, scrollThreshold, showElevation, bottomBorder, fixedOnScroll } = props;
    // const ctabar = document.getElementsByClassName('cta-bar')[0];
    //const height = 50; //ctabar ? ctabar.clientHeight : 0;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: scrollThreshold
            ? scrollThreshold
            : window.isDesktop
                ? height + props.extraHeight
                : 5,
    });

    let top = !window.isDesktop ? -1 : fixedOnScroll && trigger ? 64 : 0;

    let position = fixedOnScroll && trigger ? 'fixed' : props.position;
    return React.cloneElement(children(trigger), {
        position: position,
        elevation: showElevation ? 2 : trigger ? 2 : 0,
        style: {
            top: top,
            zIndex: trigger ? 1000 : props.zIndex || 1,
            borderBottom: trigger ? 'none' : bottomBorder ? '1px solid rgba(0,0,0,.1)' : 'none',
            ...props.appBarStyles,
        },
    });
};

MoveUpScroll.defaultProps = {
    extraHeight: 0,
    position: 'absolute',
};

const Menu = props => {
    const { navigateToPrevious } = usePreviousLocation();
    const {
        title,
        rightOptions,
        showBack,
        scrollThreshold,
        renderContent,
        centerTitle,
        subTitle,
        subMenu,
        maxWidth,
        style,
        className,
        padding,
        subColor,
        showTitleOnScroll,
        fixedOnScroll = true,
    } = props;

    const rightOptionsPosition =
        props.rightOptionsPosition != null
            ? props.rightOptionsPosition
            : window.isDesktop
                ? 'start'
                : 'end';

    const onBack = () => {
        if (props.onBack) {
            props.onBack();
        } else {
            navigateToPrevious();
        }
    };
    const rightButtons =
        rightOptions &&
        rightOptions.map((option, i) => {
            const last = i == rightOptions.length - 1;
            return (
                <div key={i} className={!last ? 'mr-2 mr-sm-3' : undefined}>
                    {option}
                </div>
            );
        });

    return (
        <MoveUpScroll
            {...props}
            showElevation={props.showElevation}
            scrollThreshold={scrollThreshold}
            fixedOnScroll={fixedOnScroll}
        >
            {trigger => {
                const showTitle = showTitleOnScroll ? trigger : true;
                return (
                    <StyledAppBar position="static" color="inherit" className={className}>
                        <StyledToolbar disableGutters>
                            <Container
                                alignstart
                                nopadding={!padding}
                                maxWidth={maxWidth}
                                justifystart
                                className="w-100 position-relative"
                            >
                                <FlexBox
                                    aligncenter
                                    style={{ minHeight: 56 }}
                                    row
                                    flex1
                                    justifystart
                                    className="w-100"
                                >
                                    {renderContent ? (
                                        props.renderContent(trigger)
                                    ) : (
                                        <React.Fragment>
                                            {showBack &&
                                                (window.isDesktop ? (
                                                    <TransparentBlueButton
                                                        className="mr-2 mr-sm-3"
                                                        onClick={onBack}
                                                        size="small"
                                                    >
                                                        {props.backText || 'Back'}
                                                    </TransparentBlueButton>
                                                ) : (
                                                    <IconButton
                                                        className="mr-2 mr-sm-3"
                                                        onClick={onBack}
                                                        style={{ width: 42 }}
                                                    >
                                                        <i
                                                            className={'far fa-xs fa-chevron-left'}
                                                        ></i>
                                                    </IconButton>
                                                ))}

                                            {title &&
                                                showTitle &&
                                                (centerTitle ? (
                                                    <FlexBox
                                                        flex1
                                                        justifycenter
                                                        aligncenter
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                            left: 0,
                                                            bottom: 0,
                                                        }}
                                                    >
                                                        {isString(title) ? (
                                                            <Typography
                                                                variant={
                                                                    window.isDesktop
                                                                        ? 'h6'
                                                                        : 'body1'
                                                                }
                                                            >
                                                                {title}
                                                            </Typography>
                                                        ) : (
                                                            title
                                                        )}
                                                    </FlexBox>
                                                ) : isString(title) ? (
                                                    <Typography
                                                        variant={window.isDesktop ? 'h6' : 'body1'}
                                                        className={clsx(
                                                            !subTitle ? 'mr-2 mr-sm-3' : '',
                                                            !showBack &&
                                                                !padding &&
                                                                !window.isDesktop
                                                                ? 'ml-3'
                                                                : ''
                                                        )}
                                                    >
                                                        {title}
                                                    </Typography>
                                                ) : (
                                                    title
                                                ))}
                                            {subTitle && showTitle && (
                                                <React.Fragment>
                                                    <div className={classes.separator}></div>
                                                    <Typography
                                                        variant="subtitle2"
                                                        className="mr-2 mr-sm-3"
                                                        color={subColor}
                                                    >
                                                        {subTitle}
                                                    </Typography>
                                                </React.Fragment>
                                            )}
                                            {rightOptions && (
                                                <FlexBox
                                                    row
                                                    aligncenter
                                                    justifyend
                                                    className={
                                                        rightOptionsPosition == 'end'
                                                            ? padding
                                                                ? 'ml-auto'
                                                                : 'ml-auto mr-3'
                                                            : undefined
                                                    }
                                                >
                                                    {rightButtons}
                                                </FlexBox>
                                            )}
                                        </React.Fragment>
                                    )}
                                </FlexBox>
                                <FlexBox justifystart className="w-100">
                                    {subMenu}
                                </FlexBox>
                            </Container>
                        </StyledToolbar>
                    </StyledAppBar>
                );
            }}
        </MoveUpScroll>
    );
};

Menu.defaultProps = {
    rightOptions: null,
    rightOptionsPosition: null,
    renderContent: null,
};

export default Menu;
