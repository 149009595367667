import axiosAPI from '@/shared/api';

export const getTrustedDevices = () => async dispatch => {
    dispatch(getTrustedDevicesRequested());

    return axiosAPI()
        .get('account/trusted')
        .then(response => {
            dispatch(getTrustedDevicesSuccess(response.data));
        })
        .catch(error => {
            dispatch(getTrustedDevicesFailed(error.response));
        });
};

export const forgetDevice = id => async dispatch => {
    dispatch(forgetDeviceRequested());

    return axiosAPI()
        .delete(`account/trusted/${id}`)
        .then(response => {
            response.data = { deviceId: id };
            dispatch(forgetDeviceSuccess(response.data));
        })
        .catch(error => {
            dispatch(forgetDeviceFailed(error.response));
        });
};

function getTrustedDevicesRequested() {
    return {
        type: 'GET_TRUSTED_DEVICES_REQUESTED',
    };
}

function getTrustedDevicesSuccess(payload) {
    return {
        type: 'GET_TRUSTED_DEVICES_SUCCESS',
        payload,
    };
}

function getTrustedDevicesFailed(error) {
    return {
        type: 'GET_TRUSTED_DEVICES_FAILED',
        error,
    };
}

function forgetDeviceRequested() {
    return {
        type: 'FORGET_DEVICE_REQUESTED',
    };
}

function forgetDeviceSuccess(payload) {
    return {
        type: 'FORGET_DEVICE_SUCCESS',
        payload,
    };
}

function forgetDeviceFailed(error) {
    return {
        type: 'FORGET_DEVICE_FAILED',
        error,
    };
}
