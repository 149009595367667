/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import GeocodeEarthAutocomplete from './geocode-earth-autocomplete';
import { OutlinedInput, Typography, CircularProgress, InputAdornment, Autocomplete } from '@mui/material';
import { geocode_earth_key } from 'config';
import classes from './geocode-earth-input.styles.module.scss';
import { FlexBox } from '../flex-box';

export default props => {
    const [text, setText] = useState(props.value);

    useEffect(() => {
        setText(props.value);
    }, [props.value]);

    return (
        <GeocodeEarthAutocomplete
            searchOptions={{
                api_key: geocode_earth_key,
                layers: 'locality,postalcode',
                'focus.point.lat': props.currentLatitude,
                'focus.point.lon': props.currentLongitude,
                sources: '-geonames', // This gets rid of the "City of ..." results
            }}
            value={text}
            onChange={newAddress => {
                setText(newAddress);
            }}
            onSelect={(address, placeId, suggestion) => {
                setText(address);
                props.onSelect(suggestion);
            }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return (
                    <FlexBox aligncenter style={{ position: 'relative' }}>
                        <OutlinedInput
                            id="component-outlined"
                            labelWidth={0}
                            className={classes.locationInput}
                            inputProps={{
                                autoCapitalize: 'none',
                                autoComplete: 'off',
                                autoCorrect: 'off',
                                ...getInputProps({
                                    placeholder: 'Enter a city...',
                                    className: 'location-search-input',
                                }),
                            }}
                        // endAdornment={
                        //     loading && (
                        //         <InputAdornment position="end">
                        //             <CircularProgress
                        //                 color="inherit"
                        //                 size={20}
                        //             ></CircularProgress>
                        //         </InputAdornment>
                        //     )
                        // }
                        />

                        <div className="autocomplete-dropdown-container">
                            {suggestions?.length > 0 && (
                                <div
                                    style={{
                                        padding: 10,
                                        backgroundColor: 'white',
                                        color: 'black',
                                        position: 'absolute',
                                        top: 50,
                                        borderRadius: 8,
                                        left: 0,
                                        width: '100%',
                                        zIndex: 100,
                                        maxHeight: 150,
                                        overflowY: 'auto',
                                        boxShadow: '0 0 .5rem rgba(0,0,0,.15)',
                                    }}
                                >
                                    {suggestions.map(suggestion => {
                                        const className = 'p-2';
                                        const style = { cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <Typography>{suggestion.description}</Typography>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </FlexBox>
                );
            }}
        </GeocodeEarthAutocomplete>
    );
};
