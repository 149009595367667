import React, { useEffect } from 'react';
import { useDidUpdateEffect } from '../util/hooks';
import SafeHtml from '@/shared/components/safe-html/safe-html';
import { IconButton, Icon } from '@mui/material';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useHistory } from 'react-router-dom';

export const Toast = props => {
    const history = useHistory();
    //options

    //variant: success, error, warning, info
    //href: 'https://...'
    //targetBlank: boolean to open in new tab
    //messageLink: link to navigate within react

    let displayed = [];

    const storeDisplayed = id => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = id => {
        displayed = displayed.filter(key => id !== key);
    };

    useEffect(() => {
        renderToast();
    }, [props.notifications]);

    useDidUpdateEffect(() => {
        renderToast();
    }, [props.notifications]);

    const renderToast = () => {
        const { notifications = [] } = props;

        notifications.forEach(({ message, options, dismissed = false, variant = 'default' }) => {
            options = {
                variant: variant,
                style: { left: 'auto' },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                // eslint-disable-next-line react/display-name
                action: key => (
                    <IconButton
                        size="small"
                        onClick={() => {
                            props.closeToast(key);
                        }}
                    >
                        <Icon fontSize="small" style={{ color: 'white' }} className="fa fa-sm fa-times"></Icon>
                    </IconButton>
                ),
                ...options,
            };

            message = <SafeHtml>{message}</SafeHtml>;

            //href will trump
            if (options.messageLink || options.href) {
                const { messageLink, key, href, targetBlank } = options;
                delete options.messageLink;
                delete options.href;
                delete options.targetBlank;
                message = (
                    <Typography>
                        <Link
                            target={targetBlank && '_blank'}
                            style={{ color: 'white' }}
                            to={href || '#'}
                            onClick={e => {
                                if (!href) {
                                    e.preventDefault();
                                    history.push(messageLink);
                                }
                                props.closeToast(key);
                            }}
                        >
                            {message}
                        </Link>
                    </Typography>
                );
            }

            if (dismissed) {
                closeSnackbar(options.key);
                return;
            }
            // Do nothing if snackbar is already displayed
            if (displayed.includes(options.key)) return;
            // Display snackbar using notistack
            enqueueSnackbar(message, {
                ...options,
                onClose: (event, reason, key) => {
                    if (options.onClose) {
                        options.onClose(event, reason, key);
                    }
                },
                onExited: (event, key) => {
                    props.clearToast(key);
                    removeDisplayed(key);
                },
                preventDuplicate: true,
            });
            // Keep track of snackbars that we've displayed
            storeDisplayed(options.key);
        });
    };

    return null;
};
