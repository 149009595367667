import { connect } from 'react-redux';
import { Account as AccountComponent } from './account.component';
import { getAccount } from './account.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.account,
        suspension: state.authReducer?.token?.user?.auths?.suspension,
        resolvable: state.authReducer?.token?.user?.auths?.resolvable,
    };
};

export const Account = connect(mapStateToProps, {
    getAccount,
})(AccountComponent);
