import React, { Component } from 'react';
import Alert from '@/shared/components/alert/alert';
import SafeHtml from '@/shared/components/safe-html/safe-html';
import { Reauth } from '@/shared/account';
import { isString } from 'lodash';

export class Exception extends Component {
    // Do not show any alert message - just redirect.
    redirectOnly = () => {
        const exceptions = [
            //'forbidden.account_suspended',
            'forbidden.account_incomplete',
            'forbidden.profile_incomplete',
            'forbidden.account_deleted',
            'authentication.signin_required',
            'authentication.signin_failed_tfa_required',
            'paymentRequired',
        ];

        return exceptions.find(e => {
            return this.props.exception.is(e);
        });
    };

    canHandle = () => {
        return this.props.exception && !this.props.exception.isCaught();
    };

    redirect = (url, state) => {
        const redirectUrl = isString(url) ? url : this.props.exception.redirect;

        if (redirectUrl) {
            this.props.queueRedirect(redirectUrl, state, true);
        }
        this.props.clearException();

        return null;
    };

    onReauthSuccess = () => {
        if (this.props.retryAction) {
            this.props.dispatch(this.props.retryAction());
        }
        this.props.clearException();
    };

    // Redirect-only & any other exceptions that should be ignored need to be in here so as to prevent unmount errors.
    componentDidUpdate = () => {
        if (this.canHandle()) {
            const pathname = window.location.pathname;
            const isAbout = /^\/about/.test(pathname);
            const guest =
                /^\/signin/.test(pathname) ||
                /^\/signup/.test(pathname) ||
                /^\/pwdreset/.test(pathname) ||
                /^\/gsearch/.test(pathname);
            if (this.redirectOnly()) {
                this.redirect();
            } else if (
                this.props.exception.is('forbidden.user_unauthenticated') &&
                (window.isPlus || isAbout || guest)
            ) {
                this.props.clearException();
            }
        }
    };

    showException = () => {
        const exception = this.props.exception;

        if (!this.canHandle()) return null;

        if (exception.is('unavailable.web_update_required')) {
            return (
                <Alert
                    title="Refresh Required"
                    content={exception.getError()}
                    onConfirm={() => {
                        window.localStorage.clear();
                        window.location.reload(true);
                    }}
                />
            );
        }

        if (
            exception.is('authentication.reauth_required') ||
            exception.is('authentication.reauth_invalid')
        ) {
            return (
                <Reauth
                    // clearException={props.clearException}
                    error={exception.getError()}
                    onSuccess={this.onReauthSuccess}
                />
            );
        }

        if (exception.is('validation')) {
            return (
                <Alert
                    title={exception.title}
                    content={<SafeHtml component="span">{exception.getErrorsAsHtml()}</SafeHtml>}
                    onConfirm={this.redirect}
                />
            );
        }

        if (exception.is('forbidden.account_recovery_throttle')) {
            return (
                <Alert
                    title={exception.title}
                    content={exception.getError()}
                    confirmText="Contact Support"
                    onConfirm={() => {
                        window.location.href =
                            'https://catholicmatch.zendesk.com/hc/en-us/requests/new?subject=Recover%20Account';
                    }}
                />
            );
        }

        if (exception.is('forbidden.recovery_token_expired')) {
            return (
                <Alert
                    title={exception.title}
                    content={exception.getError()}
                    onConfirm={this.redirect}
                />
            );
        }

        if (exception.is('instagram')) {
            let errors = '<p>' + exception.getErrorsAsHtml() + '</p>';
            errors +=
                '<p>You can close this window and attempt to connect to Instagram again. If you comtinue to have a problem please <a href="/help" target="_blank">contact support</a></p>';

            return (
                <Alert
                    title={exception.title}
                    content={<SafeHtml component="span">{errors}</SafeHtml>}
                    onConfirm={this.props.clearException}
                />
            );
        }

        return (
            <Alert
                title={exception.title}
                content={exception.getError()}
                confirmText={exception.confirmText}
                onConfirm={this.redirect}
            />
        );
    };

    render() {
        return this.showException();
    }
}
