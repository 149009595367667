import React, { useEffect } from 'react';
import classes from '../sidebar.module.scss';
import SafeHtml from '../../safe-html/safe-html';
import { Link, Typography } from '@mui/material';
import FlexBox from '../../flex-box/flex-box';
import moment from 'moment';

export const Forums = props => {
    useEffect(() => {
        if (props.posts && props.posts.length > 0) return;
        props.getPosts();
    }, []);

    if (!props.posts || props.posts.length == 0) return null;

    const posts = [];

    const first = props.posts[0];
    posts.push(
        <Link
            key={first.id}
            className={classes.post}
            to={'https://www.catholicmatch.com/forums/topics/posts.html?topic_id=' + first.id}
            target="_blank"
        >
            <FlexBox column>
                <FlexBox row className="pb-1">
                    <img style={{ objectFit: 'cover' }} alt="" src={first.image_src_s075} />

                    <FlexBox justifyend column className="ml-3">
                        <Typography color="textPrimary" className={classes.title} component="div">
                            <SafeHtml>{first.subject}</SafeHtml>
                        </Typography>
                        <Typography color="textSecondary" className={classes.text}>
                            Last reply at {moment(first.last_post_on).format('h:mma')}
                        </Typography>
                    </FlexBox>
                </FlexBox>
                <Typography color="textPrimary" className={classes.date} component="div">
                    <SafeHtml>{first.body}</SafeHtml>
                </Typography>
            </FlexBox>
        </Link>
    );

    // eslint for-loop bug
    // eslint-disable-next-line no-unused-vars
    for (let post of props.posts.slice(1)) {
        posts.push(
            <Link
                key={post.id}
                className={classes.post}
                to={'https://www.catholicmatch.com/forums/topics/posts.html?topic_id=' + post.id}
                target="_blank"
                alt=""
            >
                <FlexBox column>
                    <Typography color="textSecondary" className={classes.title}>
                        {moment(post.last_post_on).format('h:mma')}
                    </Typography>
                    <Typography className={classes.date} component="div">
                        <SafeHtml>{post.subject}</SafeHtml>
                    </Typography>
                </FlexBox>
            </Link>
        );
    }

    return (
        <FlexBox>
            <FlexBox className="px-3 mb-2 mt-4" row flex1 justifybetween>
                <Typography tabIndex="0" variant="h3" component="h2">
                    Forums
                </Typography>

                <Link variant={'subtitle2'} to="/forums">
                    View Forums
                </Link>
            </FlexBox>
            {posts}
        </FlexBox>
    );
};
