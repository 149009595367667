const initialState = {
    diocese_list: {},
};

export const basicInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_DIOCESES_REQUESTED':
            return {
                ...state,
            };
        case 'GET_DIOCESES_SUCCESS':
            return {
                ...state,
                diocese_list: action.dioceses,
            };
        case 'GET_DIOCESES_FAILED':
            return {
                ...state,
            };
        case 'GET_BASIC_INFO_SUCCESS':
            return {
                ...state,
                diocese_list: Object.values(action.diocese_list),
            };
        case 'UPDATE_BASIC_INFORMATION_REQUESTED':
            return {
                ...state,
            };
        case 'UPDATE_BASIC_INFORMATION_SUCCESS':
            return {
                ...state,
                diocese_list: action.payload.diocese_list,
            };
        case 'UPDATE_BASIC_INFORMATION_FAILED':
            return {
                ...state,
            };
    }
};
