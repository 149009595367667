import React, { useState, useEffect } from 'react';
import LayoutContainer from '@/shared/components/layout-container/layout-container';
import { FlexBox, IconText } from '@/shared/components';
import { FormControlLabel, Paper, Button, Link, Typography, Divider } from '@mui/material';
import classes from './current-subscription.styles.module.scss';
import { AccountLayout } from '../account-layout';
import { SubscriptionDetails } from './subscription-details';
import { app_url } from 'config';
import history from '@/shared/history';
import clsx from 'clsx';
import moment from 'moment';

export const CurrentSubscription = ({
    otherServices,
    nextService,
    getSubscriptions,
    currentService,
    upgradeSubscription,
    addItemToCart,
    cancelSubscription,
    user,
}) => {
    useEffect(() => {
        document.title = 'Subscription';
        getSubscriptions();
    }, []);

    // const cancel = () => upgradeSubscription(user?.service?.id);
    const purchaseSubscription = async id => {
        await addItemToCart(id).then(() => {
            if (window.location.host?.includes('plus.catholicmatch')) {
                window.location = `${app_url}/subscribe/checkout`;
            } else {
                history.push('/subscribe/checkout');
            }
        });
    };

    const upgradeToCurrent = () => {
        upgradeSubscription(currentService.id);
    };

    const currentIsExpired = currentService && moment(currentService?.end_date) < moment();
    const hasPremium = currentService?.sku != 'BASIC' && !currentIsExpired;

    if (!otherServices) return null;

    return (
        <AccountLayout
            menuProps={
                !window.isDesktop && {
                    showBack: true,
                    title: 'Subscriptions',
                }
            }
        >
            {currentService && (
                <SubscriptionDetails
                    isExpired={currentIsExpired}
                    nextSubscription={nextService}
                    product={{ ...currentService, current: true }}
                ></SubscriptionDetails>
            )}
            {((hasPremium && nextService.sku == 'BASIC') || currentIsExpired) && (
                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={
                        currentIsExpired
                            ? () => purchaseSubscription(nextService?.id)
                            : upgradeToCurrent
                    }
                    className={clsx(classes.renewBtn, 'mt-1')}
                >
                    Renew Premium
                </Button>
            )}
            <Typography className="mt-5 mb-3" variant="h6">
                Available Plans
            </Typography>
            {otherServices?.map((product, i) => {
                return (
                    <SubscriptionDetails
                        upgradeSubscription={upgradeSubscription}
                        // cancelSubscription={cancel}
                        renewPremium={upgradeToCurrent}
                        hasPremium={hasPremium}
                        purchaseSubscription={purchaseSubscription}
                        key={i}
                        product={product}
                    ></SubscriptionDetails>
                );
            })}
        </AccountLayout>
    );
};
