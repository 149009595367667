import axiosAPI from '@/shared/api';
import helpers from '@/shared/util/helpers';
import { queueRedirect } from '@/shared/redirection/redirection.actions';

export const resetStore = () => dispatch => {
    dispatch(reset());
};

export const expireStore = refresh => dispatch => {
    dispatch(expire(refresh));
};

function reset() {
    return {
        type: 'RESET_STORE',
    };
}

export const queueForExpiring = reducers => dispatch => {
    dispatch(queueForExpiringAction(reducers));
};

function queueForExpiringAction(reducers) {
    return {
        type: 'QUEUE_FOR_EXPIRE',
        reducers,
    };
}

function expire(refresh) {
    return {
        type: 'EXPIRE_STORE',
        refresh,
    };
}

export const getHandshake = () => async dispatch => {
    dispatch(getHandshakeRequested());

    return axiosAPI()
        .get('/util/handshake')
        .then(function(response) {
            const user = response.token.user;
            dispatch(getHandshakeSuccess({ user, criteria: user.criteria, wcag: user.wcag }));
        })
        .catch(error => {
            dispatch(getHandshakeFailed(error.response));
        });
};

function getHandshakeRequested() {
    return {
        type: 'GET_HANDSHAKE_REQUESTED',
    };
}

function getHandshakeSuccess(payload) {
    return {
        type: 'GET_HANDSHAKE_SUCCESS',
        payload,
    };
}

function getHandshakeFailed(error) {
    return {
        type: 'GET_HANDSHAKE_FAILED',
        error,
    };
}

export const getUser = () => async dispatch => {
    dispatch(getUserRequested());

    return axiosAPI()
        .get('/auth/user')
        .then(function(response) {
            dispatch(getUserSuccess(response.data));
        })
        .catch(error => {
            dispatch(getUserFailed(error.response));
        });
};

function getUserRequested() {
    return {
        type: 'GET_USER_REQUESTED',
    };
}

function getUserSuccess(payload) {
    return {
        type: 'GET_USER_SUCCESS',
        payload,
    };
}

function getUserFailed(error) {
    return {
        type: 'GET_USER_FAILED',
        error,
    };
}

export const updateWebVersion = webVersion => dispatch => {
    dispatch(updateWebVersionAction(webVersion));
};

function updateWebVersionAction(webVersion) {
    return {
        type: 'UPDATE_WEB_VERSION',
        webVersion,
    };
}

export const signout = () => async dispatch => {
    dispatch(signoutRequested());

    return axiosAPI()
        .get('/auth/signout')
        .then(function(response) {
            helpers.resetIframe();
            dispatch(signoutSuccess(response.data));
            dispatch(queueRedirect('/signin', {}, true));
        })
        .catch(error => {
            dispatch(signoutFailed(error.response));
        });
};

function signoutRequested() {
    return {
        type: 'SIGNOUT_REQUESTED',
    };
}

function signoutSuccess(payload) {
    return {
        type: 'SIGNOUT_SUCCESS',
        payload,
    };
}

function signoutFailed(error) {
    return {
        type: 'SIGNOUT_FAILED',
        error,
    };
}
