import { useRef } from 'react';
import { useSpring, useChain } from 'react-spring';

export function useAnimations(selected) {
    const config = { mass: 1, tension: 120, friction: 16, clamp: true };

    let cardDimensions = null;
    if (window.isDesktop) {
        cardDimensions = { width: 340, height: 200 };
    } else {
        const width = Math.min(400, window.innerWidth - 30);
        cardDimensions = { width: width, height: width * 0.6 };
    }

    let paypalDimensions = null;
    if (window.isDesktop) {
        paypalDimensions = { width: 400, height: 62 };
    } else {
        const width = Math.min(400, window.innerWidth - 30);
        paypalDimensions = { width: width, height: 62 };
    }

    const cardRef = useRef();
    const card = useSpring({
        ref: cardRef,
        config,
        from: { height: cardDimensions.height, width: cardDimensions.width },
        to: {
            height: selected ? cardDimensions.height : 62,
        },
    });

    const paypalRef = useRef();
    const paypal = useSpring({
        ref: paypalRef,
        config,
        from: {
            height: paypalDimensions.height,
            width: paypalDimensions.width,
        },
    });

    const cardNumberRef = useRef();
    const cardNumber = useSpring({
        ref: cardNumberRef,
        config,
        delay: selected ? 0 : 100,
        from: {
            transform: 'translateX(50px)',
            opacity: 0,
        },
        to: {
            transform: selected ? 'translateX(50px)' : 'translateX(0px)',
            opacity: selected ? 0 : 1,
        },
    });

    const typeRef = useRef();
    const { typeHeight, ...typeContainer } = useSpring({
        ref: typeRef,
        config,
        from: {
            top: 0,
            right: 0,
            height: cardDimensions.height,
            width: cardDimensions.width,
            typeHeight: 50,
        },
        to: {
            top: selected ? 0 : 13,
            right: selected ? 0 : 20,
            height: selected ? cardDimensions.height : 36,
            width: selected ? cardDimensions.width : 55,
            typeHeight: selected ? 50 : 35,
        },
    });

    const paypalTypeRef = useRef();
    const { paypalFontSize, ...paypalType } = useSpring({
        ref: paypalTypeRef,
        config,
        from: {
            top: 0,
            right: 0,
            height: paypalDimensions.height,
            width: paypalDimensions.width,
            paypalFontSize: 28,
        },
        to: {
            top: selected ? 0 : 13,
            right: selected ? 0 : 20,
            height: selected ? paypalDimensions.height : 36,
            width: selected ? paypalDimensions.width : 55,
            paypalFontSize: selected ? 28 : 16,
        },
    });

    const cardContentRef = useRef();
    const cardContent = useSpring({
        ref: cardContentRef,
        config,
        delay: selected ? 200 : 0,
        from: {
            transform: 'translateX(-30px)',
            opacity: 0,
        },
        to: {
            transform: selected ? 'translateX(0px)' : 'translateX(-30px)',
            opacity: selected ? 1 : 0,
        },
    });

    // This will orchestrate the animations above, comment the last arg and it creates a sequence
    useChain(
        selected
            ? [
                  cardRef,
                  paypalRef,
                  cardNumberRef,
                  typeRef,
                  paypalTypeRef,
                  cardContentRef,
              ]
            : [
                  cardContentRef,
                  cardRef,
                  paypalRef,
                  typeRef,
                  paypalTypeRef,
                  cardNumberRef,
              ],
        selected ? [0, 0, 0, 0, 0, 0.1] : [0, 0, 0, 0, 0, 0.2]
    );

    return {
        cardContent,
        typeImage: typeHeight,
        paypalImage: paypalFontSize,
        paypalType,
        cardNumber,
        typeContainer,
        card,
        paypal,
    };
}
