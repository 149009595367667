const initialState = {};

export const reauthReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'REAUTHENTICATION_REQUESTED':
            return {
                ...state,
                success: false,
            };
        case 'REAUTHENTICATION_SUCCESS':
            return {
                ...state,
                success: true,
            };
        case 'REAUTHENTICATION_FAILED':
            return {
                ...state,
                success: false,
                reauthError: action.error,
            };
    }
};
