import moment from 'moment';

const initialState = {};

export const interruptReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'POST_INTERRUPT_REQUESTED':
            return {
                ...state,
                action: null,
            };
        case 'POST_INTERRUPT_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'POST_INTERRUPT_FAILED':
            return {
                ...state,
                error: action.error,
            };
        case 'SET_TOKEN_SUCCESS': {
            const promo = action.payload.promo;
            const interrupt = promo && promo.interrupt;
            return {
                ...state,
                interrupt,
                lastLoadAt: moment(),
            };
        }
        case 'CLEAR_INTERRUPT':
            return {
                ...state,
                interrupt: null,
            };

        case 'SET_CURRENT_INTERRUPT':
            return {
                ...state,
            };
        case 'ACKNOWLEDGE_INTERRUPT_REQUESTED':
            return {
                ...state,
            };
        case 'ACKNOWLEDGE_INTERRUPT_SUCCESS':
            return {
                ...state,
            };
        case 'ACKNOWLEDGE_INTERRUPT_FAILED':
            return {
                ...state,
                error: action.error,
            };
    }
};
