import axiosAPI from '@/shared/api';

export const updateProfileStatus = route => async dispatch => {
    dispatch(updateProfileStatusRequested());

    return axiosAPI()
        .patch(route)
        .then(response => {
            dispatch(updateProfileStatusSuccess(response.data));
        })
        .catch(error => {
            dispatch(updateProfileStatusFailed(error.response));
        });
};

function updateProfileStatusRequested() {
    return {
        type: 'UPDATE_PROFILE_STATUS_REQUESTED',
    };
}

function updateProfileStatusSuccess(payload) {
    return {
        type: 'UPDATE_PROFILE_STATUS_SUCCESS',
        payload,
    };
}

function updateProfileStatusFailed(error) {
    return {
        type: 'UPDATE_PROFILE_STATUS_FAILED',
        error,
    };
}
