import { connect } from 'react-redux';
import { BadEmail as BadEmailComponent } from './bad-email.component';
import { getEmailStatus, updateEmail } from './bad-email.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.badEmail,
    };
};

export const BadEmail = connect(mapStateToProps, {
    getEmailStatus,
    updateEmail,
})(BadEmailComponent);
