import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button, TextField, Paper, CircularProgress, Skeleton } from '@mui/material';
import classes from './payment.styles.module.scss';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Vault } from '../../payment/vault';
import clsx from 'clsx';
import { useInput } from '@/shared/util/hooks';
import Confirm from '@/shared/components/confirm/confirm';
import Modal from '@/shared/components/modal/modal';
import Loadable from 'react-loadable';
import { AccountLayout } from '../account-layout';

const LoadableBraintree = Loadable({
    //don't get rid of this magic comment. Sets path in public folder
    loader: () => import('../../payment/braintree-fields' /* webpackChunkName: "js/payment" */),
    loading() {
        return (
            <Skeleton
                aria-live="polite"
                aria-label="Loading Credit Card Fields"
                variant="rect"
                style={{ borderRadius: 3 }}
                width={'100%'}
                height={160}
                className="mb-1"
            />
        );
    },
});

// after Loadable is created have to wrap it again as forward Ref: https://github.com/zeit/next.js/issues/4957
// eslint-disable-next-line react/display-name
const ForwardedRefBraintree = React.forwardRef((props, ref) => (
    <LoadableBraintree {...props} forwardedRef={ref} />
));

export const Payment = props => {
    const [selected, setSelected] = useState('saved');
    const [showGf, setShowGf] = useState(false);
    const [gfBalance, setGfBalance] = useState(null);
    const { value: giftCard, reset: resetGiftCard, bind: bindGiftCard } = useInput('');
    const braintreeRef = useRef(null);

    useEffect(() => {
        setSelected('saved');
    }, [props.saved_payment]);

    useEffect(() => {
        document.title = 'Payment';
        if (!props.countries || props.countries.length == 0) {
            props.getCountries();
        }
        props.getPaymentInfo();
    }, []);

    if (props.loading) {
        return (
            <AccountLayout
                menuProps={{
                    showBack: !window.isDesktop,
                    title: 'Payment Information',
                }}
            >
                <FlexBox justifycenter aligncenter flex1 className="mt-5">
                    <CircularProgress></CircularProgress>
                </FlexBox>
            </AccountLayout>
        );
    }

    const { user, balance, saved_payment, authorization, countries } = props;

    const checkout = params => {
        props.savePayment(params);
        () => setSelected('saved');
    };

    const savePayment = () => {
        braintreeRef.current.checkout();
    };

    const deleteSavedPayment = () => {
        props.deletePayment();
    };

    const hasPaypal = user.country != 'US' || user.auths.is_admin;
    const hidePaypal = selected !== 'paypal';
    const hideCreditCard = selected !== 'card';

    const checkGiftCard = async () => {
        let balance = await props.checkGiftCard(giftCard);
        setGfBalance(balance);
    };

    const redeemGiftCard = () => {
        props.redeemGiftCard(giftCard);
        setGfBalance(null);
        setShowGf(false);
        resetGiftCard();
    };

    const content = (
        <FlexBox justifystart flex1 className="w-100">
            <FlexBox column style={selected != 'card' && { display: 'none' }}>
                <FlexBox className={classes.paymentHeader}>
                    <Typography variant="h3" component="h2">
                        Add Card
                    </Typography>
                </FlexBox>
                <div className="py-3">
                    {authorization && (
                        <ForwardedRefBraintree
                            ref={braintreeRef}
                            hidePaypal={hidePaypal}
                            hideCreditCard={hideCreditCard}
                            countries={countries}
                            authorization={authorization}
                            onCheckout={checkout}
                        ></ForwardedRefBraintree>
                    )}
                </div>
            </FlexBox>

            {hasPaypal && (
                <FlexBox
                    flex1
                    column
                    className={clsx(classes.paymentOption, 'w-100')}
                    style={selected != 'paypal' && { display: 'none' }}
                >
                    <FlexBox className={classes.paymentHeader}>
                        <Typography variant="h3" component="h2">
                            Add Paypal Account
                        </Typography>
                    </FlexBox>
                    <FlexBox
                        justifycenter
                        aligncenter
                        className="p-3"
                        style={{ position: 'relative' }}
                    >
                        <div id="paypalButton" className={classes.paypalButton}>
                            <Skeleton
                                style={{ position: 'absolute' }}
                                variant="rect"
                                width={window.isDesktop ? 350 : 250}
                                height={window.isDesktop ? 45 : 35}
                            ></Skeleton>
                        </div>
                    </FlexBox>
                </FlexBox>
            )}
        </FlexBox>
    );

    return (
        <AccountLayout
            menuProps={{
                showBack: !window.isDesktop,
                title: (
                    <FlexBox>
                        <Typography variant={window.isDesktop ? 'h6' : 'body1'}>
                            Payment Information
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant={window.isDesktop ? 'body1' : 'caption'}
                        >
                            Your current credit balance is ${balance}
                        </Typography>
                    </FlexBox>
                ),
            }}
        >
            <Paper variant="outlined" className={clsx(classes.paper, 'mt-3')} elevation={0}>
                <FlexBox alignstart flex1 className={clsx(window.isDesktop && 'p-4')}>
                    {selected == 'saved' && saved_payment && (
                        <Vault
                            onDelete={deleteSavedPayment}
                            hideSelection
                            selected={true}
                            headerSize="h6"
                            paymentDetails={saved_payment}
                        ></Vault>
                    )}
                    {selected != 'saved' && content}
                    {selected == 'saved' && (
                        <FlexBox
                            column
                            alignstart
                            flex1
                            className={clsx('w-100', saved_payment && 'mt-4')}
                        >
                            {(saved_payment || window.isDesktop) && (
                                <Typography variant="h6" component="h2">
                                    {saved_payment
                                        ? 'Use another Payment Method'
                                        : 'Add a Payment Method'}
                                </Typography>
                            )}
                            <FlexBox row={window.isDesktop} flex1 className="mt-2 w-100">
                                <Button
                                    variant="contained"
                                    size="large"
                                    className={clsx(classes.paymentButton, 'mr-1')}
                                    onClick={() => setSelected('card')}
                                >
                                    <FlexBox row={!window.isDesktop}>
                                        <Typography
                                            color="textSecondary"
                                            align="center"
                                            variant="h3"
                                            component="div"
                                            className={clsx(!window.isDesktop && 'mr-3')}
                                            aria-label="Credit Card"
                                        >
                                            <i className="far fa-credit-card"></i>
                                        </Typography>
                                        <Typography color="textSecondary" align="center">
                                            {saved_payment ? 'Replace with card' : 'Add Card'}
                                        </Typography>
                                    </FlexBox>
                                </Button>
                                {hasPaypal && (
                                    <Button
                                        variant="contained"
                                        size="large"
                                        className={clsx(
                                            classes.paymentButton,
                                            window.isDesktop && 'ml-1'
                                        )}
                                        onClick={() => setSelected('paypal')}
                                    >
                                        <FlexBox row={!window.isDesktop}>
                                            <Typography
                                                color="textSecondary"
                                                align="center"
                                                variant="h3"
                                                component="div"
                                                className={clsx(!window.isDesktop && 'mr-3')}
                                                aria-label="paypal"
                                            >
                                                <i className="fab fa-paypal"></i>
                                            </Typography>
                                            <Typography color="textSecondary" align="center">
                                                {saved_payment
                                                    ? 'Replace with Paypal'
                                                    : 'Add Paypal'}
                                            </Typography>
                                        </FlexBox>
                                    </Button>
                                )}
                            </FlexBox>
                        </FlexBox>
                    )}
                    {selected != 'saved' && (
                        <FlexBox className="w-100" row justifyend>
                            <Button
                                variant="contained"
                                className="mr-3"
                                onClick={() => setSelected('saved')}
                            >
                                Cancel
                            </Button>
                            {selected == 'card' && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{ minWidth: 150 }}
                                    onClick={savePayment}
                                >
                                    Save
                                </Button>
                            )}
                        </FlexBox>
                    )}
                </FlexBox>
            </Paper>
            <Paper className="mt-5" variant="outlined" elevation={0}>
                <FlexBox column className="p-4">
                    <Typography
                        variant="h6"
                        component="label"
                        htmlFor="giftcard-input"
                        className="mb-3"
                    >
                        Gift Card
                    </Typography>
                    <FlexBox
                        row={window.isDesktop}
                        column={!window.isDesktop}
                        aligncenter
                        justifystart
                    >
                        <TextField
                            style={{ minWidth: 300 }}
                            id="giftcard-input"
                            placeholder="Enter you Gift Card Number"
                            error={props.gfValidation ? true : false}
                            helperText={props.gfValidation}
                            className={classes.gfInput}
                            {...bindGiftCard}
                            variant="outlined"
                        ></TextField>
                        <Button
                            color="primary"
                            size="small"
                            className={clsx(window.isDesktop ? 'ml-2' : 'mr-auto mt-2')}
                            disabled={giftCard.length != 19}
                            onClick={checkGiftCard}
                        >
                            Check Gift Card Balance
                        </Button>
                    </FlexBox>
                </FlexBox>
            </Paper>

            {gfBalance && (
                <Confirm
                    title="Redeem Gift Card"
                    content={`Giftcard has a balance of $${gfBalance}. Do you want to apply this value to your account? The funds will be available for all future purchases!`}
                    onCancel={() => setGfBalance(null)}
                    onConfirm={redeemGiftCard}
                    confirmText="Redeem"
                ></Confirm>
            )}
            {props.savingCard && (
                <Modal size="auto" className="p-5" hideClose>
                    <FlexBox justifycenter aligncenter>
                        <CircularProgress></CircularProgress>
                        <Typography className="mt-3">Saving...</Typography>
                    </FlexBox>
                </Modal>
            )}
        </AccountLayout>
    );
};
