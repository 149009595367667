import axiosAPI from '@/shared/api';

export const updatePassword = request => async dispatch => {
    dispatch(updatePasswordRequested());

    return axiosAPI()
        .patch('account/password', request)
        .then(response => {
            dispatch(updatePasswordSuccess(response.data));
        })
        .catch(error => {
            dispatch(updatePasswordFailed(error.response));
        });
};

function updatePasswordRequested() {
    return {
        type: 'UPDATE_PASSWORD_REQUESTED',
    };
}

function updatePasswordSuccess(payload) {
    return {
        type: 'UPDATE_PASSWORD_SUCCESS',
        payload,
    };
}

function updatePasswordFailed(error) {
    return {
        type: 'UPDATE_PASSWORD_FAILED',
        error,
    };
}
