import React, { useState, useEffect } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { TextField, Typography, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useInput } from '@/shared/util/hooks';

export const Password = props => {
    const {
        value: password,
        bind: bindPassword,
        reset: resetPassword,
    } = useInput('');
    const {
        value: newPassword,
        bind: bindNewPassword,
        reset: resetNewPassword,
    } = useInput('');
    const {
        value: confirmPassword,
        bind: bindConfirmPassword,
        reset: resetConfirmPassword,
    } = useInput('');

    const [visibility, setVisibility] = useState(false);

    const updatePassword = () => {
        props.updatePassword({
            password: password,
            new_password: newPassword,
            new_password_confirm: confirmPassword,
        });
        resetPassword();
        resetNewPassword();
        resetConfirmPassword();
    };

    useEffect(() => {
        // set password visibility off next time component loads
        document.title = 'Password';
        return () => {
            setVisibility(false);
        };
    }, []);

    const content = (
        <FlexBox>
            <Typography
                variant="subtitle2"
                component="label"
                htmlFor="password-input"
            >
                Current Password
            </Typography>
            <TextField
                {...bindPassword}
                id="password-input"
                type={visibility ? 'text' : 'password'}
                variant="outlined"
                autoComplete="off"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setVisibility(!visibility);
                                }}
                                tabIndex="-1"
                                onMouseDown={() => { }}
                                edge="end"
                            >
                                {visibility ? (
                                    <i className="fas fa-xs fa-eye"></i>
                                ) : (
                                    <i className="fas fa-xs fa-eye-slash"></i>
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Typography
                className="mt-4"
                variant="subtitle2"
                component="label"
                htmlFor="new-password-input"
            >
                New Password
            </Typography>
            <TextField
                {...bindNewPassword}
                type={visibility ? 'text' : 'password'}
                id="new-password-input"
                placeholder="New Password"
                variant="outlined"
                autoComplete="off"
            />
            <Typography
                className="mt-4"
                variant="subtitle2"
                component="label"
                htmlFor="confirm-password-input"
            >
                Confirm New Password
            </Typography>
            <TextField
                {...bindConfirmPassword}
                error={newPassword != confirmPassword}
                helperText={
                    newPassword != confirmPassword
                        ? 'New password must match.'
                        : ''
                }
                type={visibility ? 'text' : 'password'}
                id="confirm-password-input"
                placeholder="Re-enter New Password"
                variant="outlined"
                autoComplete="off"
            />
            <FlexBox alignend>
                <Button
                    className="mt-5"
                    variant="contained"
                    color="primary"
                    onClick={updatePassword}
                >
                    Update
                </Button>
            </FlexBox>
        </FlexBox>
    );

    if (window.isDesktop) {
        return (
            <Paper className="p-4" variant="outlined" elevation={0}>
                {content}
            </Paper>
        );
    }
    return <div>{content}</div>;
};
