import axiosAPI from '@/shared/api';

export const postAction = (id, type, action) => dispatch => {
    dispatch(postActionRequested());

    return axiosAPI()
        .post(`/home/${id}/save/${action}`)
        .then(function(response) {
            dispatch(postActionSuccess(response.data));
        })
        .catch(error => {
            dispatch(postActionFailed(error.response));
        });
};

function postActionRequested() {
    return {
        type: 'POST_ACTION_REQUESTED',
    };
}

function postActionSuccess(payload) {
    return {
        type: 'POST_ACTION_SUCCESS',
        payload,
    };
}

function postActionFailed() {
    return {
        type: 'POST_ACTION_FAILED',
    };
}
