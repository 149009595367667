import axiosAPI from '@/shared/api';

export const getBasicInfo = () => async dispatch => {
    dispatch(getBasicInfoRequested());

    return axiosAPI()
        .get('account/contact')
        .then(response => {
            dispatch(getBasicInfoSuccess(response.data));
        })
        .catch(error => {
            dispatch(getBasicInfoFailed(error.response));
        });
};

export const getDioceses = (geostring = null) => async dispatch => {
    dispatch(getDiocesesRequested());

    return axiosAPI()
        .post('account/dioceses', geostring ? { _geostring: geostring } : null)
        .then(response => {
            dispatch(getDiocesesSuccess(response.data.dioceses));
        })
        .catch(error => {
            dispatch(getDiocesesFailed(error.response));
        });
};

export const updateBasicInformation = request => async dispatch => {
    dispatch(updateBasicInformationRequested());

    return axiosAPI()
        .patch('account/contact', request)
        .then(response => {
            dispatch(updateBasicInformationSuccess(response.data));
        })
        .catch(error => {
            dispatch(axiosAPI().retryAction(() => updateBasicInformation(request)));
            dispatch(updateBasicInformationFailed(error.response));
        });
};

export const removeAccount = request => async dispatch => {
    dispatch(removeAccountRequested());

    return axiosAPI()
        .post('account/remove', request)
        .then(response => {
            dispatch(removeAccountSuccess(response.data));
        })
        .catch(error => {
            dispatch(axiosAPI().retryAction(() => removeAccount(request)));
            dispatch(removeAccountFailed(error.response));
        });
};

function getDiocesesRequested() {
    return {
        type: 'GET_DIOCESES_REQUESTED',
    };
}

function getDiocesesSuccess(dioceses) {
    return {
        type: 'GET_DIOCESES_SUCCESS',
        dioceses,
    };
}

function getDiocesesFailed(error) {
    return {
        type: 'GET_DIOCESES_FAILED',
        error,
    };
}

function getBasicInfoRequested() {
    return {
        type: 'GET_BASIC_INFO_REQUESTED',
    };
}

function getBasicInfoSuccess(payload) {
    return {
        type: 'GET_BASIC_INFO_SUCCESS',
        ...payload,
    };
}

function getBasicInfoFailed(error) {
    return {
        type: 'GET_BASIC_INFO_FAILED',
        error,
    };
}

function updateBasicInformationRequested() {
    return {
        type: 'UPDATE_BASIC_INFORMATION_REQUESTED',
    };
}

function updateBasicInformationSuccess(payload) {
    return {
        type: 'UPDATE_BASIC_INFORMATION_SUCCESS',
        payload,
    };
}

function updateBasicInformationFailed(error) {
    return {
        type: 'UPDATE_BASIC_INFORMATION_FAILED',
        error,
    };
}

function removeAccountRequested() {
    return {
        type: 'REMOVE_ACCOUNT_REQUESTED',
    };
}

function removeAccountSuccess(payload) {
    return {
        type: 'REMOVE_ACCOUNT_SUCCESS',
        payload,
    };
}

function removeAccountFailed(error) {
    return {
        type: 'REMOVE_ACCOUNT_FAILED',
        error,
    };
}
