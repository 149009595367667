import axiosAPI from '@/shared/api';

export const getPosts = () => async dispatch => {
    dispatch(loadPosts());
    return axiosAPI()
        .get('/util/forumposts')
        .then(response => {
            dispatch(postsSuccess(response.data));
        })
        .catch(error => {
            dispatch(postsFailed(error.response));
        });
};

function loadPosts() {
    return {
        type: 'GET_FORUMS_POSTS',
    };
}

function postsSuccess(payload) {
    return {
        type: 'GET_FORUMS_POSTS_SUCCESS',
        payload,
    };
}

function postsFailed(error) {
    return {
        type: 'GET_FORUMS_POSTS_FAILED',
        error,
    };
}
