import { connect } from 'react-redux';
import { Settings as SettingsComponent } from './settings.component';
import { saveSetting, getSettings } from './settings.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.settings,
    };
};

export const Settings = connect(mapStateToProps, {
    saveSetting,
    getSettings,
})(SettingsComponent);
