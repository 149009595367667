import React from 'react';
import classes from './vault.styles.module.scss';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Typography, Radio, Fab } from '@mui/material';
import clsx from 'clsx';
import { animated } from 'react-spring';
import { useAnimations } from './vault.animations';

const AnimatedFlexBox = animated(FlexBox);
const AnimatedTypography = animated(Typography);

const types = [
    { name: 'visa', src: '/resources/visa.png' },
    { name: 'american express', src: '/resources/amex.png' },
    { name: 'discover', src: '/resources/discover.png' },
    { name: 'mastercard', src: '/resources/mastercard.png' },
];
export const Vault = props => {
    const animations = useAnimations(props.selected);
    if (props.paymentDetails.paypal) {
        const { paypal_email } = props.paymentDetails.paypal;
        return (
            <FlexBox column aligncenter={props.center} justifycenter>
                <Typography
                    variant={props.headerSize || 'body1'}
                    component="h2"
                    className={classes.header}
                >
                    Your Saved Paypal Account
                </Typography>

                <AnimatedFlexBox
                    onClick={props.onSelect}
                    justifystart
                    aligncenter
                    row
                    style={animations.paypal}
                    className={clsx(
                        classes.card,
                        classes.paypal,
                        props.selected && classes.selected
                    )}
                >
                    {/* {props.onDelete && (
                        <Fab
                            size="small"
                            color="secondary"
                            aria-label="trash"
                            className={classes.delete}
                            onClick={() => props.onDelete()}
                        >
                            <i className="fas fa-sm fa-trash"></i>
                        </Fab>
                    )} */}
                    <Radio
                        id="savedPaypalRadio"
                        className={classes.radio}
                        color="primary"
                        checked={props.selected}
                        name="saved-card-selected"
                    />
                    <AnimatedTypography
                        variant="body1"
                        color="textSecondary"
                        htmlFor="savedPaypalRadio"
                        component="label"
                        aria-label={`Use paypal ${paypal_email}`}
                        className={clsx(classes.paypalEmail)}
                    >
                        {paypal_email}
                    </AnimatedTypography>

                    <FlexBox
                        justifycenter
                        aligncenter
                        className={classes.typeContainer}
                    >
                        <animated.i
                            style={{
                                fontSize: animations.paypalImage,
                                color: 'white',
                            }}
                            className="fab fa-paypal"
                        ></animated.i>
                    </FlexBox>
                    <animated.div
                        style={animations.paypalType}
                        className={classes.animatedPaypalContainer}
                    ></animated.div>
                </AnimatedFlexBox>
            </FlexBox>
        );
    }

    if (props.paymentDetails.creditcard) {
        const {
            expiration_date,
            card_type,
            last_4,
        } = props.paymentDetails.creditcard;
        const { first_name, last_name } = props.paymentDetails;

        const cardType = types.find(t => t.name == card_type.toLowerCase());

        let name = [first_name, last_name].filter(it => !!it).join(' ') || null;

        const dots = [...Array(12)].map((item, i) => {
            return <div key={i} className={classes.cardDot}></div>;
        });
        return (
            <div>
                <FlexBox column alignstart justifycenter className="mb-3">
                    <Typography
                        variant={props.headerSize || 'body1'}
                        component="h2"
                        className={classes.header}
                    >
                        Pay with Saved Card
                    </Typography>

                    <AnimatedFlexBox
                        onClick={props.onSelect}
                        justifystart
                        style={animations.card}
                        className={clsx(
                            classes.card,
                            props.selected && classes.selected
                        )}
                    >
                        {/* {props.onDelete && (
                            <Fab
                                size="small"
                                color="secondary"
                                aria-label="trash"
                                className={classes.delete}
                                onClick={() => props.onDelete()}
                            >
                                <i className="fas fa-sm fa-trash"></i>
                            </Fab>
                        )} */}
                        <FlexBox
                            row
                            aligncenter
                            className="mb-auto"
                            style={{ minHeight: 42 }}
                        >
                            {!props.hideSelection && (
                                <Radio
                                    id="savedCardRadio"
                                    className={classes.radio}
                                    color="primary"
                                    checked={props.selected}
                                    name="saved-card-selected"
                                />
                            )}

                            {!props.selected && (
                                <AnimatedTypography
                                    style={animations.cardNumber}
                                    variant="h3"
                                    className={clsx(
                                        classes.cardNumber,
                                        classes.light
                                    )}
                                >
                                    <FlexBox row aligncenter>
                                        {dots} {last_4}
                                    </FlexBox>
                                </AnimatedTypography>
                            )}
                            <FlexBox
                                justifycenter
                                aligncenter
                                className={classes.typeContainer}
                            >
                                {cardType === undefined || !cardType ? (
                                    <AnimatedTypography
                                        variant="subtitle1"
                                        className={classes.cardTypeText}
                                    >
                                        {card_type}
                                    </AnimatedTypography>
                                ) : (
                                    <animated.img
                                        style={{ height: animations.typeImage }}
                                        src={`https://d2q86h5pvn46dj.cloudfront.net${cardType.src}`}
                                        alt={card_type}
                                    />
                                )}
                            </FlexBox>
                            <animated.div
                                style={animations.typeContainer}
                                className={classes.animatedTypeContainer}
                            ></animated.div>
                        </FlexBox>

                        <FlexBox className="mb-3 mt-3">
                            <AnimatedTypography
                                style={animations.cardContent}
                                variant="h3"
                                htmlFor="savedCardRadio"
                                component="label"
                                aria-label={`Use saved card ending in ${last_4}`}
                                className={classes.cardNumber}
                            >
                                <FlexBox row aligncenter>
                                    {dots} {last_4}
                                </FlexBox>
                            </AnimatedTypography>
                        </FlexBox>
                        <FlexBox row justifybetween>
                            {name && (
                                <FlexBox>
                                    <AnimatedTypography
                                        style={animations.cardContent}
                                        variant="caption"
                                        className={classes.lbl}
                                    >
                                        Name
                                    </AnimatedTypography>
                                    <AnimatedTypography
                                        style={animations.cardContent}
                                        variant="subtitle1"
                                        className={classes.value}
                                    >
                                        {name}
                                    </AnimatedTypography>
                                </FlexBox>
                            )}
                            <FlexBox>
                                <AnimatedTypography
                                    style={animations.cardContent}
                                    variant="caption"
                                    className={classes.lbl}
                                >
                                    Expiration Date
                                </AnimatedTypography>
                                <AnimatedTypography
                                    style={animations.cardContent}
                                    variant="subtitle1"
                                    className={classes.value}
                                >
                                    {expiration_date}
                                </AnimatedTypography>
                            </FlexBox>
                        </FlexBox>
                    </AnimatedFlexBox>
                </FlexBox>
            </div>
        );
    }
    return null;
};
