import React, { useEffect } from 'react';
import history from '@/shared/history';
import { Button, Typography, Container } from '@mui/material';

export const AccountRecovery = props => {
    useEffect(() => {
        if (props.success) {
            history.replace('/home');
        }
    }, [props.success]);

    const undelete = () => {
        props.undeleteAccount();
    };

    return (
        <Container maxWidth="sm" className="mt-5">
            <div>
                Your account was either deleted at your request, or because you had not signed in to
                your account for a long period of time. To recover your account, please click
                Recover My Account button below.
                <Typography className="mt-4">
                    <b>
                        After recovering your account, you will need to verify that your account
                        information is up to date.
                    </b>
                </Typography>
            </div>
            <div className="text-center mt-3">
                <Button onClick={undelete} variant="contained" color="primary">
                    Recover My Account
                </Button>
            </div>
        </Container>
    );
};
