const initialState = {
    authorization: null,
    saved: false,
    loading: true,
};

export const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_PAYMENT_INFO_REQUESTED':
            return {
                ...state,
                loading: true,
            };
        case 'GET_PAYMENT_INFO_SUCCESS':
            return {
                ...state,
                ...action.payload,
                loading: false,
                saved_payment: action.payload.saved_payment,
            };
        case 'GET_PAYMENT_INFO_FAILED':
            return {
                ...state,
                loading: false,
            };
        case 'DELETE_PAYMENT_REQUESTED':
            return {
                ...state,
            };
        case 'DELETE_PAYMENT_SUCCESS':
            return {
                ...state,
                saved_payment: null,
            };
        case 'DELETE_PAYMENT_FAILED':
            return {
                ...state,
            };

        case 'SAVE_PAYMENT_REQUESTED':
            return {
                ...state,
                savingCard: true,
            };
        case 'SAVE_PAYMENT_FAILED':
            return {
                ...state,
                savingCard: false,
                error: action.error,
            };
        case 'SAVE_PAYMENT_SUCCESS':
            return {
                ...state,
                savingCard: false,
            };
        case 'CHECK_GIFTCARD_REQUESTED':
            return {
                ...state,
                checkingGf: true,
            };
        case 'CHECK_GIFTCARD_SUCCESS':
            return {
                ...state,
                checkingGf: false,
            };
        case 'CHECK_GIFTCARD_FAILED':
            return {
                ...state,
                checkingGf: false,
                error: action.error,
            };
        case 'REDEEM_GIFTCARD_REQUESTED':
            return {
                ...state,
            };
        case 'REDEEM_GIFTCARD_SUCCESS':
            return {
                ...state,
            };
        case 'REDEEM_GIFTCARD_FAILED':
            return {
                ...state,
                gfError: action.error,
                gfValidation: action.validation,
            };
    }
};
