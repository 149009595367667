import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/system';
import { makeTheme } from './theme';
import { useSelector } from 'react-redux';
import './theme.styles.module.scss';

export const Theme = props => {
    const wcag = useSelector(state => state.authReducer.wcag);

    useEffect(() => {
        if (wcag) {
            const body = document.body;
            //set text size
            document.documentElement.style.fontSize = `${wcag.text_size}%`;
            Object.keys(wcag).map(key => {
                const fieldValue = wcag[key];
                const prefixedKey = `wcag_${key}`;
                if (!fieldValue && body.classList.contains(prefixedKey)) {
                    body.classList.remove(prefixedKey);
                } else if (fieldValue) {
                    body.classList.add(prefixedKey);
                }
            });
        }
    }, [wcag]);

    const theme = wcag == undefined ? 'default' : wcag.theme == undefined ? 'default' : wcag.theme;

    return <ThemeProvider theme={makeTheme(theme)}>{props.children}</ThemeProvider>;
};
