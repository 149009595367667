import axiosAPI from '@/shared/api';

export const getPaymentInfo = () => async dispatch => {
    dispatch(getPaymentInfoRequested());

    return axiosAPI()
        .get('account/payment')
        .then(response => {
            dispatch(getPaymentInfoSuccess(response.data));
        })
        .catch(error => {
            dispatch(getPaymentInfoFailed(error.response));
        });
};

function getPaymentInfoRequested() {
    return {
        type: 'GET_PAYMENT_INFO_REQUESTED',
    };
}

function getPaymentInfoSuccess(payload) {
    return {
        type: 'GET_PAYMENT_INFO_SUCCESS',
        payload,
    };
}

function getPaymentInfoFailed(error) {
    return {
        type: 'GET_PAYMENT_INFO_FAILED',
        error,
    };
}

export const deletePayment = () => async dispatch => {
    dispatch(deletePaymentRequested());

    return axiosAPI()
        .delete('account/payment')
        .then(response => {
            dispatch(deletePaymentSuccess(response.data));
        })
        .catch(error => {
            dispatch(deletePaymentFailed(error.response));
        });
};

function deletePaymentRequested() {
    return {
        type: 'DELETE_PAYMENT_REQUESTED',
    };
}

function deletePaymentSuccess(payload) {
    return {
        type: 'DELETE_PAYMENT_SUCCESS',
        payload,
    };
}

function deletePaymentFailed(error) {
    return {
        type: 'DELETE_PAYMENT_FAILED',
        error,
    };
}

export const savePayment = params => async dispatch => {
    dispatch(savePaymentRequested());

    return axiosAPI()
        .post('account/payment', params)
        .then(() => {
            dispatch(savePaymentSuccess());
            dispatch(getPaymentInfo());
        })
        .catch(error => {
            dispatch(savePaymentFailed(error.response));
        });
};

function savePaymentRequested() {
    return {
        type: 'SAVE_PAYMENT_REQUESTED',
    };
}

function savePaymentSuccess() {
    return {
        type: 'SAVE_PAYMENT_SUCCESS',
    };
}

function savePaymentFailed(error) {
    return {
        type: 'SAVE_PAYMENT_FAILED',
        error,
    };
}

export const checkGiftCard = giftCardNumber => async dispatch => {
    dispatch(checkGiftCardRequested());

    return axiosAPI()
        .post(
            'account/giftcard/check',
            { gift_card_number: giftCardNumber },
            {
                catchExceptions: ['validation'],
            }
        )
        .then(response => {
            dispatch(checkGiftCardSuccess(response.data.funds));
            return response.data.funds;
        })
        .catch(error => {
            const exception = error.response.exception;
            if (exception.is('validation')) {
                dispatch(
                    checkGiftCardFailed({
                        validation: exception.message(),
                        error: exception,
                    })
                );
                return;
            }
            dispatch(checkGiftCardFailed(error.response));
        });
};

function checkGiftCardRequested() {
    return {
        type: 'CHECK_GIFTCARD_REQUESTED',
    };
}

function checkGiftCardSuccess(funds) {
    return {
        type: 'CHECK_GIFTCARD_SUCCESS',
        funds,
    };
}

function checkGiftCardFailed(error) {
    return {
        type: 'CHECK_GIFTCARD_FAILED',
        error,
    };
}

export const redeemGiftCard = giftCardNumber => async dispatch => {
    dispatch(redeemGiftCardRequested());

    return axiosAPI()
        .post('account/giftcard/redeem', { gift_card_number: giftCardNumber })
        .then(() => {
            dispatch(redeemGiftCardSuccess());
            dispatch(getPaymentInfo());
        })
        .catch(error => {
            dispatch(redeemGiftCardFailed(error.response.exception));
        });
};

function redeemGiftCardRequested() {
    return {
        type: 'REDEEM_GIFTCARD_REQUESTED',
    };
}

function redeemGiftCardSuccess() {
    return {
        type: 'REDEEM_GIFTCARD_SUCCESS',
    };
}

function redeemGiftCardFailed(error) {
    return {
        type: 'REDEEM_GIFTCARD_FAILED',
        ...error,
    };
}
