const initialState = {
    exception: null,
};

export const exceptionReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'THROW_EXCEPTION':
            return {
                ...state,
                exception: action.exception,
            };
        case 'CLEAR_EXCEPTION':
            return {
                ...state,
                exception: null,
                retryAction: null,
            };
        case 'SET_RETRY_ACTION':
            return {
                ...state,
                retryAction: action.retryAction,
            };
    }
};
