const initialState = {
    is_enabled: null,
    tfa_channels: [],
    primary: null,
    showDisableConfirm: false,
    showDeleteConfirm: false,
};

export const tfaReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_TFA_REQUESTED':
            return {
                ...state,
            };
        case 'GET_TFA_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'GET_TFA_FAILED':
            return {
                ...state,
            };
        case 'ENABLE_TFA_REQUESTED':
            return {
                ...state,
            };
        case 'ENABLE_TFA_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'ENABLE_TFA_FAILED':
            return {
                ...state,
            };
        case 'DELETE_TFA_CHANNEL_REQUESTED':
            return {
                ...state,
            };
        case 'DELETE_TFA_CHANNEL_SUCCESS':
            return {
                ...state,
                ...action.payload,
                showDeleteConfirm: false,
            };
        case 'DELETE_TFA_CHANNEL_FAILED':
            return {
                ...state,
                showDeleteConfirm: false,
            };
        case 'ADD_SMS_CHANNEL_REQUESTED':
            return {
                ...state,
            };
        case 'ADD_SMS_CHANNEL_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'ADD_SMS_CHANNEL_FAILED':
            return {
                ...state,
            };
        case 'DISABLE_TFA_REQUESTED':
            return {
                ...state,
            };
        case 'DISABLE_TFA_SUCCESS':
            return {
                ...state,
                ...action.payload,
                showDisableConfirm: false,
            };
        case 'DISABLE_TFA_FAILED':
            return {
                ...state,
                showDisableConfirm: false,
            };
        case 'SHOW_TFA_CONFIRM_SUCCESS':
            return {
                ...state,
                showDisableConfirm: true,
            };
        case 'HIDE_TFA_CONFIRM_SUCCESS':
            return {
                ...state,
                showDisableConfirm: false,
            };
        case 'SHOW_DELETE_CONFIRM_SUCCESS':
            return {
                ...state,
                showDeleteConfirm: true,
            };
        case 'HIDE_DELETE_CONFIRM_SUCCESS':
            return {
                ...state,
                showDeleteConfirm: false,
            };
    }
};
