import moment from 'moment';

const initialState = {
    loading: true,
    settings: [],
    error: null,
};

export const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_ACCOUNT_SETTINGS_REQUESTED':
            return {
                ...state,
                loading: true,
            };
        case 'GET_ACCOUNT_SETTINGS_SUCCESS':
            return {
                ...state,
                settings: action.payload,
                loading: false,
            };
        case 'GET_ACCOUNT_SETTINGS_FAILED':
            return {
                ...state,
                loading: false,
            };
        case 'SAVE_ACCOUNT_SETTING_REQUESTED':
            return {
                ...state,
            };
        case 'SAVE_ACCOUNT_SETTING_SUCCESS':
            return {
                ...state,
                settings: action.payload,
            };
        case 'SAVE_ACCOUNT_SETTING_FAILED':
            return {
                ...state,
                error: moment(),
            };
    }
};
