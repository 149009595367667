import { connect } from 'react-redux';
import { PostTrack as PostTrackComponent } from './post-track.component';
import { postAction } from './post-track.actions';

const mapStateToProps = state => {
    return {
        token: state.authReducer.token,
        ...state,
    };
};

export const PostTrack = connect(mapStateToProps, {
    postAction,
})(PostTrackComponent);
