const initialState = {
    content: '',
};

export const bladeReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_BLADE_REQUESTED':
            return {
                ...state,
                loading: true,
            };
        case 'GET_BLADE_SUCCESS': {
            const content = action.payload ? action.payload : state.content;
            return {
                ...state,
                content,
                loading: false,
            };
        }
        case 'GET_BLADE_FAILED':
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case 'POST_BLADE_REQUESTED':
            return {
                ...state,
                loading: true,
            };
        case 'POST_BLADE_SUCCESS': {
            return {
                ...state,
                loading: false,
            };
        }
        case 'POST_BLADE_FAILED':
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case 'CLEAR_BLADE':
            return {
                ...state,
                loading: true,
                content: '',
            };
    }
};
