import { findIndex } from 'lodash';

const initialState = {
    token: null,
    defaultCriteria: null,
    wcag: {},
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'SET_TOKEN_REQUESTED':
            return {
                ...state,
            };
        case 'SET_TOKEN_SUCCESS':
            return {
                ...state,
                token: action.payload,
            };
        case 'SET_TOKEN_FAILED':
            return {
                ...state,
                error: action.error,
            };
        case 'GET_HANDSHAKE_REQUESTED':
            return {
                ...state,
            };
        case 'GET_HANDSHAKE_SUCCESS':
            return {
                ...state,
                user: action.payload.user,
                defaultCriteria: action.payload.criteria,
                wcag: action.payload.wcag,
            };
        case 'GET_HANDSHAKE_FAILED':
            return {
                ...state,
                error: action.error,
            };

        case 'AUTHENTICATE_USER_SUCCESS':
            return {
                ...state,
                user: action.payload.user,
                defaultCriteria: action.payload.criteria,
                wcag: action.payload.wcag,
            };

        case 'GET_USER_REQUESTED':
            return {
                ...state,
                loadingUser: true,
            };
        case 'GET_USER_SUCCESS':
            return {
                ...state,
                user: action.payload.user,
                defaultCriteria: action.payload.criteria,
                wcag: action.payload.wcag,
                loadingUser: false,
            };
        case 'GET_USER_FAILED':
            return {
                ...state,
                error: action.error,
                loadingUser: false,
            };
        case 'CLEAR_INTERRUPT':
            return {
                ...state,
                token: {
                    ...state.token,
                    promo: {
                        ...state.token.promo,
                        interrupt_app: null,
                        interrupt_web: null,
                    },
                },
            };
        case 'UPDATE_WEB_VERSION':
            return {
                ...state,
                webVersion: action.webVersion,
            };
        //TODO these should not be here
        case 'SAVE_ACCOUNT_SETTING_SUCCESS': {
            const i = findIndex(action.payload, s => {
                return s.name == 'wcag';
            });

            const options = action.payload[i].options;
            let newWcag = {};
            options.forEach(a => {
                newWcag[a.param.split('wcag_')[1]] = a.value;
            });

            return {
                ...state,
                wcag: {
                    ...state.wcag,
                    ...newWcag,
                },
            };
        }
        case 'UPDATE_INBOX_COUNTS':
            return {
                ...state,
                token: {
                    ...state.token,
                    user: {
                        ...state.token.user,
                        counts: action.counts,
                    },
                },
            };
        case 'UPDATE_END_CALL': {
            //check if was ongoing call and clear from token to prevent attempting to get call on refresh before token has been updated
            const ongoingCall =
                state.token.user.video_call &&
                state.token.user.video_call.with_user_id == action.payload.other_user_id;
            if (ongoingCall) {
                return {
                    ...state,
                    token: {
                        ...state.token,
                        user: {
                            ...state.token.user,
                            video_call: null,
                        },
                    },
                };
            }
            return state;
        }
        case 'SIGNOUT_REQUESTED':
            return {
                ...state,
            };
        case 'SIGNOUT_SUCCESS':
            return {
                ...state,
                user: null,
            };
        case 'SIGNOUT_FAILED':
            return {
                ...state,
                error: action.error,
            };
    }
};
