import { connect } from 'react-redux';
import { AccountRecovery as AccountRecoveryComponent } from './recovery.component';
import { undeleteAccount } from './recovery.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.recovery,
    };
};

export const AccountRecovery = connect(mapStateToProps, {
    undeleteAccount,
})(AccountRecoveryComponent);
