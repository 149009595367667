import React, { useState, useEffect } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Button, Grid, Typography, OutlinedInput, Select, MenuItem, TextField } from '@mui/material';
import GeocodeEarth from '@/shared/components/geocode-earth-input/geocode-earth-input';
import classes from './basic-info.styles.module.scss';
import { AccountLayout } from '../account-layout';
import { PhoneInputBasic as PhoneInput } from '@/shared/components/phone-input';
import { useInput, useDidUpdateEffect } from '@/shared/util/hooks';
import moment from 'moment';

export const BasicInformation = props => {
    const [_geostring, setLocation] = useState('');
    const [locationChangedTime, setLocationChangedTime] = useState(moment());
    const [email, setEmail] = useState('');
    const { value: phone, setValue: setPhone, bind: bindPhone } = useInput('');
    const { value: diocese, setValue: setDiocese, bind: bindDiocese } = useInput('');

    /**
     * Get the user contact information when component mounts.
     */
    useEffect(() => {
        document.title = 'Basic Info';
        props.getBasicInfo();
    }, []);

    useDidUpdateEffect(() => {
        //update diocese only when select new location (not on every keystroke)
        if (_geostring) {
            setDiocese('');
            props.getDioceses(_geostring);
        }
    }, [locationChangedTime]);

    useEffect(() => {
        if (props.user) {
            setLocation(props.user.location);
            setEmail(props.user.email);
            setPhone(props.user.phone);
            setDiocese(props.user.diocese);
        }
    }, [props.user]);

    const updateContactInfo = () => {
        let patchRequest = { _geostring, email, diocese };
        if (phone) {
            patchRequest = { ...patchRequest, phone };
        }
        props.updateBasicInformation(patchRequest);
    };

    const updateLocation = suggestion => {
        if (suggestion) {
            setLocation(`${suggestion.locality}, ${suggestion.region}, ${suggestion.country}`);
            setLocationChangedTime(moment());
        }
    };

    const onLocationChange = event => {
        setLocation(event.target.value);
    };

    return (
        <React.Fragment>
            <AccountLayout
                menuProps={{
                    showBack: !window.isDesktop,
                    title: 'Basic Information',
                    rightOptions: !window.isDesktop && [
                        <Button key="save" onClick={updateContactInfo} color="primary">
                            Update
                        </Button>,
                    ],
                }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={window.isDesktop ? 6 : 12}>
                        <Typography variant="subtitle2" component="label" htmlFor="location-input">
                            Location
                        </Typography>
                        <GeocodeEarth
                            value={_geostring}
                            onChange={updateLocation}
                            onSelect={updateLocation}
                            onTextChange={onLocationChange}
                            currentLatitude={props.token.user.latitude}
                            currentLongitude={props.token.user.longitude}
                        />
                    </Grid>
                    <Grid item xs={window.isDesktop ? 6 : 12}>
                        <Typography variant="subtitle2" component="label" htmlFor="diocese-input">
                            Diocese
                        </Typography>
                        {(props.diocese_list.length > 0 && (
                            <Select
                                {...bindDiocese}
                                variant="outlined"
                                className={classes.input}
                                name="diocese"
                                id="diocese-select"
                            >
                                {props.diocese_list.map((item, i) => {
                                    return (
                                        <MenuItem key={i} value={item}>
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        )) || (
                                <TextField
                                    {...bindDiocese}
                                    className={classes.input}
                                    id="diocese-input"
                                    style={{ height: 42 }}
                                    placeholder="Diocese"
                                    fullWidth
                                    variant="outlined"
                                />
                            )}
                    </Grid>
                    <Grid item xs={window.isDesktop ? 6 : 12}>
                        <Typography variant="subtitle2" component="label" htmlFor="email-input">
                            Email
                        </Typography>
                        <TextField
                            id="email-input"
                            className={classes.input}
                            style={{ height: 42 }}
                            placeholder="Email"
                            value={email}
                            variant="outlined"
                            onChange={event => {
                                setEmail(event.target.value.replace(/ /g, ''));
                            }}
                        />
                    </Grid>
                    <Grid item xs={window.isDesktop ? 6 : 12}>
                        <Typography variant="subtitle2" component="label" htmlFor="phone-input">
                            Phone Number
                        </Typography>
                        <OutlinedInput
                            {...bindPhone}
                            style={{ minWidth: 250 }}
                            name="phonenumber"
                            className={classes.input}
                            id="phone-input"
                            placeholder="Enter a phone number"
                            inputComponent={PhoneInput}
                        />
                    </Grid>
                </Grid>
                <Typography className="mt-5 mb-4" variant="caption" color="textSecondary">
                    Contact Support to edit the following fields.
                </Typography>
                {props.user && (
                    <Grid container spacing={4}>
                        <Grid item xs={window.isDesktop ? 6 : 12}>
                            <Typography variant="subtitle2" component="label" htmlFor="name-input">
                                Name
                            </Typography>
                            <TextField
                                id="name-input"
                                value={`${props.user.first_name} ${props.user.last_name}`}
                                disabled
                                variant="outlined"
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item xs={window.isDesktop ? 6 : 12}>
                            <Typography variant="subtitle2" component="label" htmlFor="dob-input">
                                Date of Birth
                            </Typography>
                            <TextField
                                id="dob-input"
                                value={props.user.date_of_birth}
                                disabled
                                variant="outlined"
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item xs={window.isDesktop ? 6 : 12}>
                            <Typography
                                variant="subtitle2"
                                component="label"
                                htmlFor="gender-input"
                            >
                                Gender
                            </Typography>
                            <TextField
                                id="gender-input"
                                value={props.user.gender}
                                disabled
                                variant="outlined"
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item xs={window.isDesktop ? 6 : 12}>
                            <Typography
                                variant="subtitle2"
                                component="label"
                                htmlFor="marital-status-input"
                            >
                                Marital Status
                            </Typography>
                            <TextField
                                id="marital-status-input"
                                value={props.user.marital_status}
                                disabled
                                variant="outlined"
                                className={classes.input}
                            />
                        </Grid>
                    </Grid>
                )}
                {window.isDesktop && (
                    <FlexBox className="mt-5" row justifyend>
                        <Button onClick={updateContactInfo} color="primary" variant="contained">
                            Update
                        </Button>
                    </FlexBox>
                )}
            </AccountLayout>
        </React.Fragment>
    );
};


