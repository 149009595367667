import { connect } from 'react-redux';
import { Notifications as NotificationsComponent } from './notifications.component';
import { saveNotification, getNotifications } from './notifications.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.notifications,
    };
};

export const Notifications = connect(mapStateToProps, {
    saveNotification,
    getNotifications,
})(NotificationsComponent);
