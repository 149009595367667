import axiosAPI from '@/shared/api';

export const getSettings = () => async dispatch => {
    dispatch(getSettingsRequested());

    return axiosAPI()
        .get('account/settings')
        .then(response => {
            dispatch(getSettingsSuccess(response.data));
        })
        .catch(error => {
            dispatch(getSettingsFailed(error.response));
        });
};

export const saveSetting = (setting, value) => async dispatch => {
    dispatch(saveSettingRequested());

    return axiosAPI()
        .patch('account/settings', {
            [setting]: value,
        })
        .then(response => {
            dispatch(saveSettingSuccess(response.data));
        })
        .catch(error => {
            dispatch(saveSettingFailed(error.response));
        });
};

function getSettingsRequested() {
    return {
        type: 'GET_ACCOUNT_SETTINGS_REQUESTED',
    };
}

function getSettingsSuccess(payload) {
    return {
        type: 'GET_ACCOUNT_SETTINGS_SUCCESS',
        payload,
    };
}

function getSettingsFailed(error) {
    return {
        type: 'GET_ACCOUNT_SETTINGS_FAILED',
        error,
    };
}

function saveSettingRequested() {
    return {
        type: 'SAVE_ACCOUNT_SETTING_REQUESTED',
    };
}

function saveSettingSuccess(payload) {
    return {
        type: 'SAVE_ACCOUNT_SETTING_SUCCESS',
        payload,
    };
}

function saveSettingFailed(error) {
    return {
        type: 'SAVE_ACCOUNT_SETTING_FAILED',
        error,
    };
}
