import { connect } from 'react-redux';
import { ProfileStatus as ProfileStatusComponent } from './profile-status.component';
import { updateProfileStatus } from './profile-status.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.profileStatus,
        has_active_profile:
            state.authReducer.token.user.auths.has_active_profile,
    };
};

export const ProfileStatus = connect(mapStateToProps, {
    updateProfileStatus,
})(ProfileStatusComponent);
