import axiosAPI from '@/shared/api';

export const getArticles = () => async dispatch => {
    dispatch(loadArticles());
    return axiosAPI()
        .get('/util/articles')
        .then(response => {
            dispatch(articlesSuccess(response.data));
        })
        .catch(error => {
            dispatch(articlesFailed(error.response));
        });
};

function loadArticles() {
    return {
        type: 'GET_ARTICLES',
    };
}

function articlesSuccess(payload) {
    return {
        type: 'GET_ARTICLES_SUCCESS',
        payload,
    };
}

function articlesFailed(error) {
    return {
        type: 'GET_ARTICLES_FAILED',
        error,
    };
}
