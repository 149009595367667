import { AppBar, Toolbar } from '@mui/material';
import { styled } from "@mui/system";
// import { styled } from "@mui/material/styles";
// import { withStyles } from '@mui/material/styles';
import variables from './variables';

const ToBeStyledAppBar = ({ className, ...props }) => {
    <AppBar {...props} classes={{ root: className }} />
}

export const StyledAppBar = styled(ToBeStyledAppBar)(({ theme }) => ({
    // height: variables.appbarHeight,
    minHeight: variables.appbarHeight,
    background: theme.palette.background.default,
    border: 'none',
}));

const ToBeStyledToolbar = ({ className, ...props }) => {
    <Toolbar {...props} classes={{ root: className }} />
}

export const StyledToolbar = styled(ToBeStyledToolbar)(({ theme }) => ({
    height: '100%',
    minHeight: variables.appbarHeight,
}));

const ToBeStyledNavAppBar = ({ className, ...props }) => {
    <NavAppBar {...props} classes={{ root: className }} />
}

export const NavAppBar = styled(ToBeStyledNavAppBar)(({ theme }) => ({
    height: variables.navbarHeight,
    minHeight: variables.navbarHeight,
    borderWidth: 0,
    boxShadow: 'none',
}));

const ToBeStyledNavToolbar = ({ className, ...props }) => {
    <Toolbar {...props} classes={{ root: className }} />
}

export const NavToolbar = styled(ToBeStyledNavToolbar)(({ theme }) => ({
    height: '100%',
    minHeight: variables.navbarHeight,
    border: 'none',
}));

const ToBeStyledTabbar = ({ className, ...props }) => {
    <AppBar {...props} classes={{ root: className }} />
}

export const Tabbar = styled(ToBeStyledTabbar)(({ theme }) => ({
    height: variables.tabbarHeight,
    minHeight: variables.tabbarHeight,
    background: theme.palette.background.paper,
    borderWidth: 0,
    top: 'auto',
    bottom: 0,
    overflow: 'hidden',
    maxWidth: '100%',
}));

const ToBeStyledTabbarToolbar = ({ className, ...props }) => {
    <AppBar {...props} classes={{ root: className }} />
}

export const TabbarToolbar = styled(ToBeStyledTabbarToolbar)(({ theme }) => ({
    root: {
        height: '100%',
        minHeight: variables.tabbarHeight,
    },
}));
