const initialState = {
    user: null,
};

export const passwordReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'UPDATE_PASSWORD_REQUESTED':
            return {
                ...state,
            };
        case 'UPDATE_PASSWORD_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'UPDATE_PASSWORD_FAILED':
            return {
                ...state,
            };
    }
};
