import React, { useEffect, useState, Fragment } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import { omitBy, isNull, findIndex } from 'lodash';
import { Autocomplete, Switch, RadioGroup, FormGroup, FormControl, Typography, Button } from '@mui/material';
import settingComponents from './settings.config.jsx';
import classes from './settings.styles.module.scss';
import clsx from 'clsx';
import { AccountLayout } from '../account-layout';
import { isBoolean, isInteger } from 'lodash';
import Confirm from '@/shared/components/confirm/confirm';
import { cloneDeep } from 'lodash';
import { RemoveAccount } from '../basic-info/remove-account';

export const Settings = props => {
    const [settings, setSettings] = useState(props.settings);
    const [confirm, setConfirm] = useState({});
    const [showAccountRemoval, setShowAccountRemoval] = useState(false);
    /**
     * The set of components that are available in the config to be dynamically
     * rendered as OptionType in the renderComponent method.
     *
     * @const  components
     */
    const components = {
        switch: {
            type: Switch,
            value: 'checked',
            cast: 'boolean',
            post: val => (val ? 1 : 0),
        },
        select: {
            type: Autocomplete,
            value: 'value',
            cast: 'integer',
            post: val => val,
        },
        radio: {
            type: RadioGroup,
            className: classes.radioGroup,
            value: 'value',
            post: val => val,
        },
    };

    const boolControl = type => (type != 'boolean' ? type : 'switch');

    /**
     * Get the user settings when component mounts.
     */
    useEffect(() => {
        document.title = props.title || 'Settings';
        props.getSettings();
    }, []);

    useDidUpdateEffect(() => {
        setSettings(props.settings);
    }, [props.settings, props.error]);

    /**
     * Cast type to be used in Material-UI component.
     */
    const cast = (section, option) => {
        /**
         * Server side boolean values come back as 0,1,"0", or "1". These need
         * converted before being passed to the Switch.
         */
        switch (components[boolControl(option.type)].cast) {
            case 'boolean':
                return !isBoolean(option.value) ? !!parseInt(option.value) : option.value;
            case 'integer':
                return !isInteger(option.value) ? parseInt(option.value) : option.value;
            default:
                return option.value;
        }
    };

    /**
     * Click handler for updating settings client side, fire action to
     * save setting.
     *
     * @param {*} target
     * @param {*} value
     * @param {*} option
     */
    const updateSettings = (target, section, value, option) => {
        /**
         * All of the user settings at this point are validated server side
         * to be a 1 or 0, this converts Switch boolean values to 1 or 0.
         */
        target.disabled = true;

        const si = findIndex(settings, function (s) {
            return s.name == section.name;
        });

        const oi = findIndex(settings[si]['options'], function (o) {
            return o.param == option.param;
        });

        const newSettings = cloneDeep(settings);
        newSettings[si]['options'][oi] = { ...option, value };
        setSettings(newSettings);

        props
            .saveSetting(option.param, components[boolControl(option.type)].post(value))
            .finally(() => {
                target.disabled = false;
            });
    };

    const confirmOrUpdate = (target, section, value, option) => {
        let confirm = {};
        if (option.confirm_on && value) {
            confirm = { ...option.confirm_on, event, section, value, option };
        } else if (option.confirm_off && !value) {
            confirm = { ...option.confirm_off, event, section, value, option };
        }

        if (confirm.text) {
            setConfirm(confirm);
        } else {
            setConfirm({});
            updateSettings(target, section, value, option);
        }
    };

    const renderComponent = (section, option) => {
        /**
         * OptionType is a dynamic Component that is determined in the config.
         */
        const OptionType = components[boolControl(option.type)].type;

        /**
         * Values come in from the API sometimes as int, sometimes a string,
         * but material-ui components require a specific type which cast()
         * determines. Also, here we add and remove props dependending
         * on how the option is configured in the config file.
         */
        const inputs = omitBy(
            {
                [components[boolControl(option.type)].value]: cast(section, option),
                color: 'primary',
                options: option.options ? option.options : null,
                renderInput: option.renderInput ? option.renderInput : null,
                children: option.children ? option.children : null,
                getOptionLabel: option.getOptionLabel ? option.getOptionLabel : null,
                disableClearable: option.disableClearable ? option.disableClearable : null,
                size: option.size ? option.size : null,
            },
            isNull
        );

        return (
            <OptionType
                id={option.param}
                {...inputs}
                className={components[boolControl(option.type)].className}
                name={option.title}
                aria-label={option.title}
                onChange={(event, value) => {
                    confirmOrUpdate(event.currentTarget, section, value, option);
                }}
            />
        );
    };

    /**
     * Render the title, description, and input selector for a specific
     * user setting.
     *
     * @param {*} option
     */
    const renderOption = (section, incomingOption) => {
        const option = { ...incomingOption, ...settingComponents[incomingOption.param] };
        const OptionType = renderComponent(section, option);
        const flexOption =
            boolControl(option.type) === 'radio' //maybe options could have a direction property
                ? { column: true, alignstart: true }
                : { row: true, aligncenter: true, };

        return (
            <FlexBox
                className={classes.option}
                flex1
                {...flexOption}
                justifybetween
                key={option.param}
            >
                <FlexBox className={classes.optionContent}>
                    <Typography htmlFor={option.param} variant="body1" component="label">
                        {option.title}
                    </Typography>
                    {option.description && (
                        <Typography variant="caption" color="textSecondary">
                            {option.description}
                        </Typography>
                    )}
                </FlexBox>
                <FormControl
                    className={clsx('mb-auto', boolControl(option.type) == 'radio' && 'w-100')}
                >
                    {OptionType}
                </FormControl>
            </FlexBox>
        );
    };
    /**
     * The JSX returned from this component is an iteration of sections
     * passed in the props. Each section then displays a title, description,
     * and iteration of options.
     */
    return (
        <React.Fragment>
            <AccountLayout
                nopadding
                menuProps={{
                    showBack: !window.isDesktop,
                    title: props.title || 'Settings',
                }}
            >
                <FlexBox flex1 className="pt-3">
                    {settings.length > 0 &&
                        settings.map((section, index) => {
                            return (
                                <Fragment key={index}>
                                    <FormGroup>
                                        <Typography
                                            variant="h6"
                                            component="h2"
                                            className={classes.sectionTitle}
                                        >
                                            {section.title}
                                        </Typography>

                                        {section.description && (
                                            <Typography variant="caption">
                                                {section.description}
                                            </Typography>
                                        )}
                                        <FlexBox className={classes.optionSet}>
                                            {section.options.map(option =>
                                                renderOption(section, option)
                                            )}
                                        </FlexBox>
                                    </FormGroup>
                                </Fragment>
                            );
                        })}
                </FlexBox>
                {confirm.text && (
                    <Confirm
                        title={confirm.title}
                        content={confirm.text}
                        onCancel={() => {
                            setConfirm({});
                        }}
                        onConfirm={() => {
                            updateSettings(
                                confirm.event,
                                confirm.section,
                                confirm.value,
                                confirm.option
                            );
                            setConfirm({});
                        }}
                        cancelText={confirm.cancel_text}
                        confirmText={confirm.confirm_text}
                    ></Confirm>
                )}
            </AccountLayout>
            <FlexBox className="mt-5" column aligncenter>
                <Typography variant="h6" className="mb-1">
                    Thinking about leaving?
                </Typography>
                <Button
                    style={{ minWidth: 150 }}
                    variant="outlined"
                    onClick={() => setShowAccountRemoval(true)}
                >
                    Remove Account
                </Button>
            </FlexBox>
            {showAccountRemoval && <RemoveAccount setVisibility={setShowAccountRemoval} />}
        </React.Fragment>
    );
};
