import { connect } from 'react-redux';
import { Password as PasswordComponent } from './password.component';
import { updatePassword } from './password.actions';

const mapStateToProps = state => {
    return {
        ...state.accountPasswordReducer,
    };
};

export const Password = connect(mapStateToProps, {
    updatePassword,
})(PasswordComponent);
