import { combineReducers } from 'redux';
import { settingsReducer } from './settings/settings.reducer';
import { notificationsReducer } from './notifications/notifications.reducer';
import { basicInfoReducer } from './basic-info/basic-info.reducer';
import { profileStatusReducer } from './profile-status/profile-status.reducer';
import { currentSubscriptionReducer } from './current-subscription/current-subscription.reducer';
import { ordersReducer } from './orders/orders.reducer';
import { paymentReducer } from './payment/payment.reducer';
import { tfaReducer } from './security/tfa/tfa.reducer';
import { trustedDevicesReducer } from './security/trusted-devices/trusted-devices.reducer';
import { passwordReducer } from './security/password/password.reducer';
import { sessionsReducer } from './security/sessions/sessions.reducer';
//import { guaranteeReducer } from './guarantee/guarantee.reducer';
import { badEmailReducer } from './bad-email/bad-email.reducer';
import { removeAccountReducer } from './basic-info/remove-account/remove-account.reducer';
import { recoveryReducer } from './recovery/recovery.reducer';

const initialState = {
    user: null,
    guarantee: null,
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_ACCOUNT_REQUESTED':
            return {
                ...state,
            };
        case 'GET_ACCOUNT_SUCCESS':
            return {
                ...state,
                user: action.payload.user,
            };
        case 'GET_ACCOUNT_FAILED':
            return {
                ...state,
            };
        case 'UPDATE_BASIC_INFORMATION_SUCCESS':
            return {
                ...state,
                user: action.payload.user,
            };
        case 'UPDATE_AUTORENEW_SUCCESS':
            return {
                ...state,
                user: {
                    ...state.user,
                    service: action.payload.service,
                },
            };
    }
};

export default combineReducers({
    account: accountReducer,
    removeAccount: removeAccountReducer,
    settings: settingsReducer,
    notifications: notificationsReducer,
    basicInfo: basicInfoReducer,
    badEmail: badEmailReducer,
    profileStatus: profileStatusReducer,
    currentSubscription: currentSubscriptionReducer,
    orders: ordersReducer,
    payment: paymentReducer,
    tfa: tfaReducer,
    trustedDevices: trustedDevicesReducer,
    password: passwordReducer,
    sessions: sessionsReducer,
    //guarantee: guaranteeReducer,
    recovery: recoveryReducer,
});
