import { get } from 'lodash';
const initialState = {};

export const signinReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'AUTHENTICATE_USER_REQUESTED':
            return {
                ...state,
            };
        case 'AUTHENTICATE_USER_SUCCESS':
            return {
                ...state,
                success: Date.now(),
            };
        case 'AUTHENTICATE_USER_FAILED': {
            return {
                ...state,
            };
        }
    }
};
