import React, { Fragment, Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default class Confirm extends Component {
    defaults = {
        title: 'Please Confirm',
        content: 'Are you sure you want to do this?',
        cancelText: 'Cancel',
        onCancel: () => { },
        cancelOptions: {
            color: 'default',
            variant: 'contained',
        },
        confirmText: 'Ok',
        onConfirm: () => { },
        confirmOptions: {
            color: 'primary',
            variant: 'contained',
        },
        onClose: () => { },
    };

    onClose = callback => {
        const options = { ...this.defaults, ...this.props };
        callback();
        options.onClose();
    };

    render() {
        const options = { ...this.defaults, ...this.props };

        return (
            <Fragment>
                <Dialog
                    maxWidth={options.maxWidth}
                    style={options.style}
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                >
                    {options.title && (
                        <DialogTitle id="alert-dialog-title">{options.title}</DialogTitle>
                    )}
                    {options.content && (
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {options.content}
                            </DialogContentText>
                        </DialogContent>
                    )}

                    <DialogActions>
                        <Button
                            onClick={() => this.onClose(options.onCancel)}
                            {...options.cancelOptions}
                        >
                            {options.cancelText}
                        </Button>
                        <Button
                            onClick={() => this.onClose(options.onConfirm)}
                            {...options.confirmOptions}
                        >
                            {options.confirmText}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}
