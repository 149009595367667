import React, { useEffect } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './post-track.styles.module.scss';
import clsx from 'clsx';
import { IconButton, Icon, Typography, Link } from '@mui/material';
import { has, get } from 'lodash';
import { wp_url } from 'config';

export const PostTrack = props => {
    const { id, type, post_track, likes_count } = props.post;
    const { small } = props;
    const { bookmark_flag, like_flag, enrollment_flag } = post_track;

    useEffect(() => {
        if (props.captureViews) {
            window.addEventListener('scroll', saveView);
            return () => window.removeEventListener('scroll', saveView);
        }
    }, []);

    const saveBookmark = e => {
        e.stopPropagation();
        props.postAction(id, type, 'bookmark');
    };

    const saveLike = e => {
        e.stopPropagation();
        props.postAction(id, type, 'like');
    };

    const saveView = e => {
        let scrollTop = e.target.scrollingElement.scrollTop;
        if (scrollTop > 500) {
            props.postAction(id, type, 'view');
            props.onCapturedViews();
            window.removeEventListener('scroll', saveView);
        }
    };

    const { className, style } = props;

    const showBookmark = get(props, 'showBookmark', true);
    const showLikes = get(props, 'showLikes', false);
    const showEdit = get(props, 'showEdit', false);

    const hasUser = has(props.token, 'user');
    const isAdmin = get(props.token, 'user.auths.is_admin', false);
    const hasWpCookie = get(props.token, 'global_auths.has_wp_cookie', false);
    const iconSize = small ? { fontSize: '.9rem' } : { fontSize: '1.2rem' };
    const canShowEdit = (isAdmin || hasWpCookie) && showEdit;

    return (
        <FlexBox aligncenter row className={clsx(classes.container, className)}>
            {showLikes && (
                <FlexBox aligncenter row className="mr-2">
                    <IconButton
                        className={clsx(classes.button, props.white && classes.buttonWhite)}
                        onClick={saveLike}
                    >
                        {(like_flag && <i style={iconSize} className="fas fa-thumbs-up"></i>) || (
                            <i style={iconSize} className="far fa-thumbs-up"></i>
                        )}
                    </IconButton>

                    <Typography style={props.white ? { color: 'white' } : {}} variant="subtitle1">
                        {likes_count}
                    </Typography>
                </FlexBox>
            )}

            {hasUser && showBookmark && (
                <IconButton
                    color="default"
                    className={clsx(classes.button, props.white && classes.buttonWhite)}
                    onClick={saveBookmark}
                >
                    <i
                        style={{ ...iconSize, opacity: small ? 0.5 : 1 }}
                        className={`fa${bookmark_flag ? 's' : small ? 'l' : 'r'} fa-bookmark`}
                    ></i>
                </IconButton>
            )}

            {canShowEdit && (
                <IconButton
                    href={`${wp_url}/wp-admin/post.php?post=${id}&action=edit`}
                    target="_blank"
                    color="default"
                    className={clsx(classes.button, props.white && classes.buttonWhite)}
                >
                    <i style={iconSize} className="far fa-edit"></i>
                </IconButton>
            )}
        </FlexBox>
    );
};
