import axiosAPI from '@/shared/api';


export const getSubscriptions = () => async dispatch => {
    dispatch(getSubscriptionsRequested());

    return axiosAPI()
        .get('account/services')
        .then(response => {
            dispatch(getSubscriptionsSuccess(response.data));
        })
        .catch(error => {
            dispatch(getSubscriptionsFailed(error.response));
        });
};

function getSubscriptionsRequested() {
    return {
        type: 'GET_SUBSCRIPTIONS_REQUESTED',
    };
}

function getSubscriptionsSuccess(payload) {
    return {
        type: 'GET_SUBSCRIPTIONS_SUCCESS',
        payload,
    };
}

function getSubscriptionsFailed(error) {
    return {
        type: 'GET_SUBSCRIPTIONS_FAILED',
        error,
    };
}
export const upgradeSubscription = (id) => async dispatch => {
    dispatch(upgradeSubscriptionRequested());

    return axiosAPI()
        .post(`account/service/upgrade/${id}`)
        .then(response => {
            dispatch(upgradeSubscriptionSuccess(response.data));
        })
        .catch(error => {
            dispatch(upgradeSubscriptionFailed(error.response));
        });
};

function upgradeSubscriptionRequested() {
    return {
        type: 'UPGRADE_SUBSCRIPTION_REQUESTED',
    };
}

function upgradeSubscriptionSuccess(payload) {
    return {
        type: 'UPGRADE_SUBSCRIPTION_SUCCESS',
        payload,
    };
}

function upgradeSubscriptionFailed(error) {
    return {
        type: 'UPGRADE_SUBSCRIPTION_FAILED',
        error,
    };
}


export const addItemToCart = product => async dispatch => {
    dispatch(addItemToCartRequested());
    // POST api/subscribe/cart/add/product

    // Parameter	Type	Status	Description
    // id	string	required
    // type	string	optional	Required if the parameters type are present. Must match this regular expression: /^(product|bundle|coupon)/i
    // replace	boolean	optional	true, false, 1 or 0
    return axiosAPI()
        .post('/subscribe/cart/add/product', { id: product })
        .then(() => {
            dispatch(addCartItemSuccess());
        })
        .catch(() => {
            dispatch(addCartItemFailed());
        });
};

function addItemToCartRequested() {
    return {
        type: 'ADD_ITEM_TO_CART_REQUESTED',
    };
}

function addCartItemSuccess(payload) {
    return {
        type: 'ADD_ITEM_TO_CART_SUCCESS',
        payload,
    };
}

function addCartItemFailed(error) {
    return {
        type: 'ADD_ITEM_TO_CART_FAILED',
        error,
    };
}


export const cancelSubscription = (id) => async dispatch => {
    dispatch(cancelSubscriptionRequested());
    const request = {id, state: 0};

    return axiosAPI()
        .patch('account/service/autorenew', request)
        .then(response => {
            dispatch(cancelSubscriptionSuccess(response.data));
            dispatch(getSubscriptions());
        })
        .catch(error => {
            dispatch(axiosAPI().retryAction(() => cancelSubscription(id)));
            dispatch(cancelSubscriptionFailed(error.response));
        });
};

function cancelSubscriptionRequested() {
    return {
        type: 'CANCEL_SUBSCRIPTION_REQUESTED',
    };
}

function cancelSubscriptionSuccess(payload) {
    return {
        type: 'CANCEL_SUBSCRIPTION_SUCCESS',
        payload,
    };
}

function cancelSubscriptionFailed(error) {
    return {
        type: 'CANCEL_SUBSCRIPTION_FAILED',
        error,
    };
}


