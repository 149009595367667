const initialState = {
    passwordResetEmailSent: false,
};

export const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'SEND_PASSWORD_RESET_LINK_REQUESTED':
            return {
                ...state,
                passwordResetEmailSent: false,
            };
        case 'SEND_PASSWORD_RESET_LINK_SUCCESS':
            return {
                ...state,
                passwordResetEmailSent: true,
            };
        case 'SEND_PASSWORD_RESET_LINK_FAILED':
            return {
                ...state,
                passwordResetEmailSent: false,
            };
    }
};
