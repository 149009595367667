const initialState = {
    isValidating: null,
    validationSuccess: false,
    passwordWasReset: false,
};

export const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'VALIDATE_RESET_REQUESTED':
            return {
                ...state,
                isValidating: true,
            };
        case 'VALIDATE_RESET_SUCCESS':
            return {
                ...state,
                isValidating: false,
                validationSuccess: true,
            };
        case 'VALIDATE_RESET_FAILED':
            return {
                ...state,
                isValidating: false,
                validationSuccess: false,
            };
        case 'RESET_PASSWORD_REQUESTED':
            return {
                ...state,
            };
        case 'RESET_PASSWORD_SUCCESS':
            return {
                ...state,
                passwordWasReset: true,
            };
        case 'RESET_PASSWORD_FAILED':
            return {
                ...state,
            };
    }
};
