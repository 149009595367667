import React, { useState, useEffect } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Button, Typography, TextField } from '@mui/material';
import { AccountLayout } from '../account-layout';
import { useInput } from '@/shared/util/hooks';

export const BadEmail = props => {
    const { value: email, setValue: setEmail, bind: bindEmail } = useInput('');

    /**
     * Get the user's email status when component mounts.
     */
    useEffect(() => {
        props.getEmailStatus();
    }, []);

    useEffect(() => {
        setEmail(props.email);
    }, [props.email]);

    const updateEmail = () => {
        props.updateEmail({ email });
    };

    return (
        <AccountLayout
            menuProps={{
                showBack: false,
                title: 'Confirm Email',
            }}
        >
            <FlexBox column alignstart>
                <Typography>
                    The last email that we attempted to send to you was
                    rejected. Being able to contact you is vital, whether it is
                    for letting you know of a new message, a new match, or a
                    problem with your account. Please confirm your email address
                </Typography>

                <TextField
                    className="mt-4"
                    style={{ minWidth: 300 }}
                    {...bindEmail}
                    label="Email Address"
                    variant="outlined"
                />
                <Button
                    className="mt-3"
                    variant="contained"
                    color="primary"
                    onClick={updateEmail}
                >
                    Confirm
                </Button>
            </FlexBox>
        </AccountLayout>
    );
};
