import React, { useState, useEffect } from 'react';
import { RadioGroup, Grid, Radio } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Modal from '@/shared/components/modal/modal';
import FlexBox from '@/shared/components/flex-box/flex-box';
import Typography from '@mui/material/Typography';
import classes from './remove-account.styles.module.scss';
import history from '@/shared/history';

export const RemoveAccount = props => {
    const [selectedReason, setSelectedReason] = useState(null);
    useEffect(() => {
        props.getReasons();
    }, []);

    const renderReasons = () => {
        if (!props.reasons) return null;
        const reasons = props.reasons;
        return Object.keys(reasons).map(function (current, index) {
            return (
                <FormControlLabel
                    key={index}
                    value={current}
                    control={<Radio />}
                    label={reasons[current].reason}
                />
            );
        });
    };

    const navigateToAlternate = () => {
        const alternate = props.reasons[selectedReason].alternate;
        if (alternate.href.match('https')) {
            window.location.href = alternate.href;
        } else {
            // so we can stay in react if react route
            history.push(alternate.href);
        }
    };

    return (
        <Modal
            size="auto"
            mobileFullscreen={false}
            onClose={() => {
                props.setVisibility(false);
            }}
        >
            {selectedReason ? (
                <FlexBox column className={classes.confirmationPopup}>
                    <Typography align="center" variant="h3">
                        {props.reasons[selectedReason].stay.title}
                    </Typography>
                    {/* It's called dangerouslySetInnerHTML because of the
                        potential to have user input inserted here. This text is
                        brought in from a server side static config file, and I'm
                        using this attribute to allow for the anchor tags. */}
                    <Typography
                        className={classes.confirmationText}
                        align="center"
                        variant="body1"
                        dangerouslySetInnerHTML={{
                            __html: props.reasons[selectedReason].stay.text,
                        }}
                    />
                    {props.reasons[selectedReason].alternate && (
                        <Button color="primary" className="mx-auto" onClick={navigateToAlternate}>
                            {props.reasons[selectedReason].alternate.label}
                        </Button>
                    )}
                    <Grid container spacing={2} className="p-2">
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="mt-3  w-100"
                                size="large"
                                onClick={() => {
                                    history.replace('/home');
                                }}
                            >
                                Keep Account
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className="mt-3 w-100"
                                size="large"
                                onClick={() => {
                                    props.removeAccount({
                                        reason: selectedReason,
                                    });
                                }}
                            >
                                Delete Account
                            </Button>
                        </Grid>
                    </Grid>
                </FlexBox>
            ) : (
                <FlexBox column className={classes.reportPopup}>
                    <FlexBox>
                        <FlexBox className="px-3">
                            <Typography align="center" variant="h3" className="mb-1">
                                Why are you removing your account?
                            </Typography>
                            <Typography align="center" variant="caption" color="textSecondary">
                                If you don't want to answer, "Other" works just fine!
                            </Typography>
                        </FlexBox>

                        <div className={classes.reasons}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <RadioGroup
                                    aria-label="reasons"
                                    name="reasons"
                                    value={selectedReason}
                                    onChange={() => {
                                        setSelectedReason(event.target.value);
                                    }}
                                >
                                    {renderReasons()}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </FlexBox>
                </FlexBox>
            )}
        </Modal>
    );
};
