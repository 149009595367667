import React, { Fragment, useState, useEffect } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Switch, Typography, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import classes from './tfa.styles.module.scss';
import _ from 'lodash';
import moment from 'moment';
import Confirm from '@/shared/components/confirm/confirm';
import { useInput, useDidUpdateEffect } from '@/shared/util/hooks';
import { PhoneInput } from '@/shared/components/phone-input';
import { Verify } from '@/shared/verify';

export const TFA = props => {
    useEffect(() => {
        document.title = 'Two Factor Auth';
        props.getTFA();
    }, []);

    useDidUpdateEffect(() => {
        if (!props?.verify) return;

        const { status, verified_channel_id } = props.verify;

        if (status == 'pending') {
            setVerifyRequired(true);
            return;
        }
        if (status == 'approved' && verified_channel_id) {
            props.updatePrimary(verified_channel_id);
        }

        setVerifyRequired(false);
    }, [props.verify]);

    const [verifyRequired, setVerifyRequired] = useState(false);
    const [deleteChannel, setDeleteChannel] = useState(null);
    const { value: phone_code, setValue: setPhoneCode } = useInput('');
    const { value: phone_number, bind: bindPhoneNumber } = useInput('');

    const handleCountrySelect = (country, code) => {
        setPhoneCode(code);
    };

    const toggleTFA = async () => {
        if (props.is_enabled) {
            await props.disableTFA();
        } else {
            props.enableTFA();
        }
    };

    const deviceName = {
        EMAIL: 'email address',
        SMS: 'mobile device',
    };

    const primaryChannel = _.find(props.tfa_channels, { is_primary: 1 });
    const secondaryChannel = _.find(props.tfa_channels, { is_primary: 0 });

    const renderSecondary = () => {
        if (_.isUndefined(secondaryChannel)) {
            return (
                <FlexBox className="p-3">
                    <Typography variant="subtitle2">Add another Method</Typography>

                    <Typography variant="body2" className="mb-3 mt-1">
                        It is recommended that you add another method using text messaging on your
                        mobile device.
                    </Typography>
                    <FlexBox>
                        <Typography
                            variant="subtitle2"
                            component="label"
                            htmlFor="mobile-input"
                            className="mb-1"
                        >
                            Mobile Number
                        </Typography>
                        <FlexBox row>
                            <PhoneInput
                                {...bindPhoneNumber}
                                key="phone_number"
                                className="w-100"
                                initialCountry={props.token?.user?.country}
                                onCountrySelect={handleCountrySelect}
                            />

                            <Button
                                color="primary"
                                size="small"
                                className="ml-1"
                                onClick={() => {
                                    const fullNumber = phone_number
                                        ? `+${phone_code}${phone_number}`
                                        : null;
                                    props.sendPhone({ phone_number: fullNumber });
                                }}
                            >
                                Add
                            </Button>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            );
        } else {
            return (
                <React.Fragment>
                    <FlexBox className="p-3">
                        <FlexBox row aligncenter justifybetween>
                            <Typography variant="subtitle2">
                                Secondary Method ({secondaryChannel.type})
                            </Typography>
                            <FlexBox row aligncenter>
                                <Button
                                    color="primary"
                                    onClick={() => props.updatePrimary(secondaryChannel.id)}
                                >
                                    Make Primary
                                </Button>
                                {secondaryChannel.type === 'SMS' && (
                                    <Button
                                        className="ml-3"
                                        color="secondary"
                                        onClick={() => {
                                            setDeleteChannel(secondaryChannel);
                                            props.showDeleteConfirmModal();
                                        }}
                                    >
                                        Delete
                                    </Button>
                                )}
                                {secondaryChannel.type === 'SMS' && (
                                    <Typography>{validatedAt(secondaryChannel)}</Typography>
                                )}
                            </FlexBox>
                        </FlexBox>
                        <Typography variant="body2">
                            When signing in from a new device, you may alternatively enter a code
                            sent to your {deviceName[secondaryChannel.type]}:{' '}
                            {secondaryChannel.value}
                        </Typography>
                    </FlexBox>
                </React.Fragment>
            );
        }
    };

    const validatedAt = device => {
        if (device.validated_at) {
            return (
                <Typography variant="caption" color="textSecondary">
                    Validated {moment(device.validated_at).format('MMM DD, YYYY')}
                </Typography>
            );
        }
        return (
            <Typography variant="caption" color="textSecondary">
                Unvalidated
            </Typography>
        );
    };

    return (
        <Fragment>
            <FlexBox className={classes.enable}>
                <FlexBox row justifybetween aligncenter>
                    <Typography variant="h6">Enable Two Factor Authentication?</Typography>
                    <Switch
                        checked={!!props.is_enabled}
                        onChange={() => {
                            if (props.is_enabled) {
                                props.showDisableConfirmModal();
                            } else {
                                toggleTFA();
                            }
                        }}
                        color="primary"
                    />
                </FlexBox>
                {!props.is_enabled && (
                    <Typography color="textSecondary" variant="caption">
                        By enabling two-factor authentication, you will be required to enter a code
                        sent to either your email or mobile phone number when signing in with a new
                        device.
                    </Typography>
                )}
            </FlexBox>
            {!!props.is_enabled && primaryChannel && (
                <Paper className="mt-4" variant="outlined" elevation={0}>
                    <FlexBox className="p-3" style={{ borderBottom: '1px solid #ecedf3' }}>
                        <FlexBox row aligncenter justifybetween>
                            <Typography variant="subtitle2">
                                Primary Method ({props.primary})
                            </Typography>
                            {primaryChannel.type === 'SMS' && (
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setDeleteChannel(primaryChannel);
                                        props.showDeleteConfirmModal();
                                    }}
                                >
                                    Delete
                                </Button>
                            )}
                        </FlexBox>
                        <FlexBox>
                            <Typography variant="body2" className="mt-1">
                                When signing in from a new device you will be required to enter a
                                code sent to your {deviceName[primaryChannel.type]} at{' '}
                                {primaryChannel.value}.
                            </Typography>
                            {primaryChannel.type === 'SMS' && (
                                <Typography>{validatedAt(primaryChannel)}</Typography>
                            )}
                        </FlexBox>
                    </FlexBox>
                    <FlexBox>
                        <FlexBox>{renderSecondary()}</FlexBox>
                    </FlexBox>
                </Paper>
            )}
            {props.showDeleteConfirm && deleteChannel && (
                <Confirm
                    title="Delete TFA Channel"
                    content={`Are you sure you wish to remove your ${deviceName[deleteChannel.type]
                        } information?`}
                    onCancel={props.hideDeleteConfirmModal}
                    onConfirm={() => props.deleteChannel(deleteChannel.id)}
                    confirmText="Delete"
                ></Confirm>
            )}
            {props.showDisableConfirm && (
                <Confirm
                    title="Disable Two Factor Authentication"
                    content={
                        'If you disable Two-Factor Authentication, all trusted devices will be cleared!'
                    }
                    onCancel={props.hideDisableConfirmModal}
                    onConfirm={toggleTFA}
                    confirmText="Disable"
                ></Confirm>
            )}

            {verifyRequired && (
                <Verify
                    context="addtfa"
                    view="verify_code"
                    onVerifyModalClose={() => {
                        setVerifyRequired(false);
                    }}
                ></Verify>
            )}
        </Fragment>
    );
};
