import { has, get } from 'lodash';

const helpers = {
    getProfileContext: props => {
        return has(props, 'history.location.state.context')
            ? get(props, 'history.location.state.context')
            : props.context;
    },
    getProfileCriteria: props => {
        return has(props, 'history.location.state.navCriteria')
            ? get(props, 'history.location.state.navCriteria')
            : null;
    },
    isProfileContextMatches: context => {
        return /^matches\/(new|confirmed|declined)$/.test(context);
    },
    isProfileContextNewMatches: context => {
        return context == 'matches/new';
    },
    getProfileBackUrl: props => {
        let backURL = has(props, 'history.location.state.backUrl')
            ? get(props, 'history.location.state.backUrl')
            : null;
        if (!backURL) {
            const contextBackURL = helpers.getProfileContext(props);
            backURL = contextBackURL ? '/' + contextBackURL : null;
        }
        return backURL;
    },
    getScrollTop: () => {
        const el = document.scrollingElement || document.documentElement;
        return el.scrollTop;
    },
    navigateToProfile: (props, id) => {
        let scrollTop = helpers.getScrollTop();
        props.history.push({
            pathname: `/profile/${id}`,
            state: {
                initialId: id,
                context: props.context,
                navCriteria: props.navCriteria, // this is criteria if navigating to saved search profile from home
                backUrl: props.backUrl,
                scrollTop,
            },
        });
    },
    getWebVersion: () => {
        return document.head.querySelector('meta[name~=version]').getAttribute('content');
    },
    getCsrfToken: () => {
        return document.head.querySelector('meta[name~=csrf-token]').getAttribute('content');
    },

    generateString: (characters, length) => {
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    setTitle: title => {
        return `${title} | CatholicMatch.com`;
    },

    isAdmin: () => {
        return /^admin/.test(window.location.pathname);
    },

    isPlus: () => {
        return /plus/.test(window.location.host);
    },

    isCM: () => {
        return !helpers.isPlus();
    },

    canLoadAdmin: () => {
        return helpers.isAdmin();
    },

    canLoadPlus: () => {
        return !helpers.isAdmin() && helpers.isPlus();
    },

    canLoadCM: () => {
        return !helpers.isAdmin() && !helpers.isPlus();
    },

    getQueryValue: (query, propName) => {
        const queryMap = query
            .slice(1)
            .split('&')
            .map(q => q.split('='))
            .reduce((a, c) => {
                a[c[0]] = c[1];
                return a;
            }, {});

        return queryMap[propName];
    },

    showLoader: () => {
        const loader = document.getElementById('react-preloader');
        if (loader) {
            loader.style.display = 'block';
        }
    },

    hideLoader: () => {
        const loader = document.getElementById('react-preloader');
        if (loader) {
            loader.style.display = 'none';
        }
    },

    resetIframe: () => {
        document.getElementById('resetiframe').contentWindow.reset();
    },
};

export default helpers;
