import { connect } from 'react-redux';
import { Order as OrderComponent } from './order.component';
import { getIndividualOrderHistory } from '../orders.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.orders,
    };
};

export const Order = connect(mapStateToProps, {
    getIndividualOrderHistory,
})(OrderComponent);
