import { connect } from 'react-redux';
import { Toast as ToastComponent } from './toast.component';
import { closeToast, clearToast } from './toast.actions';
// This withSnackbar thing has been dropped in the update. Need to review this.
// import { withSnackbar } from 'notistack';

const mapStateToProps = state => {
    return state.toastReducer;
};

// export const Toast = withSnackbar(
//     connect(mapStateToProps, { closeToast, clearToast })(ToastComponent)
// );

export const Toast = connect(mapStateToProps, { closeToast, clearToast })(ToastComponent);
