import { keys } from 'lodash';

export default class ExceptionHandler {
    constructor(payload, caughtExceptions = [], initialRequest = { send: () => {} }) {
        this.exception = payload.exception;
        this.errors = payload.errors;
        this.redirect = payload.redirect;
        this.replace = payload.replace;
        this.title = payload.title;
        this.confirmText = payload.button || 'OK';
        this.initialRequest = initialRequest;

        if (!this.title && this.exception != 'faux') {
            this.title = 'Ooops...!';
        }

        if (!this.exception || !this.errors) return;

        this.key = keys(payload.errors)[0];
        this.name = this.exception + '.' + this.key;
        this.caughtExceptions = caughtExceptions;
    }

    is(exception) {
        var re = new RegExp('^' + exception, 'gi');
        return re.test(this.name);
    }

    isCaught() {
        return this.caughtExceptions.find(exception => {
            return this.is(exception);
        });
    }

    getError(key = this.key) {
        return this.errors[key] && this.errors[key][0] ? this.errors[key][0] : null;
    }

    message() {
        return this.getError();
    }

    getErrorsAsHtml() {
        if (keys(this.errors).length > 1) {
            let errors = [];
            // eslint-disable-next-line no-unused-vars
            for (let key in this.errors) {
                errors.push('<li>' + this.errors[key][0] + '</li>');
            }
            return '<ul>' + errors.join(' ') + '</ul>';
        }
        return this.message();
    }
}
