import { male_default_photo, female_default_photo } from 'config';

export default {
    navbarHeight: window.isDesktop ? 64 : 56,
    navbarMobileHeight: 50,
    subscribeBarHeight: 50,
    paddingTop: 30,

    //search
    appbarHeight: 56,
    bottomBarHeight: 62,

    tabbarHeight: 56,

    //container left section

    //sidebar
    sidebarWidth: 400,
    sidePadding: 24,
    isDesktop: window.isDesktop,

    maleDefaultPhoto: male_default_photo,
    femaleDefaultPhoto: female_default_photo,
};
