import axiosAPI from '@/shared/api';

export const getSessions = () => async dispatch => {
    dispatch(getSessionsRequested());

    return axiosAPI()
        .get('account/sessions')
        .then(response => {
            dispatch(getSessionsSuccess(response.data));
        })
        .catch(error => {
            dispatch(getSessionsFailed(error.response));
        });
};

export const signoutSession = sessionId => async dispatch => {
    dispatch(deleteSessionRequested());

    return axiosAPI()
        .delete(`account/sessions/${sessionId}`)
        .then(response => {
            dispatch(deleteSessionSuccess({ sessionId }));
        })
        .catch(error => {
            dispatch(axiosAPI().retryAction(() => signoutSession(sessionId)));
            dispatch(deleteSessionFailed(error.response));
        });
};

function getSessionsRequested() {
    return {
        type: 'GET_SESSIONS_REQUESTED',
    };
}

function getSessionsSuccess(payload) {
    return {
        type: 'GET_SESSIONS_SUCCESS',
        payload,
    };
}

function getSessionsFailed(error) {
    return {
        type: 'GET_SESSIONS_FAILED',
        error,
    };
}

function deleteSessionRequested() {
    return {
        type: 'DELETE_SESSION_REQUESTED',
    };
}

function deleteSessionSuccess(payload) {
    return {
        type: 'DELETE_SESSION_SUCCESS',
        payload,
    };
}

function deleteSessionFailed(error) {
    return {
        type: 'DELETE_SESSION_FAILED',
        error,
    };
}
