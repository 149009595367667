import React, { Fragment } from 'react';
import { Alert, AlertTitle, Paper } from '@mui/material';
import Menu from '@/shared/components/menu/menu.component';
import Container from '@/shared/components/container/container';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './account-layout.styles.module.scss';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import clsx from 'clsx';

export const AccountLayout = props => {
    const { nopadding = false, paddingTop = 0 } = props;

    const profileInActiveAlert = () => {
        return (
            <Alert
                severity="warning"
                className={clsx('mb-3', !window.isDesktop && nopadding && 'mx-3')}
            >
                <AlertTitle>Attention</AlertTitle>
                Your profile is currently set to inactive. You can re-activate in your{' '}
                <Link to="/account/settings">account settings.</Link>
            </Alert>
        );
    };

    if (window.isDesktop) {
        return (
            <FlexBox className="w-100 pl-4">
                {!get(props, 'has_active_profile', true) && profileInActiveAlert()}
                <Paper className="w-100 px-5 pb-5 pt-4">
                    {props.menuProps && (
                        <Menu
                            style={{ backgroundColor: 'transparent' }}
                            scrollThreshold="200"
                            nopadding={window.isDesktop}
                            position="relative"
                            className={classes.accountTopMenu}
                            {...props.menuProps}
                        ></Menu>
                    )}
                    {props.children}
                </Paper>
            </FlexBox>
        );
    }
    return (
        <React.Fragment>
            {props.menuProps && <Menu bottomBorder {...props.menuProps}></Menu>}
            <Container
                className="w-100"
                style={{ paddingTop: `calc(1.5rem + ${paddingTop}px)` }}
                nopadding={window.isDesktop || nopadding}
            >
                {!props.has_active_profile && profileInActiveAlert()}
                {props.children}
            </Container>
        </React.Fragment>
    );
};
