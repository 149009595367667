import React from 'react';
import MuiPaper from '@mui/material/Paper';
import classes from './paper.styles.module.scss';
import clsx from 'clsx';

export const Paper = props => {
    const { level, ...rest } = props;

    let className = classes.level1;
    if (level == 0) {
        className = classes.level0;
    } else if (level == 2) {
        className = classes.level2;
    } else if (level == 3) {
        className = classes.level3;
    }

    return (
        <MuiPaper
            {...rest}
            className={clsx(
                className,
                props.className,
                props.elevation == 0 && classes.noElevation
            )}
        />
    );
};
