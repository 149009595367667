import axiosAPI from '@/shared/api';

export const undeleteAccount = () => async dispatch => {
    dispatch(undeleteAccountRequested());

    return axiosAPI()
        .post('account/undelete')
        .then(response => {
            dispatch(undeleteAccountSuccess(response.data));
        })
        .catch(error => {
            dispatch(undeleteAccountFailed(error.response));
        });
};

function undeleteAccountRequested() {
    return {
        type: 'ACCOUNT_RECOVERY_REQUESTED',
    };
}

function undeleteAccountSuccess(payload) {
    return {
        type: 'ACCOUNT_RECOVERY_SUCCESS',
        payload,
    };
}

function undeleteAccountFailed(error) {
    return {
        type: 'ACCOUNT_RECOVERY_FAILED',
        error,
    };
}
