import React, { useEffect } from 'react';
import classes from '../sidebar.module.scss';
import SafeHtml from '../../safe-html/safe-html';
import { Link, Typography } from '@mui/material';
import FlexBox from '../../flex-box/flex-box';
import { get } from 'lodash';
import { plus_url } from 'config';

export const Articles = props => {
    useEffect(() => {
        if (props.articles && props.articles.length > 0) return;
        props.getArticles();
    }, []);

    const articles = props.articles.map(article => {
        const photo = get(article, 'photos.thumbnail.url');
        return (
            <Link
                key={article.id}
                className={classes.post}
                to={article.permalink}
                target="_blank"
            >
                <FlexBox row>
                    <img className={classes.postImg} width="120px" src={photo} border="0" alt="" />

                    <FlexBox column className="ml-3">
                        <Typography color="textPrimary" className={classes.title} component="div">
                            <SafeHtml>{article.title}</SafeHtml>
                        </Typography>
                        <Typography color="textPrimary" className={classes.text} component="div">
                            <SafeHtml>{`${article.date} - ${article.excerpt}`}</SafeHtml>
                        </Typography>
                    </FlexBox>
                </FlexBox>
            </Link>
        );
    });

    return (
        <FlexBox>
            <FlexBox className="mx-3 mb-2" row flex1 justifybetween>
                <Typography color="textPrimary" tabIndex="0" variant="h3" component="h2">
                    CM+ Articles
                </Typography>

                <Link
                    color="primary"
                    variant={'subtitle2'}
                    to={`${plus_url}/articles`}
                    target="_blank"
                >
                    View Articles
                </Link>
            </FlexBox>
            {articles}
        </FlexBox>
    );
};
