import moment from 'moment';

const initialState = {
    loaded: null,
    article: {},
    related: [],
};

export const articleReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'ARTICLE_REQUESTED':
            return {
                ...state,
            };
        case 'ARTICLE_SUCCESS': {
            const { article, related } = action.payload;
            return {
                ...state,
                article,
                related,
                loaded: moment(),
            };
        }
        case 'ARTICLE_FAILED':
            return {
                ...state,
                error: action.error,
            };

        case 'POST_ACTION_SUCCESS': {
            const { post_track, likes_count } = action.payload;
            const {
                postable_id,
                bookmark_flag,
                like_flag,
                enrollment_flag,
                view_count,
            } = post_track;

            let article = state.article;
            let related = state.related;

            if (article.id == postable_id) {
                article.likes_count = likes_count;
                article.post_track = {
                    bookmark_flag,
                    like_flag,
                    enrollment_flag,
                    view_count,
                };
            } else {
                related = state.related.map(art => {
                    if (art.id == postable_id) {
                        return {
                            ...art,
                            likes_count,
                            post_track: {
                                bookmark_flag,
                                like_flag,
                                enrollment_flag,
                                view_count,
                            },
                        };
                    }
                    return art;
                });
            }

            return {
                ...state,
                article,
                related,
            };
        }
    }
};
