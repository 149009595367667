// Phone Input
import React, { useEffect, useState, Fragment } from 'react';
import MaskedInput from 'react-text-mask';
import { TextField } from '@mui/material';
import clsx from 'clsx';
import classes from './phone-input.styles.module.scss';
import { Menu, MenuItem, InputAdornment, Divider } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { country_phone_codes } from 'config';
import { remove, findIndex } from 'lodash';

const topCountries = [
    'US',
    'CA',
    'GB',
    'MX',
    'AU',
    'IE',
    'ES',
    'IN',
    'PH',
    'CO',
    'IT',
    'FR',
    'PE',
    'BR',
];

// eslint-disable-next-line react/display-name
const PhoneMenu = React.memo(
    ({ countryCodes, selectedIndex, onMenuItemClick, anchorEl, onClose }) => {
        return (
            <Menu
                id="phone-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onClose}
            >
                {countryCodes?.map((option, index) => {
                    const addDivider = index == topCountries.length;
                    if (addDivider) {
                        return <Divider key="divider" style={{ height: '10px' }} />;
                    }
                    return (
                        <MenuItem
                            key={index}
                            selected={index === selectedIndex}
                            onClick={event => {
                                onMenuItemClick(event, index);
                            }}
                        >
                            <FlexBox row justifybetween w100>
                                <div>+{option.country_code}</div>
                                <div>{option.country_name}</div>
                            </FlexBox>
                        </MenuItem>
                    );
                })}
            </Menu>
        );
    },
    (prevProps, nextProps) => {
        if (
            prevProps.countryCodes == nextProps.countryCodes &&
            prevProps.anchorEl == nextProps.anchorEl &&
            prevProps.selectedIndex == nextProps.selectedIndex
        ) {
            return true;
        }
        return false;
    }
);

// eslint-disable-next-line react/display-name
export const PhoneInput = React.memo(
    ({ onCountrySelect, initialCountry, onChange, value, label, className, ...props }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [focused, setFocused] = useState(false);
        const [countryCodes, setCountryCodes] = useState([]);
        const [selectedIndex, setSelectedIndex] = useState(0);

        useEffect(() => {
            let tops = remove(country_phone_codes, i => {
                return topCountries.includes(i?.country_code);
            });

            tops = tops.reverse();

            tops.forEach(i => {
                country_phone_codes.unshift(i);
            });

            setCountryCodes(country_phone_codes);

            let i = findIndex(country_phone_codes, function (o) {
                return o.country_code == initialCountry;
            });
            i = i < 0 ? 0 : i;

            setSelectedIndex(i);
            onCountrySelect(country_phone_codes[i].country_code, country_phone_codes[i].phone_code);
        }, []);

        const handleChange = event => {
            onChange(event);
        };

        const phoneLabelShrink = () => {
            return value?.length > 0 ? true : focused;
        };

        const phoneDigitClass = () => {
            if (label) {
                const len = countryCodes[selectedIndex]?.phone_code.toString().length;
                return classes[`phone-${len}-digit`];
            }
            return null;
        };

        const handleClickListItem = event => {
            setAnchorEl(event.currentTarget);
        };

        const handleMenuItemClick = (event, index) => {
            setAnchorEl(null);
            setSelectedIndex(index);
            onCountrySelect(countryCodes[index]?.country_code, countryCodes[index]?.phone_code);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <Fragment>
                <PhoneMenu
                    anchorEl={anchorEl}
                    countryCodes={countryCodes}
                    onMenuItemClick={handleMenuItemClick}
                    onClose={handleClose}
                    selectedIndex={selectedIndex}
                ></PhoneMenu>
                <TextField
                    variant="outlined"
                    name="phone_number"
                    autoComplete="off"
                    inputProps={{
                        autoCapitalize: 'none',
                        autoComplete: 'off',
                        autoCorrect: 'off',
                    }}
                    value={value}
                    id="phone_number"
                    type="phone"
                    label={label}
                    className={clsx(className, phoneDigitClass())}
                    onChange={handleChange}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    InputLabelProps={{
                        shrink: phoneLabelShrink(),
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                onClick={handleClickListItem}
                                style={{ cursor: 'pointer' }}
                            >
                                +{countryCodes[selectedIndex]?.phone_code}{' '}
                                <i style={{ marginLeft: '5px' }} className="fas fa-caret-down" />
                            </InputAdornment>
                        ),
                    }}
                    {...props}
                />
            </Fragment>
        );
    },
    (prevProps, nextProps) => {
        if (prevProps.value == nextProps.value) {
            return true;
        }
        return false;
    }
);

export const PhoneInputBasic = props => {
    const { inputRef, showMask, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ]}
            placeholderChar={'\u2000'}
            showMask={showMask}
        />
    );
};
