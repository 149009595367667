import React, { Component } from 'react';
import { motion } from 'framer-motion';

export default class FlexBox extends Component {
    render() {
        const flexStyle = {
            flexDirection:
                (this.props.row && 'row') ||
                (this.props.column && 'column') ||
                (this.props.columnreverse && 'column-reverse') ||
                (this.props.rowreverse && 'row-reverse') ||
                'column',
            justifyContent:
                (this.props.justifycenter && 'center') ||
                (this.props.justifystart && 'flex-start') ||
                (this.props.justifyend && 'flex-end') ||
                (this.props.justifybetween && 'space-between') ||
                (this.props.justifyaround && 'space-around'),
            alignItems:
                (this.props.aligncenter && 'center') ||
                (this.props.alignstart && 'flex-start') ||
                (this.props.alignend && 'flex-end') ||
                (this.props.alignbetween && 'space-between') ||
                (this.props.alignaround && 'space-around') ||
                (this.props.alignstretch && 'stretch'),
            order: this.props.order,
            display: 'flex',
            flexWrap: this.props.wrap && 'wrap',
            flex: this.props.flex1 && 1,
            width: this.props.w100 && '100%',
            height: this.props.h100 && '100%',
            flexShrink: this.props.noShrink && 0,
            ...this.props.style,
        };
        var {
            row,
            rowreverse,
            column,
            columnreverse,
            aligncenter,
            alignstart,
            alignend,
            alignbetween,
            alignaround,
            alignstretch,
            justifycenter,
            justifystart,
            justifyend,
            justifybetween,
            justifyaround,
            order,
            flex1,
            wrap,
            w100,
            h100,
            noShrink,
            ...other
        } = this.props;
        if (this.props.variants || this.props.animate || this.props.initial) {
            return (
                <motion.div {...other} style={flexStyle}>
                    {this.props.children}
                </motion.div>
            );
        }

        return (
            <div {...other} style={flexStyle}>
                {this.props.children}
            </div>
        );
    }
}
