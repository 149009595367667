import { connect } from 'react-redux';
import { Reauth as ReauthComponent } from './reauth.component';
import { reauthenticateAccount } from './reauth.actions';

const mapStateToProps = state => {
    return {
        ...state.reauthReducer,
    };
};

export const Reauth = connect(mapStateToProps, {
    reauthenticateAccount,
})(ReauthComponent);
