import axiosAPI from '@/shared/api';
import { resetStore } from '@/shared/auth/auth.actions';
import helpers from '@/shared/util/helpers';

export const getReasons = () => async dispatch => {
    dispatch(getReasonsRequested());

    return axiosAPI()
        .get('account/remove')
        .then(response => {
            dispatch(getReasonsSuccess(response.data));
        })
        .catch(error => {
            dispatch(getReasonsFailed(error.response));
        });
};

export const removeAccount = request => async dispatch => {
    dispatch(removeAccountRequested());

    return axiosAPI()
        .post('account/remove', request)
        .then(response => {
            dispatch(removeAccountSuccess(response.data));
            helpers.resetIframe();
            dispatch(resetStore());
        })
        .catch(error => {
            dispatch(removeAccountFailed(error.response));
        });
};

function getReasonsRequested() {
    return {
        type: 'GET_REASONS_REQUESTED',
    };
}

function getReasonsSuccess(payload) {
    return {
        type: 'GET_REASONS_SUCCESS',
        payload,
    };
}

function getReasonsFailed(error) {
    return {
        type: 'GET_REASONS_FAILED',
        error,
    };
}

function removeAccountRequested() {
    return {
        type: 'REMOVE_ACCOUNT_REQUESTED',
    };
}

function removeAccountSuccess(payload) {
    return {
        type: 'REMOVE_ACCOUNT_SUCCESS',
        payload,
    };
}

function removeAccountFailed(error) {
    return {
        type: 'REMOVE_ACCOUNT_FAILED',
        error,
    };
}
