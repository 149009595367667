import axiosAPI from '@/shared/api';

export const getNotifications = () => async dispatch => {
    dispatch(getNotificationsRequested());

    return axiosAPI()
        .get('account/notifications')
        .then(response => {
            dispatch(getNotificationsSuccess(response.data));
        })
        .catch(error => {
            dispatch(getNotificationsFailed(error.response));
        });
};

export const saveNotification = (notification, value) => async dispatch => {
    dispatch(saveNotificationRequested());

    return axiosAPI()
        .patch('account/notifications', {
            [notification]: value,
        })
        .then(response => {
            dispatch(saveNotificationSuccess(response.data));
        })
        .catch(error => {
            dispatch(saveNotificationFailed(error.response));
        });
};

function saveNotificationRequested() {
    return {
        type: 'SAVE_ACCOUNT_NOTIFICATION_REQUESTED',
    };
}

function saveNotificationSuccess(payload) {
    return {
        type: 'SAVE_ACCOUNT_NOTIFICATION_SUCCESS',
        payload,
    };
}

function saveNotificationFailed(error) {
    return {
        type: 'SAVE_ACCOUNT_NOTIFICATION_FAILED',
        error,
    };
}

function getNotificationsRequested() {
    return {
        type: 'GET_ACCOUNT_NOTIFICATIONS_REQUESTED',
    };
}

function getNotificationsSuccess(payload) {
    return {
        type: 'GET_ACCOUNT_NOTIFICATIONS_SUCCESS',
        payload,
    };
}

function getNotificationsFailed(error) {
    return {
        type: 'GET_ACCOUNT_NOTIFICATIONS_FAILED',
        error,
    };
}
