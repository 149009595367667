import { Button, Fab } from '@mui/material';
import { styled } from "@mui/system";


const ToBeStyledButton = ({ className, ...props }) => {
    <Button {...props} classes={{ ...className }} />
}

export const FilterButton = styled(ToBeStyledButton)(({ theme }) => ({
    textTransform: 'none',
    padding: '3px 12px',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.highlight,
    backgroundColor: '#e8f1ff',
    minHeight: 32,
    border: 'none',
    //  borderColor: theme.palette.text.hint,
    minWidth: 'auto',

    '&:hover': {
        //background: theme.palette.grey[200],
    },
    '&:focus': {
        outline: 'none',
    },
}));

export const WhiteButton = styled(ToBeStyledButton)(({ theme }) => ({
    root: {
        textTransform: 'none',
        borderColor: 'white',
        backgroundColor: 'white',
        minWidth: 'auto',

        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,.8)',
            borderColor: 'rgba(255,255,255,.8)',
        },
    },
    label: {
        color: theme.palette.common.black,
    },
}));

const ToBeStyledFab = ({ className, ...props }) => {
    <Fab {...props} classes={{ root: className }} />
}

export const NoShadowFab = styled(ToBeStyledFab)(({ theme }) => ({
    boxShadow: 'none',
}));

export const TransparentBlueButton = styled(ToBeStyledButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light100,
    color: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.light200,
    },
    '&:focus': {
        outline: 'none',
    },
}));
