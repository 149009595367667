export const showToast = (message, options = {}, variant) => dispatch => {
    options.key = options.key || new Date().getTime() + Math.random();

    dispatch({
        type: 'SHOW_TOAST',
        message,
        options,
        variant,
    });
};

export const closeToast = key => dispatch => {
    dispatch({
        type: 'CLOSE_TOAST',
        dismissAll: !key, // dismiss all if no key has been defined
        key,
    });
};

export const clearToast = key => dispatch => {
    dispatch({
        type: 'CLEAR_TOAST',
        key,
    });
};
