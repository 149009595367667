import moment from 'moment';
import { uniqBy } from 'lodash';

const initialState = {
    loaded: false,
    loading: true,
    articles: [],
    category: {},
    criteria: null,
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
};

export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'CATEGORY_REQUESTED':
            return {
                ...state,
                loading: true,
                criteria: action.payload.criteria,
                articles: action.payload.page == 1 ? [] : state.articles,
            };
        case 'CATEGORY_SUCCESS': {
            let articles = action.payload.articles;
            const pagination = action.payload.articles_pagination;
            const category = action.payload.category;
            const tag = action.payload.tag;

            if (pagination.current_page > 1) {
                articles = [...state.articles, ...articles];
                articles = uniqBy(articles, r => {
                    return r.id;
                });
            }
            return {
                ...state,
                articles,
                ...action.payload.articles_pagination,
                category,
                tag,
                loading: false,
                loaded: moment(),
            };
        }
        case 'CATEGORY_FAILED':
            return {
                ...state,
                error: action.error,
                loading: false,
                criteria: [],
            };

        case 'POST_ACTION_SUCCESS': {
            const { post_track, likes_count } = action.payload;
            const { postable_id, bookmark_flag, like_flag, enrollment_flag } = post_track;

            return {
                ...state,
                articles: state.articles.map(a => {
                    if (a.id == postable_id)
                        return {
                            ...a,
                            likes_count: likes_count,
                            post_track: {
                                ...a.post_track,
                                bookmark_flag,
                                like_flag,
                                enrollment_flag,
                            },
                        };
                    return a;
                }),
            };
        }
    }
};
