export const throwException = exception => dispatch => {
    dispatch({
        type: 'THROW_EXCEPTION',
        exception,
    });
};

export const clearException = () => dispatch => {
    dispatch({
        type: 'CLEAR_EXCEPTION',
    });
};
