import React, { useEffect } from 'react';
import {
    Chip,
    Grid,
    Typography,
    Paper,
    Button,
    CircularProgress,
} from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LayoutContainer from '@/shared/components/layout-container/layout-container';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './orders.styles.module.scss';
import { AccountLayout } from '../account-layout';
import history from '@/shared/history';

export const Orders = props => {
    /**
     * Get the order history when component mounts.
     */
    useEffect(() => {
        document.title = 'Orders';
        props.getOrderHistory();
    }, []);

    /**
     * Return the service name from an order.
     *
     * @param  {*} order
     * @return string
     */
    const _service = order => {
        const item = order.order_items.find(item => {
            if (item.product !== 'REFUND') {
                return item.product.sku.toUpperCase().includes('SINGLES');
            }
            return false;
        });

        return item !== undefined ? item.product.name : 'REFUND';
    };

    /**
     * Show the user's Order History
     */

    const desktopOrdersRendered =
        props.orders &&
        props.orders.map((order, index, set) => {
            const productName = _service(order);
            return (
                <Grid
                    className="p-3"
                    style={{
                        borderBottom: '1px solid #ecedf3',
                        alignItems: 'center',
                    }}
                    container
                    key={order.id}
                >
                    <Grid item xs={2}>
                        <Chip
                            size="small"
                            className={
                                order.status.toLowerCase == 'completed'
                                    ? classes.chipPrimary
                                    : classes.chipSecondary
                            }
                            label={order.status.toLowerCase()}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FlexBox>
                            <Typography variant="caption" color="textSecondary">
                                {moment(order.date).format('MMM DD, YYYY')}
                            </Typography>
                            <Typography variant="h6">{productName}</Typography>
                        </FlexBox>
                    </Grid>
                    <Grid item xs={3}>
                        <FlexBox
                            justifycenter
                            aligncenter
                            className={classes.subtotal}
                        >
                            <Typography variant="h6">
                                ${order.amount}
                            </Typography>
                        </FlexBox>
                    </Grid>
                    <Grid item xs={1}>
                        <FlexBox justifyend aligncenter flex1>
                            {productName != 'REFUND' && (
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        history.push(
                                            `/account/orders/${order.id}`
                                        );
                                    }}
                                >
                                    View
                                </Button>
                            )}
                        </FlexBox>
                    </Grid>
                </Grid>
            );
        });

    const mobileOrdersRendered =
        props.orders &&
        props.orders.map((order, index, set) => {
            return (
                <Grid
                    className="p-3"
                    style={{
                        borderBottom: '1px solid #ecedf3',
                        alignItems: 'center',
                    }}
                    container
                    key={order.id}
                >
                    <Grid item xs={7}>
                        <FlexBox>
                            <FlexBox row aligncenter>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    className="mr-2"
                                >
                                    {moment(order.date).format('MMM DD, YYYY')}
                                </Typography>
                                <Chip
                                    size="small"
                                    className={
                                        order.status.toLowerCase == 'completed'
                                            ? classes.chipPrimary
                                            : classes.chipSecondary
                                    }
                                    label={order.status.toLowerCase()}
                                />
                            </FlexBox>

                            <Typography variant="h6">
                                {_service(order)}
                            </Typography>
                        </FlexBox>
                    </Grid>
                    <Grid item xs={4}>
                        <FlexBox
                            justifycenter
                            aligncenter
                            className={classes.subtotal}
                        >
                            <Typography variant="h6">
                                ${order.amount}
                            </Typography>
                        </FlexBox>
                    </Grid>
                    <Grid item xs={1}>
                        <FlexBox justifyend aligncenter flex1>
                            <Button
                                size="small"
                                color="primary"
                                onClick={() => {
                                    history.push(`/account/orders/${order.id}`);
                                }}
                            >
                                View
                            </Button>
                        </FlexBox>
                    </Grid>
                </Grid>
            );
        });
    return (
        <AccountLayout
            nopadding={props.orders}
            menuProps={{
                showBack: !window.isDesktop,
                title: 'Order History',
            }}
        >
            {props.loading && (
                <FlexBox flex1 justifycenter aligncenter className="mt-5">
                    <CircularProgress></CircularProgress>
                </FlexBox>
            )}
            {!props.loading && props.orders && (
                <Paper
                    className={classes.paper}
                    variant="outlined"
                    elevation={0}
                >
                    {window.isDesktop
                        ? desktopOrdersRendered
                        : mobileOrdersRendered}
                </Paper>
            )}
            {!props.loading && !props.orders && (
                <FlexBox
                    flex1
                    justifycontent
                    aligncenter
                    className={classes.noOrders}
                >
                    <Typography variant="h6">You have no orders</Typography>
                </FlexBox>
            )}
        </AccountLayout>
    );
};
