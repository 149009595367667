const initialState = {
    sid: null,
    status: null,
    channel: null,
    email: null,
    phone_number: null,
    phone_country: null,
};

export const verifyReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'SET_VERIFICATION':
            return {
                ...state,
                ...action.payload,
            };
        case 'VERIFY_SEND_EMAIL_REQUESTED':
            return {
                ...state,
                sid: null,
                status:null,
            };
        case 'VERIFY_SEND_EMAIL_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'VERIFY_SEND_EMAIL_FAILED':
            return {
                ...state,
                sid: null,
                status:null,
            };
        case 'VERIFY_SEND_PHONE_REQUESTED':
            return {
                ...state,
                sid: null,
                status:null,
            };
        case 'VERIFY_SEND_PHONE_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'VERIFY_SEND_PHONE_FAILED':
            return {
                ...state,
                sid: null,
                status:null,
            };
        case 'VERIFY_SEND_CODE_REQUESTED':
            return {
                ...state,
            };
        case 'VERIFY_SEND_CODE_SUCCESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'VERIFY_SEND_CODE_FAILED':
            return {
                ...state,
            };
    }
};
