import { connect } from 'react-redux';
import { Payment as PaymentComponent } from './payment.component';
import {
    getPaymentInfo,
    deletePayment,
    savePayment,
    checkGiftCard,
    redeemGiftCard,
} from './payment.actions';
import { getCountries } from '@/shared/config/config.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.payment,
        user: state.authReducer.token.user,
        countries: state.configReducer.countries,
    };
};

export const Payment = connect(mapStateToProps, {
    getPaymentInfo,
    getCountries,
    deletePayment,
    savePayment,
    checkGiftCard,
    redeemGiftCard,
})(PaymentComponent);
