import axiosAPI from '@/shared/api';
import { signout } from '@/shared/auth/auth.actions';

export const reauthenticateAccount = password => async dispatch => {
    dispatch(reauthentiateAccountRequested());

    return axiosAPI()
        .post('account/reauth', { password })
        .then(response => {
            dispatch(reauthentiateAccountSuccess(response));
        })
        .catch(error => {
            dispatch(reauthentiateAccountFailed(error.response));
            if (error.response.data.errors.reauth_failed_max_attempts) {
                dispatch(signout());
            }
        });
};

function reauthentiateAccountRequested() {
    return {
        type: 'REAUTHENTICATION_REQUESTED',
    };
}

function reauthentiateAccountSuccess(payload) {
    return {
        type: 'REAUTHENTICATION_SUCCESS',
        payload,
    };
}

function reauthentiateAccountFailed(error) {
    return {
        type: 'REAUTHENTICATION_FAILED',
        error,
    };
}
