import { connect } from 'react-redux';
import { BasicInformation as BasicInformationComponent } from './basic-info.component';
import {
    getDioceses,
    updateBasicInformation,
    removeAccount,
    getBasicInfo,
} from './basic-info.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.basicInfo,
        user: state.accountReducer.account.user,
        token: state.authReducer.token,
    };
};

export const BasicInformation = connect(mapStateToProps, {
    getDioceses,
    updateBasicInformation,
    removeAccount,
    getBasicInfo,
})(BasicInformationComponent);
