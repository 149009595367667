const currentPlan = {
    id: 1,
    current: true,
    name: '6 Month Premium',
    savings: '50%',
    pricePerMonth: 14.99,
    months: 6,
    features: [
        'Discounted pricing on subscription',
        'Message anyone at any time',
        'Message read indicator',
        'Enjoy unlimited likes',
        'See who likes your profile',
        'Search using advanced filters',
        'Make in-app video calls',
        'See who is interested in your profile',
        'Posting privileges in community forums',
        'VIP invitations and discounts to online events',
    ],
    backgroundColor: '#d88798',
    message:
        'Your Premium subscription provides you with the best features & experience that make it easier to <i>Find Your Forever</i>.<br/><br/>Your next bill is for $49.99 on 3/21/22. You will save 50%!',
    confirm: null,
};
const plans = [
    {
        id: 1,
        name: '6 Month Premium',
        savings: '50%',
        cost_per_month: 14.99,
        subscription_months: 6,
        features: [
            'Discounted pricing on subscription',
            'Message anyone at any time',
            'Message read indicator',
            'Enjoy unlimited likes',
            'See who likes your profile',
            'Search using advanced filters',
            'Make in-app video calls',
            'See who is interested in your profile',
            'Posting privileges in community forums',
            'VIP invitations and discounts to online events',
        ],
        color: '#d88798',
        message: `Your Premium subscription provides you with the best
        features & experience that make it easier to Find Your
        Forever. <br/> Your next bill is for $49.99 on 3/21/22. You will save 50%`,
        confirm: null,
    },
    {
        id: 2,
        name: '12 Month Premium',
        savings: '67%',
        cost_per_month: 9.99,
        subscription_months: 12,
        features: [
            'Best discount of ANY subscription',
            'Message anyone at any time',
            'Message read indicator',
            'Enjoy unlimited likes',
            'See who likes your profile',
            'Search using advanced filters',
            'Make in-app video calls',
            'See who is interested in your profile',
            'Posting privileges in community forums',
            'VIP invitations and discounts to online events',
        ],
        color: '#0d264d',
        confirm: {
            isFirst: true,
            title: 'Yay! Something should go here',
            message:
                "You're subscription will be renewed for 12 months on 3/20/22 and your card will be charged $119.99",
            buttons: [
                {
                    id: 'upgrade',
                    color: 'primary',
                    text: 'Confirm Change',
                },
                {
                    text: 'Cancel',
                },
            ],
        },
    },
    {
        id: 3,
        name: '1 Month Premium',
        savings: null,
        cost_per_month: 29.99,
        subscription_months: 1,
        features: [
            'Message anyone at any time',
            'Message read indicator',
            'Enjoy unlimited likes',
            'See who likes your profile',
            'Search using advanced filters',
            'Make in-app video calls',
            'See who is interested in your profile',
            'Posting privileges in community forums',
            'VIP invitations and discounts to online events',
        ],
        color: '#3271a0',
        message:
            'This subscription is not currently available to you because it is a downgrade from your current subscription. If you have questions please contact <a href="https://catholicmatch.zendesk.com/hc/en-us" alt="Customer Service">Customer Support</a>',
        confirm: null, //can't upgrade because below current subscription. Could also be undefined if you would like
    },
    {
        id: 4,
        name: 'Basic Access',
        subscription_months: 0,
        features: [
            'Message mutual likes and matches',
            'Read & reply to messages after unlocking period',
            'Basic search features',
            'Send 3 Likes per day',
        ],
        color: '#98A0A9',
        confirm_chain: [
            {
               // isFirst: true,
                title: 'Are you sure?',
                message:
                    'The chance of finding a match is far greater with a Premium Subscription. You’ll no longer be able to initiate messages, you’ll lose advanced search capabilities, and won’t be able to see who views your profile.',
                buttons: [
                    {
                        color: 'primary',
                        text: 'Keep my Premium Subscription',
                    },
                    {
                        text: 'Save',
                        next: 'saveSuccess',
                    },
                    {
                        text: 'Save',
                        id: 'saveMethod',
                        next: 'saveSuccess',
                    },
                ],
            },
            {
                id: 'saveSuccess',
                title: 'We hate to see you miss out on meeting your future spouse!',
                message: 'Stay with Premium and take an additional 10% off on us!',
                buttons: [
                    {
                        color: 'primary',
                        id: 'acceptOffer',
                        text: 'Yes, Stay Premium',
                    },
                    {
                        id: 'cancelSubscription',
                        text: 'No, just cancel',
                    },
                ],
            },
            {
                id: 'saveFail',
                title: 'We hate to see you miss out on meeting your future spouse!',
                message: 'Stay with Premium and take an additional 10% off on us!',
                buttons: [
                    {
                        color: 'primary',
                        id: 'acceptOffer',
                        text: 'Yes, Stay Premium',
                    },
                    {
                        id: 'cancelSubscription',
                        text: 'No, just cancel',
                    },
                ],
            },

        ],
    },
];



//
const confirmUpgrade = {

};

const initialState = {
    otherServices:null,
    nextService: null,
    currentService: null,
};

export const currentSubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_ACCOUNT_SUCCESS':
            return {
                ...state,
              //  upgradeServices: plans, //action.payload.upgrade_services,
               // currentService: currentPlan, //action.payload.user?.service,
            };
        case 'GET_SUBSCRIPTIONS_SUCCESS':
            return {
                ...state,
                currentService: action.payload.services.current_service, //action.payload.upgrade_services,
                nextService: action.payload.services.next_service, //action.payload.upgrade_services,
                otherServices: action.payload.services.other_products, //action.payload.user?.service,
            };
            case 'UPGRADE_SUBSCRIPTION_SUCCESS':
                return {
                    ...state,
                    currentService: action.payload.services.current_service, //action.payload.upgrade_services,
                    nextService: action.payload.services.next_service, //action.payload.upgrade_services,
                    otherServices: action.payload.services.other_products, //action.payload.user?.service,
                };
        case 'UPDATE_AUTORENEW_SUCCESS':
            return {
                ...state,
            };
        case 'UPDATE_AUTORENEW_FAILED':
            return {
                ...state,
            };
        case 'CANCEL_TRIAL_SUCCESS':
            return {
                ...state,
            };
    }
};
