import { connect } from 'react-redux';
import { CurrentSubscription as CurrentSubscriptionComponent } from './current-subscription.component';
import {  cancelSubscription , getSubscriptions, upgradeSubscription, addItemToCart} from './current-subscription.actions';
import { getPaymentInfo } from '../payment/payment.actions';
import { getAccount } from '../account.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.currentSubscription,
       user: state.accountReducer.account.user,
    };
};

export const CurrentSubscription = connect(mapStateToProps, {
    cancelSubscription,
    getPaymentInfo,
    getAccount,
    getSubscriptions,
    upgradeSubscription,
    addItemToCart
})(CurrentSubscriptionComponent);
