import axiosAPI from '@/shared/api';

export const setVerification = verification => dispatch => {
    dispatch(setVerificationAction(verification));
};

function setVerificationAction(payload) {
    return {
        type: 'SET_VERIFICATION',
        payload,
    };
}

export const sendEmail = (verification, isSignup = false) => async dispatch => {
    dispatch(sendEmailRequested());

    const url = isSignup ? '/signup/create' : '/account/verify/email';

    return axiosAPI()
        .post(url, verification)
        .then(response => {
            dispatch(sendEmailSuccess(response.data));
        })
        .catch(error => {
            dispatch(sendEmailFailed(error.response));
        });
};

function sendEmailRequested() {
    return {
        type: 'VERIFY_SEND_EMAIL_REQUESTED',
    };
}

function sendEmailSuccess(payload) {
    return {
        type: 'VERIFY_SEND_EMAIL_SUCCESS',
        payload,
    };
}

function sendEmailFailed(error) {
    return {
        type: 'VERIFY_SEND_EMAIL_FAILED',
        error,
    };
}

export const sendPhone = (verification, isSignup = false) => async dispatch => {
    dispatch(sendPhoneRequested());

    const url = isSignup ? '/signup/create' : '/account/verify/phone';

    return axiosAPI()
        .post(url, verification)
        .then(response => {
            dispatch(sendPhoneSuccess(response.data));
        })
        .catch(error => {
            dispatch(sendPhoneFailed(error.response));
        });
};

function sendPhoneRequested() {
    return {
        type: 'VERIFY_SEND_PHONE_REQUESTED',
    };
}

function sendPhoneSuccess(payload) {
    return {
        type: 'VERIFY_SEND_PHONE_SUCCESS',
        payload,
    };
}

function sendPhoneFailed(error) {
    return {
        type: 'VERIFY_SEND_PHONE_FAILED',
        error,
    };
}

export const sendCode = (verification, isSignup = false) => async dispatch => {
    dispatch(confirmVerifyCodeRequested());

    const url = isSignup ? '/signup/create' : '/account/verify/code';

    return axiosAPI()
        .post(url, verification)
        .then(response => {
            dispatch(confirmVerifyCodeSuccess(response.data));
        })
        .catch(error => {
            dispatch(confirmVerifyCodeFailed(error.response));
        });
};

function confirmVerifyCodeRequested() {
    return {
        type: 'VERIFY_SEND_CODE_REQUESTED',
    };
}

function confirmVerifyCodeSuccess(payload) {
    return {
        type: 'VERIFY_SEND_CODE_SUCCESS',
        payload,
    };
}

function confirmVerifyCodeFailed(error) {
    return {
        type: 'VERIFY_SEND_CODE_FAILED',
        error,
    };
}
