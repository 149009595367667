import React from 'react';
import { connect } from 'react-redux';
import { Exception as ExceptionComponent } from './exception.component';
import { throwException, clearException } from './exception.actions';
import { queueRedirect } from '@/shared/redirection/redirection.actions';

const mapStateToProps = state => {
    return {
        ...state.exceptionReducer,
        token: state.authReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        throwException: () => dispatch(throwException()),
        clearException: () => dispatch(clearException()),
        queueRedirect: (url, state, immediate) => dispatch(queueRedirect(url, state, immediate)),
        dispatch,
    };
};

export const Exception = connect(mapStateToProps, mapDispatchToProps)(ExceptionComponent);

// Provide HOC for wrapping host-specific exceptions (extends shared exception component)
export const withException = Component => {
    class WrappedException extends ExceptionComponent {
        render() {
            if (!this.canHandle()) return null;
            return (
                <Component {...this.props} redirect={this.redirect}>
                    {this.showException()}
                </Component>
            );
        }
    }
    return connect(mapStateToProps, mapDispatchToProps)(WrappedException);
};
