import React from 'react';
import classes from './container.module.scss';
import clsx from 'clsx';

const Container = props => {
    const flexStyle = {
        flexDirection:
            (props.row && 'row') || (props.column && 'column') || 'column',
        justifyContent:
            (props.justifycenter && 'center') ||
            (props.justifystart && 'flex-start') ||
            (props.justifyend && 'flex-end') ||
            (props.justifybetween && 'space-between') ||
            (props.justifyaround && 'space-around'),
        alignItems:
            (props.aligncenter && 'center') ||
            (props.alignstart && 'flex-start') ||
            (props.alignend && 'flex-end') ||
            (props.alignbetween && 'space-between') ||
            (props.alignaround && 'space-around'),
        display: 'flex',
        width: props.w100 && '100%',
        ...props.style,
    };

    const {
        row,
        column,
        aligncenter,
        alignstart,
        alignend,
        alignbetween,
        alignaround,
        justifycenter,
        justifystart,
        justifyend,
        justifybetween,
        justifyaround,
        order,
        flex1,
        wrap,
        nopadding,
        leftSection,
        maxWidth,
        w100,
        ...other
    } = props;

    let sizeClass = null;
    if (props.maxWidth == 'lg') {
        sizeClass = classes.lg;
    } else if (props.maxWidth == 'md') {
        sizeClass = classes.md;
    } else if (props.maxWidth == 'sm') {
        sizeClass = classes.sm;
    }

    return (
        <div
            {...other}
            className={clsx(
                classes.container,
                props.nopadding ? classes.nopadding : classes.padding,
                props.className,
                sizeClass
            )}
            style={flexStyle}
        >
            {props.children}
        </div>
    );
};

Container.defaultProps = {
    maxWidth: 'lg',
};

export default Container;
