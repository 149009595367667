import React from 'react';
import { TextField, Radio, FormControlLabel } from '@mui/material';

const config = {
    wcag_text_size: {
        // eslint-disable-next-line react/display-name
        renderInput: params => {
            return (
                <TextField
                    id="wcag_text_size"
                    key="wcag_text_size"
                    {...params}
                    aria-label="Text Size"
                    variant="outlined"
                />
            );
        },
        disableClearable: true,
        size: 'small',
        getOptionLabel: option => {
            return option.toString() + '%';
        },
    },
    wcag_theme: {
        cast: 'string',
        children: [
            <FormControlLabel
                id="default"
                key="default"
                value="default"
                control={<Radio color="primary" />}
                label="Default"
            />,
            <FormControlLabel
                id="traditional"
                key="traditional"
                value="traditional"
                control={<Radio color="primary" />}
                label="Traditional"
            />,
            <FormControlLabel
                id="lightBackground"
                key="lightBackground"
                value="lightBackground"
                control={<Radio color="primary" />}
                label="Light Background"
            />,
            <FormControlLabel
                id="highContrast"
                key="highContrast"
                value="highContrast"
                control={<Radio color="primary" />}
                label="High Contrast"
            />,
            <FormControlLabel
                id="negativeContrast"
                key="negativeContrast"
                value="negativeContrast"
                control={<Radio color="primary" />}
                label="Negative Contrast"
            />,
        ],
    },
};
export default config;
