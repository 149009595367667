import { connect } from 'react-redux';
import { Forums as ForumsComponent } from './forums.component';
import { getPosts } from './forums.actions';

const mapStateToProps = state => {
    return state.forumsReducer;
};

export const Forums = connect(
    mapStateToProps,
    { getPosts }
)(ForumsComponent);
