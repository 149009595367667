const initialState = {
    visible: false,
    ghostedUser: {},
    type: null,
};

export const ghostingReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;

        case 'GHOSTING_STARTED':
            return {
                ...state,
                ...action.payload,
            };
        case 'GHOSTING_ENDED':
            return {
                ...state,
                ...action.payload,
            };
    }
};
