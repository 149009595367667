import { connect } from 'react-redux';
import { TFA as TFAComponent } from './tfa.component';
import {
    enableTFA,
    disableTFA,
    getTFA,
    deleteChannel,
    showDisableConfirmModal,
    hideDisableConfirmModal,
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    updatePrimary,
} from './tfa.actions';

import { sendPhone } from '@/shared/verify/verify.actions';

const mapStateToProps = state => {
    return {
        ...state.accountReducer.tfa,
        verify: state.verifyReducer,
        token: state.authReducer.token,
    };
};

export const TFA = connect(mapStateToProps, {
    enableTFA,
    disableTFA,
    getTFA,
    deleteChannel,
    showDisableConfirmModal,
    hideDisableConfirmModal,
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    updatePrimary,
    sendPhone,
})(TFAComponent);
